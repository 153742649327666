<template>
  <section class="section">
    <div class="container">
      <h1 class="title">
        {{ $tf("workScheduleForm.title|Munkarend beállítása") }}
      </h1>
      <h2 class="subtitle">
        {{
          $tf(
            "workScheduleForm.subtitle|Dolgozók munkarendjének beállítása. A beállított munkarend minden kijelölt dolgozó munkarendjét felül fogja írni!"
          )
        }}
      </h2>
    </div>
    <div class="container">
      <h3>
        {{ $tf("workScheduleForm.userSelect.heading|Dolgozók kijelölése") }}
      </h3>
      <div style="width: 25%">
        <b-field
          :label="
            $tf('workScheduleForm.userSelect|Dolgozók kijelölése név alapján')
          "
          label-position="on-border"
        >
          <b-input v-model="nameSearchString"></b-input>
        </b-field>
      </div>
      <div class="employee-container">
        <ul style="list-style-type: none">
          <li v-for="emp in filteredEmployees" v-bind:key="emp.id">
            <b-checkbox v-model="checked" :native-value="emp.id">
              {{ emp.name }}
            </b-checkbox>
          </li>
        </ul>
      </div>
      <hr />
      <h3 v-if="isSeatingFeatureEnabled">
        {{
          $tf(
            "workScheduleForm.hoursAndSiteSelect|Óraszámok és helyszínek beállítása"
          )
        }}
      </h3>
      <h3 v-else>
        {{ $tf("workScheduleForm.hoursSelect|Óraszámok beállítása") }}
      </h3>
      <div class="pt-4">
        <div class="weekComponent mb-5">
          <div class="columns">
            <div
              class="column"
              v-for="(day, dayIndex) of weekArray"
              :key="dayIndex"
            >
              <div>
                <b-field
                  :label="
                    $tf(DAY_NUMBER_LABEL[dayIndex === 6 ? 0 : dayIndex + 1])
                  "
                  label-position="on-border"
                >
                  <b-input
                    v-model="day.hours"
                    :placeholder="$tf('workScheduleForm.hours|Óraszám')"
                    :select-on-focus="true"
                  />
                </b-field>
                <b-field v-if="isSeatingFeatureEnabled">
                  <b-select
                    v-model="weekArray[dayIndex].site"
                    :disabled="
                      !weekArray[dayIndex].hours ||
                      weekArray[dayIndex].hours === '0'
                    "
                    expanded
                  >
                    <option
                      v-for="value in sites"
                      :key="`sid` + value.id"
                      :value="value.id"
                    >
                      {{ value.identifier }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <div
          class="is-flex is-align-items-center is-justify-content-flex-end has-gap-3"
        >
          <b-tag
            v-if="isMultipleSelected()"
            type="is-warning"
            icon="exclamation-circle"
          >
            {{
              $tf(
                "workScheduleForm.saveEachOneInfo|A mentés egyenként történik, a töltésjelzővel követhető a folyamat."
              )
            }}
          </b-tag>

          <b-button
            :disabled="weekArray.map((wa) => wa.hours).some((h) => !h)"
            native-type="submit"
            :loading="buttonLoading"
            type="is-info"
            @click="submit"
          >
            {{ $tf("workScheduleForm.save|Mentés") }}
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { formatDate, getDisplayName } from "@/utils/util";
import { mapGetters } from "vuex";
import { DAY_NUMBER_LABEL } from "@/utils/const";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "WorkScheduleForm",
  setup: () => ({ v$: useVuelidate() }),
  props: {
    userId: {
      type: String,
      required: false,
    },
  },
  async mounted() {
    await this.$store.dispatch("work_schedule/getWorkScheduleSites");
  },
  data() {
    return {
      getDisplayName,
      DAY_NUMBER_LABEL,
      formatDate,
      buttonLoading: false,
      weekArray: [
        { hours: "0", site: null },
        { hours: "0", site: null },
        { hours: "0", site: null },
        { hours: "0", site: null },
        { hours: "0", site: null },
        { hours: "0", site: null },
        { hours: "0", site: null },
      ],
      checked: [],
      details: [],
      searchString: "",
      // filteredEmployees: [],
      nameSearchString: null,
    };
  },
  computed: {
    filteredEmployees() {
      return this.employees.filter(
        (employee) =>
          ["PERSONAL", "ASSIGNED", "NONE"].includes(employee.workSchedule) &&
          (!this.searchString ||
            employee.name.search(new RegExp(this.searchString, "i")) !== -1) &&
          !employee.hidden &&
          (!employee.endDate || new Date(employee.endDate) >= new Date())
      );
    },
    isSeatingFeatureEnabled() {
      return this.$store.getters["uiConfigStore/getFlags"]
        .seatingFeatureEnabled;
    },
    ...mapGetters({
      employees: "employee/employees",
      sites: "work_schedule/workScheduleSites",
    }),
  },
  watch: {
    nameSearchString(n, o) {
      if (n !== o) {
        this.searchString = n;
      }
    },
  },
  methods: {
    async submit() {
      this.buttonLoading = true;
      let errorHappened = false;
      for (let uid of this.checked) {
        let request = {
          mondayHours: this.weekArray[0].hours,
          mondaySite: this.weekArray[0].site,
          tuesdayHours: this.weekArray[1].hours,
          tuesdaySite: this.weekArray[1].site,
          wednesdayHours: this.weekArray[2].hours,
          wednesdaySite: this.weekArray[2].site,
          thursdayHours: this.weekArray[3].hours,
          thursdaySite: this.weekArray[3].site,
          fridayHours: this.weekArray[4].hours,
          fridaySite: this.weekArray[4].site,
          saturdayHours: this.weekArray[5].hours,
          saturdaySite: this.weekArray[5].site,
          sundayHours: this.weekArray[6].hours,
          sundaySite: this.weekArray[6].site,
          user: uid,
        };
        try {
          await this.$store.dispatch(
            "work_schedule/createWorkSchedule",
            request
          );
        } catch (e) {
          errorHappened = true;
        } finally {
          this.buttonLoading = false;
        }

        if (!errorHappened) {
          this.checked = [];
        }
      }
    },
    isMultipleSelected() {
      return this.checked.length > 1;
    },
  },
};
</script>

<style scoped>
.employee-container {
  max-height: 200px;
  overflow-y: scroll;
}
.employee-container ul {
  column-count: 4;
}
</style>
