<template>
  <section>
    <div class="utilization-employee-brief">
      <div class="utilization-employee-brief-percent">
        {{ percentify(percentage) }}
      </div>
      <div
        v-for="index in numberOfBlocks"
        :key="`${index}-block`"
        :class="
          index <= numberOfColoredBlocks
            ? 'utilization-employee-brief-block utilization-employee-brief-block-colored'
            : 'utilization-employee-brief-block'
        "
      ></div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { convertHoursToMWD, percentify } from "@/utils/util";

export default defineComponent({
  name: "UtilizationEmployeeBrief",
  methods: { convertHoursToMWD, percentify },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      numberOfBlocks: 32,
    };
  },
  computed: {
    percentage() {
      return this.data.allocatedHoursSum / this.data.expectedHoursSum;
    },
    numberOfColoredBlocks() {
      return Math.round(this.percentage * this.numberOfBlocks);
    },
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.utilization-employee-brief {
  &-block {
    width: 2px;
    height: 12px;
    background: $grey-lighter;
    display: inline-block;
    margin-right: 2px;
    text-align: right;

    &-colored {
      background: $custom-light-blue !important;
    }
  }

  &-percent {
    width: 128px;
    text-align: right;
    transform: translateY(6px);
    color: $custom-grayer-blue;
    font-size: 12px;
  }
}
</style>
