<template>
  <div>
    <div class="container" v-if="overtimeRequest">
      <div class="mb-2 ml-2">
        <RouterLink to="../?force=true">
          <div class="is-inline-flex is-align-items-center">
            <b-icon icon="chevron-left" />
            <p>{{ $tf("overtimeRequestHandle.backToList|Túlórakérelmek") }}</p>
          </div>
        </RouterLink>
      </div>
      <div class="columns">
        <div class="column is-6">
          <article class="panel is-info">
            <div class="panel-heading">
              {{ $tf("overtimeRequestHandle.details|Kérelem adatai") }}
            </div>

            <div class="panel-block">
              <section class="request-details">
                <b-field
                  horizontal
                  :label="$tf('overtimeRequestHandle.date|Kérelem dátuma')"
                >
                  <b-input
                    :value="formatDate(overtimeRequest.created)"
                    disabled
                  />
                </b-field>
                <b-field
                  horizontal
                  :label="$tf('overtimeRequestHandle.name|Név')"
                >
                  <b-input
                    :value="getDisplayName(overtimeRequest.user)"
                    disabled
                  />
                </b-field>
                <b-field
                  horizontal
                  :label="$tf('overtimeRequestHandle.status|Státusz')"
                >
                  <b-input
                    :value="
                      $tf(OVERTIME_REQUEST_STATUS.LABEL[overtimeRequest.status])
                    "
                    disabled
                  />
                </b-field>
                <b-field
                  horizontal
                  :label="$tf('overtimeRequestHandle.overtimeDay|Túlóra napja')"
                >
                  <b-input
                    :value="formatDate(overtimeRequest.timeRecordDay)"
                    disabled
                  />
                </b-field>
                <b-field
                  horizontal
                  :label="$tf('overtimeRequestHandle.timeSpent|Ráfodított idő')"
                >
                  <b-input
                    :value="overtimeRequest.seconds / 60 / 60 + 'h'"
                    disabled
                  />
                </b-field>
                <b-field
                  horizontal
                  :label="$tf('overtimeRequestHandle.issue|Jegy')"
                >
                  <a
                    :href="createTicketUrl(overtimeRequest.issueKey)"
                    target="_blank"
                    >{{ overtimeRequest.issueKey }}</a
                  >
                </b-field>

                <b-field
                  horizontal
                  :label="$tf('overtimeRequestHandle.type|Típus')"
                >
                  <b-select v-model="request.type" expanded>
                    <option
                      v-for="(key, value) in OVERTIME_REQUEST_TYPE.LABEL"
                      :key="value"
                      :value="value"
                    >
                      {{ $tf(key) }}
                    </option>
                  </b-select>
                </b-field>

                <b-field
                  horizontal
                  :label="$tf('overtimeRequestHandle.multiplier|Szorzó')"
                >
                  <b-select v-model="request.overtimePolicyId" expanded>
                    <option
                      v-for="overtimePolicy in overtimePolicies"
                      :key="overtimePolicy.id"
                      :value="overtimePolicy.id"
                    >
                      {{ overtimePolicy.name }} -
                      {{ overtimePolicy.multiplier }}x
                    </option>
                  </b-select>
                </b-field>

                <div class="panel-block">
                  <b-field grouped group-multiline>
                    <b-field>
                      <b-button type="is-success" @click="approve">
                        {{ $tf("overtimeRequestHandle.approve|Elfogadás") }}
                      </b-button>
                    </b-field>
                    <b-field>
                      <b-button type="is-danger" @click="deny">
                        {{ $tf("overtimeRequestHandle.deny|Elutasítás") }}
                      </b-button>
                    </b-field>
                  </b-field>
                  <br />
                </div>
              </section>
            </div>
          </article>
        </div>

        <div class="column is-6">
          <article class="panel is-light">
            <div class="panel-heading">
              {{ $tf("overtimeRequestHandle.userDetails|Felhasználó adatai") }}
            </div>
            <div class="panel-block">
              <section>
                <b-collapse
                  animation="slide"
                  v-model="isWaitingRequestsOpen"
                  aria-id="waitingTable"
                >
                  <template v-slot:trigger>
                    <div role="button" aria-controls="waitingTable">
                      <strong
                        ><a>
                          {{
                            $tf(
                              "overtimeRequestHandle.requestsOfUser|@{user} döntésre váró kérelmei",
                              { user: overtimeRequest.user }
                            )
                          }}
                        </a></strong
                      >
                    </div>
                  </template>

                  <OvertimeRequestTable
                    :items="overtimeRequestsWaiting.items"
                    :pagination="paginationWaiting"
                    :sort="sortWaiting"
                    :on-sort="onSortWaiting"
                    :on-page-change="onPageChangeWaiting"
                    :empty-message="
                      $tf(
                        'overtimeRequestHandle.noPendingRequestForUser|Nincs elbírálásra váró túlórakérelem.'
                      )
                    "
                    :actions="false"
                  />
                </b-collapse>

                <b-collapse
                  aria-id="pastTable"
                  animation="slide"
                  v-model="isPastRequestOpen"
                >
                  <template v-slot:trigger>
                    <div role="button" aria-controls="pastTable">
                      <strong
                        ><a>{{
                          $tf(
                            "overtimeRequestHandle.previousRequestsOfUser|@{user} korábbi kérelmei",
                            { user: overtimeRequest.user }
                          )
                        }}</a></strong
                      >
                    </div>
                  </template>

                  <OvertimeRequestTable
                    :items="overtimeRequestsPast.items"
                    :pagination="paginationPast"
                    :sort="sortPast"
                    :on-sort="onSortPast"
                    :on-page-change="onPageChangePast"
                    :empty-message="
                      $tf(
                        'overtimeRequestHandle.noPastRequestForUser|Nincs korábbi túlórakérelem.'
                      )
                    "
                    :actions="false"
                  />
                </b-collapse>
              </section>
            </div>
          </article>
        </div>
      </div>
    </div>

    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  createTicketUrl,
  createUrlSearchParams,
  getDisplayName,
  formatDate,
} from "@/utils/util";
import { OVERTIME_REQUEST_STATUS, OVERTIME_REQUEST_TYPE } from "@/utils/const";
import OvertimeRequestTable from "../../../../components/enterprise/overtime/OvertimeRequestTable";

export default {
  name: "HandleOvertimeRequestForm",
  components: { OvertimeRequestTable },
  async mounted() {
    this.id = parseInt(this.$route.params.id);

    await this.getOvertimeRequest();
    await this.getWaitingOvertimeRequests();
    await this.getPastOvertimeRequests();
    //await this.$store.dispatch("employee/fetch");
    await this.$store.dispatch("overtime_policies/fetchActiveOvertimePolicies");

    if (this.overtimePolicies && this.overtimePolicies.length > 0) {
      this.request.overtimePolicyId = this.overtimePolicies[0].id;
    }

    this.isLoading = false;
  },
  data() {
    return {
      createTicketUrl,
      OVERTIME_REQUEST_STATUS,
      OVERTIME_REQUEST_TYPE,
      formatDate,
      id: null,
      isWaitingRequestsOpen: false,
      isPastRequestOpen: false,
      isLoading: true,
      paginationPast: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sortPast: {
        field: "created",
        order: "desc",
      },
      paginationWaiting: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sortWaiting: {
        field: "created",
        order: "desc",
      },
      request: {
        overtimePolicyId: "",
        type: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      overtimeRequestsWaiting: "overtime_requests/waitingOvertimeRequest",
      overtimeRequestsPast: "overtime_requests/pastOvertimeRequest",
      overtimeRequest: "overtime_requests/overtimeRequest",
      employees: "employee/employees",
      overtimePolicies: "overtime_policies/getActiveOvertimePolicies",
    }),
  },
  methods: {
    async getOvertimeRequest() {
      await this.$store.dispatch(
        "overtime_requests/getOvertimeRequest",
        this.id
      );

      await this.getPastOvertimeRequests();
      await this.getWaitingOvertimeRequests();

      this.request.type = this.overtimeRequest.type;
    },
    async getPastOvertimeRequests() {
      await this.$store.dispatch(
        "overtime_requests/fetchPastOvertimeRequests",
        {
          user: this.overtimeRequest.user,
          pageable: {
            params: createUrlSearchParams(this.paginationPast, this.sortPast),
          },
        }
      );
      this.paginationPast = this.overtimeRequestsPast.pagination;
    },
    async getWaitingOvertimeRequests() {
      await this.$store.dispatch(
        "overtime_requests/fetchWaitingOvertimeRequests",
        {
          user: this.overtimeRequest.user,
          pageable: {
            params: createUrlSearchParams(
              this.paginationWaiting,
              this.sortWaiting
            ),
          },
        }
      );
      this.paginationWaiting = this.overtimeRequestsWaiting.pagination;
    },
    getDisplayName(user) {
      return getDisplayName(this.employees, user);
    },
    approve() {
      this.$buefy.dialog.confirm({
        type: "is-warning",
        hasIcon: true,
        title: "Túlóra kérelem elfogadása",
        message: "Biztos, hogy szeretnéd elfogadni a túlóra kérelmet?",
        cancelText: "Mégsem",
        confirmText: "Igen",
        onConfirm: async () =>
          this.$store
            .dispatch("overtime_requests/approve", {
              id: this.overtimeRequest.id,
              request: this.request,
            })
            .then(() => {
              this.$store.dispatch(
                "overtime_requests/getOvertimeRequestToApprove",
                {
                  force: true,
                }
              );
              this.$router.push("/approval/overtime");
            }),
      });
    },
    deny() {
      this.$buefy.dialog.confirm({
        type: "is-warning",
        hasIcon: true,
        title: "Túlóra kérelem elfogadása",
        message: "Biztos, hogy szeretnéd elutasítani a túlóra kérelmet?",
        cancelText: "Mégsem",
        confirmText: "Igen",
        onConfirm: async () =>
          this.$store
            .dispatch("overtime_requests/deny", this.overtimeRequest.id)
            .then(() => {
              this.$store.dispatch(
                "overtime_requests/getOvertimeRequestToApprove",
                {
                  force: true,
                }
              );
              this.$router.push("/approval/overtime");
            }),
      });
    },
    onPageChangePast: function (page) {
      this.paginationPast.page = page - 1;
      this.getPastOvertimeRequests();
    },
    onSortPast: function (field, order) {
      this.sortPast.field = field;
      this.sortPast.order = order;
      this.getPastOvertimeRequests();
    },
    onPageChangeWaiting: function (page) {
      this.paginationWaiting.page = page - 1;
      this.getWaitingOvertimeRequests();
    },
    onSortWaiting: function (field, order) {
      this.sortWaiting.field = field;
      this.sortWaiting.order = order;
      this.getWaitingOvertimeRequests();
    },
  },
};
</script>

<style scoped>
.request-details {
  width: 40rem;
}
</style>
