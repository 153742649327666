<template>
  <div>
    <div class="is-flex is-flex-direction-column has-gap-4">
      <normal-project-number-cards
        v-if="projectData.status === PROJECT_STATUS_ENUM.ONGOING"
        :project-details="projectDetails"
        :project-data="projectData"
      />
      <project-history :project-id="projectData.id" />
      <!--      <project-focus :project-id="projectData.id" />-->

      <div class="newForecastCard">
        <div class="is-flex is-justify-content-space-between">
          <h3 class="title">{{ statusTableTitle }}</h3>
          <b-tabs
            v-model="statusTables"
            type="is-toggle"
            class="tabs-no-margin"
          >
            <b-tab-item>
              <template #header>
                <div class="mx-4">
                  {{ $tf("projectPage.statusTables.tab.whole|Teljes projekt") }}
                </div>
              </template>
            </b-tab-item>

            <b-tab-item>
              <template #header>
                <div class="mx-4">
                  {{
                    $tf(
                      "projectPage.statusTables.tab.sinceStart|Eddig eltelt időszak"
                    )
                  }}
                </div>
              </template>
            </b-tab-item>

            <b-tab-item>
              <template #header>
                <div class="mx-4">
                  {{
                    $tf("projectPage.statusTables.tab.future|Hátralévő időszak")
                  }}
                </div>
              </template>
            </b-tab-item>
          </b-tabs>
        </div>
        <div v-if="statusTables === 0">
          <ForecastWholeStatusOverview
            :project-data="projectData"
            :project-details="projectDetails"
          ></ForecastWholeStatusOverview>
        </div>
        <div v-if="statusTables === 1">
          <ForecastSinceStartStatusOverview
            :project-data="projectData"
            :project-details="projectDetails"
          ></ForecastSinceStartStatusOverview>
        </div>
        <div v-if="statusTables === 2">
          <ForecastFutureStatusOverview
            :project-data="projectData"
            :project-details="projectDetails"
          ></ForecastFutureStatusOverview>
        </div>
      </div>
      <normal-project-timeline-cards
        v-if="projectData.status === PROJECT_STATUS_ENUM.ONGOING"
        :project-data="projectData"
        :project-details="projectDetails"
      />
      <div
        class="has-text-centered"
        v-if="projectData.status !== PROJECT_STATUS_ENUM.ONGOING"
      >
        <h5>
          <span class="is-italic">{{
            $tf(
              "projectPage.forecastAndProgressNotAvailable|A forecast és a folyamati adatok nem érhetőek el."
            )
          }}</span>
          <b-tooltip
            :auto-close="['outside', 'escape']"
            :triggers="['click']"
            type="is-info"
            :label="
              $tf(
                'projectPage.forecastAndProgressNotAvailable.reason|Forecast adatok kizárólag folyamatban lévő projekteknél érhetőek el'
              )
            "
          >
            <p class="is-dotted-underlined is-italic has-text-blue">
              {{
                $tf("projectPage.forecastAndProgressNotAvailable.why|Miért?")
              }}
            </p>
          </b-tooltip>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, formatDateTime, moneyify, percentify } from "@/utils/util";
import ForecastSinceStartStatusOverview from "@/components/enterprise/project/module/forecast/ForecastSinceStartStatusOverview.vue";
import ForecastFutureStatusOverview from "@/components/enterprise/project/module/forecast/ForecastFutureStatusOverview.vue";
import ForecastWholeStatusOverview from "@/components/enterprise/project/module/forecast/ForecastWholeStatusOverview.vue";
import NormalProjectNumberCards from "@/components/enterprise/ProjectDetails/NormalProjectNumberCards.vue";
import NormalProjectTimelineCards from "@/components/enterprise/ProjectDetails/NormalProjectTimelineCards.vue";
// import ProjectFocus from "@/components/enterprise/project/module/ProjectFocus.vue";
import ProjectHistory from "@/components/enterprise/project/module/ProjectHistory.vue";
import { PROJECT_STATUS_ENUM } from "@/utils/const";

export default {
  name: "ProjectStatus",
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
    bars: {
      type: Object,
      required: true,
    },
    forecastBars: {
      type: Object,
      required: true,
    },
    projectIdentifier: {
      type: String,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  components: {
    NormalProjectTimelineCards,
    NormalProjectNumberCards,
    ForecastFutureStatusOverview,
    ForecastSinceStartStatusOverview,
    ForecastWholeStatusOverview,
    // ProjectFocus,
    ProjectHistory,
  },
  data() {
    return {
      PROJECT_STATUS_ENUM,
      moneyify,
      formatDate,
      today: formatDate(new Date()),
      statusTables: 0,
      dueDate: this.projectDetails.dueDate,
      selectedTig: null,
    };
  },
  computed: {
    statusTableTitle() {
      switch (this.statusTables) {
        case 0:
          return this.$tf(
            "projectPage.statusTables.tableTitle.whole|Teljes projekt részletei"
          );
        case 1:
          return this.$tf(
            "projectPage.statusTables.tableTitle.sinceStart|Eddig eltelt időszak részletei"
          );
        case 2:
          return this.$tf(
            "projectPage.statusTables.tableTitle.future|Hátralévő időszak részletei"
          );
        default:
          return "";
      }
    },
    isReady() {
      return this.today > this.projectData.startDate;
    },
  },
  methods: {
    percentify,
    formatDateTime,
  },
};
</script>

<style>
.b-tabs.tabs-no-margin ul {
  margin-top: 0 !important;
}
</style>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
@import "~@/assets/scss/media-queries.scss";

//@include xxl-min {
//  .status-tables {
//    display: flex;
//    flex-direction: row;
//    &-table {
//      flex: 1 0 50%;
//    }
//  }
//}

.timeline {
  h5 {
    color: $custom-dark-blue;
    font-size: 1rem;
  }
  &-times {
    max-width: 460px;
  }
  &-dates {
    max-width: 340px;
    padding-top: 2.875rem;
    .column {
      &:first-child {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 1.25rem;
          bottom: calc(0.313rem + 1rem);
          border-right: 1px solid $grey-lightest;
        }
      }
    }
  }
  &-date {
    padding: 0.625rem;
    max-width: 160px;
    margin: 0 auto;

    strong {
      color: $custom-dark-blue;
    }
    span {
      color: $custom-grayer-blue;
    }
  }
  &-line {
    margin: 4rem 10% 0 0;
    font-size: 0.875rem;
    &.not-ready {
      margin-left: 5%;
      .timeline-line-start {
        border-radius: 0.5rem 0 0 0.5rem;
        background: $grey-lightest;
        opacity: 0.7;
        left: -6%;
      }
    }
    &-holder {
      height: 1rem;
      background-color: $grey-lightest;
    }
    &-start {
      background: linear-gradient(270deg, #7b61ff 14.55%, #0aa6fe 75.13%);
      border-radius: 0 0.5rem 0.5rem 0;
      height: 1rem;
      position: absolute;
      left: 0;
      top: 0;
    }
    &-end {
      background: repeating-linear-gradient(
        -45deg,
        $red,
        $red 6px,
        $white 6px,
        $white 12px
      );
      border-radius: 0 0.5rem 0.5rem 0;
      height: 1rem;
      position: absolute;
      left: calc(100% + 2px);
      top: 0;
      width: 7%;
    }
    &-end-text,
    &-today-text {
      background-color: $custom-light-blue;
      width: 2px;
      height: 2.5rem;
      margin-top: 0.813rem;
      position: absolute;
      top: -1.5rem;
      color: $custom-grayer-blue;
      span {
        color: $custom-light-blue;
        font-weight: 500;
        &.is-danger {
          color: $red;
        }
      }
    }
    &-today-text {
      top: -2.6rem;
      height: 3.6rem;
      z-index: 10;
    }
    &-end-text {
      left: 100%;
      margin-left: 0;
    }
    .is-absolute {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      top: -1.5rem;
    }
  }
  &-months {
    font-size: 0.875rem;
    font-weight: 500;
    &-day {
      margin-left: -20px;
    }
    .column {
      position: relative;
      padding: 1.563rem 0 2.5rem 0;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        background-color: rgba($custom-grayer-blue, 0.5);
        width: 2px;
        height: 0.813rem;
        left: 0;
      }
      &:first-child {
      }
      &:last-child {
        position: absolute;
        left: 100%;
        white-space: nowrap;
      }
    }
  }
}
.is-danger {
  color: $red !important;
}
.is-success {
  color: $green !important;
}
.project-tig-tabs {
  background: $grey-lightest;
  margin-left: -3.5em;
  margin-right: -3.5em;
  margin-bottom: -2em;
}

.progress-history-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 3fr 4fr;
  grid-gap: 8px;
  max-width: 792px;
}
</style>
