<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 24rem">
      <section class="modal-card-body">
        <h1 class="title">
          {{ $tf("tiers.import.modal.title|Szintek importálása") }}
        </h1>

        <form>
          <b-field
            :label="$tf('tiers.import.modal.validFrom|Érvényesség kezdete')"
            label-position="on-border"
          >
            <f-datepicker
              v-model="validFrom"
              :placeholder="
                $tf('tiers.import.modal.validFromPlaceholder|Kezdeti dátum')
              "
              append-to-body
              icon="calendar-alt"
            >
            </f-datepicker>
          </b-field>
          <b-field>
            <b-upload
              v-model="file"
              :loading="isImportInProgress"
              drag-drop
              expanded
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"></b-icon>
                  </p>
                  <p v-if="file">{{ file.name }}</p>
                  <p v-else>
                    {{
                      $tf(
                        "tiers.import.modal.uploadText|Húzd ide a fájlt vagy kattints a feltöltéshez."
                      )
                    }}
                  </p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <b-field class="is-pulled-right">
            <b-button @click="close">
              {{ $tf("tiers.import.modal.cancel|Mégse") }}
            </b-button>
            <b-button
              :disabled="file === null"
              :loading="isImportInProgress"
              class="ml-3"
              type="is-info"
              @click="save"
            >
              {{ $tf("tiers.import.modal.save|Mentés") }}
            </b-button>
          </b-field>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/util";
import { getCurrentInstance } from "vue";
import FDatepicker from "@/components/module/input/FDatepicker.vue";

export default {
  name: "TierImportModal",
  components: { FDatepicker },
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  data() {
    return {
      file: null,
      validFrom: new Date(),
      isImportInProgress: false,
    };
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    async save() {
      this.isImportInProgress = true;
      let formData = new FormData();
      formData.append("file", this.file);
      await this.$store.dispatch("enterprise_tiers/importTiers", {
        formData,
        validFrom: formatDate(this.validFrom),
      });
      this.isImportInProgress = false;
      this.$emit("save");
      this.close();
    },
  },
};
</script>
