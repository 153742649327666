<template>
  <div>
    <b-field group-multiline grouped>
      <div v-if="filter['user']" class="control">
        <b-taglist v-if="filter['user']" attached class="filter-tags">
          <b-tag type="is-dark">
            {{ $tf("adminAbsenceRequests.name|Név") }}
          </b-tag>
          <b-tag
            aria-close-label="Remove filter"
            closable
            type="is-danger"
            @close="removeFilter('user')"
          >
            <span v-for="user in filter['user']" :key="user.id"
              >{{ user.name
              }}<span
                v-if="
                  filter['user'].indexOf(user) !== filter['user'].length - 1
                "
                >,
              </span></span
            >
          </b-tag>
        </b-taglist>
      </div>

      <div v-if="filter['createdFrom']" class="control">
        <b-taglist v-if="filter['createdFrom']" attached class="filter-tags">
          <b-tag type="is-dark"
            >{{
              $tf(
                "adminAbsenceRequests.filter.createdAfter|Létrehozva később, mint"
              )
            }}
          </b-tag>
          <b-tag
            aria-close-label="Remove filter"
            closable
            type="is-danger"
            @close="removeFilter('createdFrom')"
          >
            {{
              $route.params.createdFrom
                ? $route.params.createdFrom
                : filter["createdFrom"]
            }}
          </b-tag>
        </b-taglist>
      </div>
      <div v-if="filter['createdTo']" class="control">
        <b-taglist v-if="filter['createdTo']" attached class="filter-tags">
          <b-tag type="is-dark">
            {{
              $tf(
                "adminAbsenceRequests.filter.createdBefore|Létrehozva korábban, mint"
              )
            }}
          </b-tag>
          <b-tag
            aria-close-label="Remove filter"
            closable
            type="is-danger"
            @close="removeFilter('createdTo')"
          >
            {{
              $route.params.createdTo
                ? $route.params.createdTo
                : filter["createdTo"]
            }}
          </b-tag>
        </b-taglist>
      </div>

      <div v-if="filter['status']" class="control">
        <b-taglist v-if="filter['status']" attached class="filter-tags">
          <b-tag type="is-dark">
            {{ $tf("adminAbsenceRequests.filter.status|Státusz") }}
          </b-tag>
          <b-tag
            aria-close-label="Remove filter"
            closable
            type="is-danger"
            @close="removeFilter('status')"
          >
            {{ getStatusDisplay(filter["status"]) }}
          </b-tag>
        </b-taglist>
      </div>

      <div v-if="filter['type']" class="control">
        <b-taglist v-if="filter['type']" attached class="filter-tags">
          <b-tag type="is-dark">
            {{ $tf("adminAbsenceRequests.filter.type|Típus") }}
          </b-tag>
          <b-tag
            aria-close-label="Remove filter"
            closable
            type="is-danger"
            @close="removeFilter('type')"
          >
            {{ getTypeDisplay(filter["type"]) }}
          </b-tag>
        </b-taglist>
      </div>
    </b-field>

    <b-table
      ref="table"
      :data="absenceRequests ? absenceRequests.items : []"
      :loading="loading"
      :opened-detailed="defaultOpenedDetails"
      :per-page="pagination.size"
      :total="pagination.totalCount"
      backend-filtering
      backend-pagination
      backend-sorting
      default-sort="created"
      default-sort-direction="desc"
      detail-key="id"
      detailed
      hoverable
      mobile-cards
      paginated
      striped
      @sort="onSort"
      @details-open="(row) => closeAllOtherDetails(row)"
      @page-change="onPageChange"
      @filters-change="onFilterChange"
    >
      <b-table-column
        :label="$tf('adminAbsenceRequests.table.date|Kérelem dátuma')"
        field="created"
        searchable
        sortable
        width="110"
      >
        <template #searchable="props">
          <b-field>
            <f-datepicker v-model="props.filters.createdFrom" />
            <f-datepicker v-model="props.filters.createdTo" />
          </b-field>
        </template>

        <template v-slot="props">
          {{ formatDate(props.row.created) }}
        </template>
      </b-table-column>

      <b-table-column
        :custom-sort="
          (a, b, isAsc) =>
            isAsc
              ? Intl.Collator('hu').compare(a.user, b.user)
              : Intl.Collator('hu').compare(b.user, a.user)
        "
        :label="$tf('adminAbsenceRequests.table.name|Név')"
        field="user"
        searchable
        sortable
        width="150"
      >
        <template #searchable="props">
          <multiselect-autocomplete
            v-model="props.filters.user"
            :data="employees"
            :placeholder="
              $tf(
                'adminAbsenceRequests.table.name.search.placeholder|Elektrom Ágnes'
              )
            "
            field="name"
          ></multiselect-autocomplete>
        </template>
        <template v-slot="props">
          {{ props.row.userData.name }}
        </template>
      </b-table-column>

      <b-table-column
        :label="$tf('adminAbsenceRequests.table.type|Típus')"
        field="type"
        searchable
        sortable
      >
        <template #searchable="props">
          <b-select
            v-model="props.filters[props.column.field]"
            style="max-width: 100px"
          >
            <option :value="null">
              {{ $tf("adminAbsenceRequests.table.type.all|Összes") }}
            </option>
            <option
              v-for="type in absenceTypes"
              :key="type.id"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </b-select>
        </template>

        <template v-slot="props">
          {{ props.row.type.name }}
        </template>
      </b-table-column>

      <b-table-column
        :label="$tf('adminAbsenceRequests.table.status|Státusz')"
        field="status"
        searchable
        sortable
      >
        <template #searchable="props">
          <b-select
            v-model="props.filters[props.column.field]"
            style="max-width: 150px"
          >
            <option :value="null">
              {{ $tf("adminAbsenceRequests.table.status.all|Összes") }}
            </option>
            <option :value="'REQUEST'">
              {{ $tf("adminAbsenceRequests.table.status.request|Kérelem") }}
            </option>
            <option :value="'LINE_APPROVED'">
              {{
                $tf(
                  "adminAbsenceRequests.table.status.lineApproved|Vezető által elfogadva"
                )
              }}
            </option>
            <option :value="'APPROVED'">
              {{ $tf("adminAbsenceRequests.table.status.approved|Elfogadva") }}
            </option>
            <option :value="'ACTIVE'">
              {{ $tf("adminAbsenceRequests.table.status.active|Aktív") }}
            </option>
            <option :value="'TAKEN'">
              {{ $tf("adminAbsenceRequests.table.status.taken|Felhasznált") }}
            </option>
            <option :value="'DENIED'">
              {{ $tf("adminAbsenceRequests.table.status.denied|Elutasítva") }}
            </option>
            <option :value="'REVOKED'">
              {{ $tf("adminAbsenceRequests.table.status.revoked|Visszavont") }}
            </option>
          </b-select>
        </template>

        <template v-slot="props">
          {{ props.row.status ? props.row.status.display : "-" }}
        </template>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('adminAbsenceRequests.table.description|Leírás')"
        centered
        field="description"
      >
        {{ props.row.description }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('adminAbsenceRequests.table.requestDate|Dátum')"
        centered
        field="date"
      >
        <div
          class="is-flex is-flex-wrap-wrap has-gap-1 is-justify-content-center is-align-items-center"
        >
          <b-tooltip
            v-if="props.row.carryOver"
            :label="
              $tf(
                'adminAbsenceRequests.table.carryOver.tooltip|Előző évről áthozott'
              )
            "
            class="mr-2"
            type="is-light"
          >
            <div class="is-flex is-align-items-center">
              <b-icon icon="clock-rotate-left" size="is-small" />
            </div>
          </b-tooltip>
          <b-tag
            v-for="(actDate, index) in props.row.absenceRequestDates"
            :key="index"
            :type="getDateType(props.row.status ? props.row.status.enum : null)"
          >
            {{ formatDate(actDate) }}
          </b-tag>
        </div>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('adminAbsenceRequests.table.actions|Műveletek')"
        centered
        field="actions"
      >
        <b-tooltip
          :label="
            $tf('adminAbsenceRequests.table.actions.aceept|Kérelem elfogadása')
          "
          type="is-light"
        >
          <clickable-icon
            :disabled="
              props.row.status.enum === 'REVOKED' ||
              props.row.status.enum === 'APPROVED' ||
              props.row.status.enum === 'TAKEN' ||
              props.row.status.enum === 'ACTIVE'
            "
            :type="
              props.row.status.enum === 'REVOKED' ||
              props.row.status.enum === 'APPROVED' ||
              props.row.status.enum === 'TAKEN' ||
              props.row.status.enum === 'ACTIVE'
                ? null
                : 'is-success'
            "
            icon="check-circle"
            size="is-small"
            @click="approve(props.row.id, props.row.userData)"
          />
        </b-tooltip>

        <b-tooltip
          :label="
            $tf(
              'adminAbsenceRequests.table.actions.clarify|Egyeztetés szükséges'
            )
          "
          type="is-light"
        >
          <clickable-icon
            :disabled="
              props.row.status.enum === 'REVOKED' ||
              props.row.status.enum === 'TAKEN' ||
              props.row.status.enum === 'ACTIVE'
            "
            :type="
              props.row.status.enum === 'REVOKED' ||
              props.row.status.enum === 'TAKEN' ||
              props.row.status.enum === 'ACTIVE'
                ? null
                : 'is-warning'
            "
            icon="question-circle"
            size="is-small"
            @click="clarify(props.row.id, props.row.userData)"
          />
        </b-tooltip>

        <b-tooltip
          :label="
            $tf('adminAbsenceRequests.table.actions.deny|Kérelem elutasítása')
          "
          type="is-light"
        >
          <clickable-icon
            :disabled="
              props.row.status.enum === 'REVOKED' ||
              props.row.status.enum === 'DENIED' ||
              props.row.status.enum === 'TAKEN' ||
              props.row.status.enum === 'ACTIVE'
            "
            :type="
              props.row.status.enum === 'REVOKED' ||
              props.row.status.enum === 'DENIED' ||
              props.row.status.enum === 'TAKEN' ||
              props.row.status.enum === 'ACTIVE'
                ? null
                : 'is-danger'
            "
            icon="times-circle"
            size="is-small"
            @click="deny(props.row.id, props.row.userData)"
          />
        </b-tooltip>
      </b-table-column>

      <template #detail="props">
        <commented-approval
          :key="commentedApprovalKey"
          :absence-request-id="props.row.id"
        ></commented-approval>
      </template>

      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="smile-beam" size="is-large"></b-icon>
            </p>
            <p>
              {{
                $tf(
                  "adminAbsenceRequests.table.empty|Jelenleg nincs távollét kérelem"
                )
              }}
            </p>
          </div>
        </section>
      </template>

      <template #bottom-left>
        <div class="is-flex has-gap-1 is-align-items-center">
          <div>
            {{ $tf("adminAbsenceRequests.table.pageSize|Oldal mérete") }}
          </div>
          <div id="size-selector" class="ml-2 mr-2">
            <b-select
              v-model="pagination.size"
              size="is-small"
              @update:modelValue="getAbsenceRequests"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
              <option :value="100">100</option>
            </b-select>
          </div>
          <div>
            {{ $tf("adminAbsenceRequests.table.sum|Összesen:")
            }}<strong>{{ pagination.totalCount }}</strong>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import CommentedApproval from "@/components/absence/admin/CommentedApproval";
import { mapGetters } from "vuex";
import { formatDate } from "@/utils/util";
import debounce from "lodash.debounce";
import { nextTick } from "vue";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import MultiselectAutocomplete from "@/components/module/input/MultiselectAutocomplete";
import FDatepicker from "@/components/module/input/FDatepicker.vue";

export default {
  name: "AbsenceRequestList",
  components: {
    FDatepicker,
    CommentedApproval,
    ClickableIcon,
    MultiselectAutocomplete,
  },
  async mounted() {
    await this.getAbsenceRequests();
    // await this.$store.dispatch("employee/fetch");
    await this.$store.dispatch("absence_request/getAdminTypes");
  },
  data() {
    return {
      formatDate,
      commentedApprovalKey: 0,
      defaultOpenedDetails: [1],
      pagination: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sort: {
        field: "created",
        order: "desc",
      },
      filter: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      absenceRequests: "absence_request/adminAbsenceRequests",
      absenceTypes: "absence_request/types",
      employees: "employee/employees",
    }),
  },
  methods: {
    async getAbsenceRequests(force) {
      this.loading = true;
      const { page, size } = this.pagination;
      const sort =
        this.sort.field !== null
          ? `${this.sort.field},${this.sort.order}`
          : null;

      let params = new URLSearchParams();
      params.append("page", page);
      params.append("size", size);
      params.append("sort", sort);

      for (const [key, value] of Object.entries(this.filter)) {
        if (value && key !== "user") {
          params.append(key, value);
        } else if (value && key === "user") {
          params.append(
            "userId",
            this.filter.user.map((user) => user.id)
          );
        }
      }

      let requestParams = { params: params };

      await this.$store
        .dispatch("absence_request/getAdminAbsenceRequests", {
          params: requestParams,
          force,
        })
        .then(() => {
          this.pagination = this.absenceRequests.pagination;
        });
      this.loading = false;
    },
    approve(absenceId, user) {
      this.$buefy.dialog.prompt({
        type: "is-success",
        hasIcon: true,
        title: "Kérelem elfogadása",
        message: `Biztosan elfogadod <strong>${user.name}</strong> a kérelmet?`,
        cancelText: "Mégsem",
        confirmText: "Elfogadás",
        inputAttrs: {
          placeholder: "Indoklás, rövid üzenet",
          maxlength: 255,
          size: 250,
          autocomplete: "off",
          required: true,
        },
        //megj: mivel ez admin felület, itt azonnal APPROVE lesz a státusz.
        onConfirm: (value) =>
          this.sendCommentedApproval(value, "APPROVE", user.id, absenceId),
      });
    },
    deny(absenceId, user) {
      this.$buefy.dialog.prompt({
        type: "is-danger",
        hasIcon: true,
        title: "Kérelem elutasítása",
        message: `Biztosan elutasítod <strong>${user.name}</strong> a kérelmet?`,
        cancelText: "Mégsem",
        confirmText: "Elutasítás",
        inputAttrs: {
          placeholder: "Indoklás, rövid üzenet",
          maxlength: 255,
          size: 250,
          autocomplete: "off",
          required: true,
        },
        onConfirm: (value) =>
          this.sendCommentedApproval(value, "DENY", user.id, absenceId),
      });
    },
    clarify(absenceId, user) {
      this.$buefy.dialog.prompt({
        type: "is-warning",
        hasIcon: true,
        title: "Egyeztetés szükséges",
        message: `Üzenet küldése <strong> ${user.name} </strong> számára?`,
        cancelText: "Mégsem",
        confirmText: "Küldés",
        inputAttrs: {
          placeholder: "Probléma rövid leírása",
          maxlength: 255,
          size: 250,
          autocomplete: "off",
          required: true,
        },
        onConfirm: (value) =>
          this.sendCommentedApproval(value, "CLARIFY", user.id, absenceId),
      });
    },
    sendCommentedApproval(comment, decision, user, absenceId) {
      let payload = {
        comment: comment,
        approval: decision,
        user: user,
        absenceRequestId: absenceId,
      };
      this.$store.dispatch("absence_request/adminApprove", payload).then(() => {
        this.getAbsenceRequests(true);
        this.forceRerenderCommentedApprovalComponent();
        this.$store.dispatch("admin/getWaitingRequests", true);
      });
    },

    forceRerenderCommentedApprovalComponent() {
      this.commentedApprovalKey += 1;
    },
    closeAllOtherDetails(row) {
      nextTick(() => {
        this.defaultOpenedDetails = [row.id];
      });
    },
    getDateType(status) {
      switch (status) {
        case "REQUEST":
          return "is-info";
        case "LINE_APPROVED":
          return "is-info";
        case "APPROVED":
          return "is-info";
        case "ACTIVE":
          return "is-success";
        case "TAKEN":
          return "is-light";
        case "DENIED":
          return "is-danger";
        case "REVOKED":
          return "is-light";
      }
    },

    getStatusDisplay(status) {
      switch (status) {
        case "REQUEST":
          return "Kérelem";
        case "LINE_APPROVED":
          return "Vezető által elfogadva";
        case "APPROVED":
          return "Elfogadva";
        case "ACTIVE":
          return "Aktív";
        case "TAKEN":
          return "Felhasznált";
        case "DENIED":
          return "Elutasított";
        case "REVOKED":
          return "Visszavont";
      }
    },

    getTypeDisplay(type) {
      return this.absenceTypes.find((absenceType) => absenceType.id === type)
        .name;
    },
    toggleDetails: function (row) {
      this.$refs.table.toggleDetails(row);
    },

    onPageChange: function (page) {
      this.pagination.page = page - 1;
      this.getAbsenceRequests();
    },
    onSort: function (field, order) {
      this.sort.field = field;
      this.sort.order = order;
      this.getAbsenceRequests();
    },

    onFilterChange: debounce(
      function (filter) {
        console.log(filter);
        const { createdFrom, createdTo, user, type, status } = filter;
        this.filter = {
          type,
          status,
          user,
          createdFrom: createdFrom ? formatDate(createdFrom) : null,
          createdTo: createdTo ? formatDate(createdTo) : null,
        };
        this.getAbsenceRequests();
      },
      500,
      { leading: true }
    ),

    removeFilter: function (filteredField) {
      this.$refs.table.filters[filteredField] = null;
      this.filter[filteredField] = null;
      this.getAbsenceRequests();
    },

    // filterRequestsByName(user) { fixme
    //   let requests = this.requests.items.filter((option) => {
    //     return (
    //       option.user.toString().toLowerCase().indexOf(user.toLowerCase()) >= 0
    //     );
    //   });
    //   for (let i in requests) {
    //     requests[i] = requests[i].userId;
    //   }
    //   return requests;
    // },
  },
};
</script>

<style scoped></style>
