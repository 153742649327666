<template>
  <div class="container">
    <div class="is-flex gap is-align-items-center">
      <div>
        <h1 class="title">
          {{
            $tf("projectPage.briefData.title|{name} ({key}) részletei", {
              name: projectData.name,
              key: projectData.identifier,
            })
          }}
        </h1>
      </div>
      <b-tag
        :type="
          getProjectStatusObject(projectData.status, projectDetails.archived)
            .type
        "
      >
        {{
          getProjectStatusObject(projectData.status, projectDetails.archived)
            .text
        }}
      </b-tag>
      <b-tooltip
        label="A Projekt csillagozása egy jelölési lehetőség, ami minden felhasználó számára közös."
        position="is-right"
      >
        <star-icon
          :filled="starred"
          :clickable="canStarProject"
          @change="star"
          ref="star-icon"
        ></star-icon>
      </b-tooltip>
      <b-tooltip
        label="A Projekt szivecskézése egy jelölési lehetőség, ami felhasználónként egyedi, csak magadnak állítod be."
        position="is-right"
      >
        <heart-icon
          :filled="liked"
          :clickable="true"
          @change="changeLike"
          ref="heart-icon"
        ></heart-icon>
      </b-tooltip>
      <div
        class="mx-auto py-1 px-2 archived-tag"
        v-if="projectDetails.archived"
      >
        {{ $tf("projectPage.briefData.archived|Archivált projekt") }}
      </div>
      <div class="ml-auto is-flex has-gap-3">
        <b-button
          type="is-info"
          size="is-small"
          rounded
          @click="openStatusChangeModal"
          v-if="!projectDetails.archived"
        >
          {{ $tf("projectPage.briefData.statusChange|Státusz váltás") }}
        </b-button>

        <b-button
          type="is-info"
          size="is-small"
          rounded
          @click="onExportClick"
          :loading="isExportInProgress"
        >
          {{ $tf("projectPage.briefData.export|Exportálás") }}
        </b-button>
      </div>
    </div>
    <div class="brief">
      <div class="bunch">
        <div class="is-flex is-justify-content-space-between">
          <b-field v-if="projectType === 'BASIC'">
            <b-tooltip
              label="A Projekt Kezdeti dátuma, törzsadatként módosítható (naplózásra kerül)"
            >
              <LabelBox
                icon="hourglass-start"
                size="is-large"
                :header="$tf('projectPage.briefData.startDate|Kezdés')"
                :text="projectData.startDate"
              />
            </b-tooltip>
          </b-field>
          <b-field v-if="projectType === 'BASIC'">
            <b-tooltip
              label="A Projekt Határideje, törzsadatként módosítható (naplózásra kerül)"
            >
              <LabelBox
                icon="alarm-clock"
                size="is-large"
                :header="$tf('projectPage.briefData.dueDate|Határidő')"
                :text="projectDetails.dueDate"
              />
            </b-tooltip>
          </b-field>
          <b-field v-if="projectType === 'BASIC'">
            <b-tooltip
              label="A Projekt Büdzséje, törzsadatként módosítható (naplózásra kerül)"
            >
              <LabelBox
                icon="credit-card"
                size="is-large"
                :header="$tf('projectPage.briefData.budget|Büdzsé')"
                :text="moneyify(projectDetails.budget)"
              />
            </b-tooltip>
          </b-field>
          <b-field v-if="projectType === 'BASIC'">
            <b-tooltip
              label="A Projekt logolásai alapján számolt eddig összes költsége"
            >
              <LabelBox
                icon="money-bills"
                size="is-large"
                :header="$tf('projectPage.briefData.spent|Költés')"
                :text="moneyify(projectDetails.sumSpentBudget)"
              />
            </b-tooltip>
          </b-field>
          <b-field v-if="projectType === 'BASIC'">
            <b-tooltip
              label="A Projekt szerződés szerinti Bevétele, törzsadatként módosítható (naplózásra kerül)"
            >
              <LabelBox
                icon="sack-dollar"
                size="is-large"
                :header="$tf('projectPage.briefData.income|Bevétel')"
                :text="
                  projectData.internal
                    ? '-'
                    : moneyify(projectDetails.expectedIncome)
                "
              />
            </b-tooltip>
          </b-field>
          <b-field>
            <b-tooltip label="A Projekt felelős menedzsere">
              <UserInfo
                :user="getEmployeeDetailsByName(projectData.leadName)"
                displayMode="long"
                tooltipPosition="bottom"
                size="32x32"
              />
            </b-tooltip>
          </b-field>
          <b-field v-if="!projectData.internal">
            <b-tooltip label="A Projekt ügyfelee">
              <client-info
                :client="getClientDetailsByName(projectData.clientName)"
                displayMode="long"
                tooltipPosition="bottom"
                size="24x24"
              />
            </b-tooltip>
          </b-field>
          <b-field v-if="projectData.internal">
            <b-tooltip
              label="A projekt Belső Projektnek van jelölve, így a Bevételi beállítások ki vannak kapcsolva"
            >
              <LabelBox
                icon="house-chimney-window"
                size="is-large"
                header="Nincs ügyfél"
                text="Belső projekt"
              />
            </b-tooltip>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, getProjectStatusObject, moneyify } from "@/utils/util";
import LabelBox from "@/components/module/info/LabelBox";
import StatusChanger from "../../ProjectDetails/StatusChanger";
import StarIcon from "@/components/module/icon/StarIcon.vue";
import { PRIVILEGES } from "@/utils/const";
import HeartIcon from "@/components/module/icon/HeartIcon.vue";
import UserInfo from "@/components/module/info/UserInfo.vue";
import ClientInfo from "@/components/module/info/ClientInfo.vue";
import { mapGetters } from "vuex";
import FileSaver from "file-saver";
import ProjectExportModal from "@/components/enterprise/export/ProjectExportModal.vue";

export default {
  name: "BriefData",
  components: { HeartIcon, StarIcon, LabelBox, UserInfo, ClientInfo },
  mixins: [],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
    projectType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formatDate,
      getProjectStatusObject,
      starred: this.$props.projectData.starred,
      isExportInProgress: false,
    };
  },
  computed: {
    ...mapGetters({
      employees: "employee/employees",
      clients: "enterprise_clients/clients",
      likedProjects: "project_like/likedProjects",
    }),
    canStarProject: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.PROJECT.STAR_PROJECT,
        PRIVILEGES.ENTERPRISE.PROJECT.ADMIN
      );
    },
    liked() {
      return this.likedProjects.includes(this.projectData.id);
    },
  },
  methods: {
    moneyify,
    async onExportClick() {
      this.isExportInProgress = true;
      if (this.projectType === "BASIC") {
        try {
          let data = await this.$store.dispatch(
            "enterprise_overview/exportOverview",
            {
              projects: [this.projectDetails.identifier],
            }
          );

          FileSaver.saveAs(data, "project_export.xlsx");
        } finally {
          this.isExportInProgress = false;
        }
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: ProjectExportModal,
          props: { projectIdentifier: this.projectDetails.identifier },
          events: { close: () => (this.isExportInProgress = false) },
          hasModalCard: true,
          trapFocus: true,
          onCancel: () => (this.isExportInProgress = false),
        });
      }
    },
    async fetchData() {
      await this.$store.dispatch("enterprise_projects/fetchProjectData", {
        projectIdentifier: this.projectDetails.identifier,
      });
    },
    emit(event, args) {
      this.$emit(event, args);
    },
    openStatusChangeModal() {
      this.$buefy.modal.open({
        parent: this,
        component: StatusChanger,
        props: {
          projectData: this.projectData,
        },
        hasModalCard: true,
        trapFocus: true,
        canCancel: ["escape"],
      });
    },
    async star() {
      this.$refs["star-icon"].$el.classList.add("clicked");
      // Remove the 'clicked' class after the duration of the animation
      setTimeout(() => {
        this.$refs["star-icon"].$el.classList.remove("clicked");
      }, 500); // 500ms matches the duration of the CSS animation
      const original = this.starred;
      this.starred = !this.starred;
      await this.$store
        .dispatch("enterprise_projects/star", {
          projectType: this.projectType,
          projectIdentifier: this.projectDetails.identifier,
          starred: original,
        })
        .catch(() => {
          this.starred = original;
        });
      this.$forceUpdate();
    },
    async changeLike() {
      this.$refs["heart-icon"].$el.classList.add("clicked");
      // Remove the 'clicked' class after the duration of the animation
      setTimeout(() => {
        this.$refs["heart-icon"].$el.classList.remove("clicked");
      }, 500); // 500ms matches the duration of the CSS animation
      const original = this.liked;
      //this.liked = !this.liked;
      await this.$store
        .dispatch("project_like/changeLike", {
          projectId: this.projectData.id,
          liked: original,
        })
        .catch(() => {
          //this.liked = original;
        });
      await this.$store.dispatch("project_like/clearCache");
      await this.$store.dispatch("project_like/fetchLikedProjects");
      this.$forceUpdate();
    },
    getEmployeeDetailsByName(name) {
      const employee = this.employees.find((emp) => emp.name === name);
      if (employee) {
        return {
          userId: employee.id,
          name: employee.name,
          avatar: employee.avatar,
          email: employee.email,
          phone: employee.phone,
        };
      } else {
        return undefined;
      }
    },
    getClientDetailsByName(name) {
      const client = this.clients.find((client) => client.name === name);
      if (client) {
        return {
          identifier: client.identifier,
          name: client.name,
          avatar: client.avatar,
        };
      } else {
        return undefined;
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("project_like/fetchLikedProjects");
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.gap {
  gap: 8px;
}
.title {
  margin: 0 !important;
}
.status-image-div {
  width: 24px !important;
}
.archived-tag {
  background: $red;
  border-radius: 8px;
  color: $white;
  text-align: center;
}

.clicked {
  animation: pop 0.5s ease-in-out;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
