<template>
  <div class="timesheet-summary">
    <b-modal
      v-model="isModalOpen"
      :destroy-on-hide="false"
      aria-modal
      aria-role="dialog"
      has-modal-card
      render-on-mounted
    >
      <div class="card p-4">
        <h2 class="title mb-5">
          {{ $tf("timesheetSummary.modalTitle|Helyszín") }}
        </h2>
        <h4 class="subtitle" style="font-size: 12px">
          {{
            $tf(
              "timesheetSummary.modalSubtitle|Beállíthatod, honnan dolgozol az adott napon"
            )
          }}
        </h4>
        <div class="columns mb-0">
          <div class="column is-narrow">
            <div v-for="day in 5" :key="day" style="height: 48px">
              {{ $tf(DAY_NUMBER_LABEL[day]) }}
            </div>
          </div>
          <div class="column">
            <div v-for="day in 5" :key="day" style="height: 48px">
              <b-field>
                <b-select
                  v-model="siteEditData[WORK_SCHEDULE_INDEXES[day - 1].site]"
                  expanded
                >
                  <option
                    v-for="value in sites"
                    :key="`sid` + value.id"
                    :value="value.id"
                  >
                    {{ value.identifier }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: flex-end">
          <b-button
            :loading="isPageLoading"
            icon-left="save"
            type="is-primary"
            @click="editMyWorkScheduleSites"
          >
            {{ $tf("timesheetSummary.modal.save|Mentés") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <div class="columns">
      <loading-component class="column is-narrow">
        <div
          style="max-height: 320px; max-width: 320px"
          class="timesheet-chart"
        >
          <apexchart
            :options="getChartOptions"
            :series="getChartData.map((data) => data.hours)"
            type="donut"
          />
        </div>
      </loading-component>
      <div class="column is-one-eight" />
      <div class="column">
        <loading-component>
          <h4 class="subtitle" style="font-size: 20px">
            {{ $tf("timesheetSummary.statisticsTitle|Napi statisztika") }}
          </h4>
        </loading-component>
        <div class="columns">
          <div class="column is-narrow">
            <loading-component v-for="day in 5" :key="day" style="height: 32px">
              {{ $tf(DAY_NUMBER_SHORT_LABEL[day]) }}
            </loading-component>
          </div>
          <div class="column">
            <div>
              <loading-component
                v-for="day in 5"
                :key="day"
                class="progress-bar"
                is-full-width
              >
                <div style="height: 32px">
                  <b-progress
                    :value="getProgressForDay(day)"
                    size="is-large"
                    :type="
                      getProgressType(
                        totalPerExpected(
                          timeLoggedForDay(day),
                          loggingRequiredForDay(day)
                        )
                      )
                    "
                    show-value
                  >
                    <span>
                      {{
                        `${secondToShortWorkDayFormat(
                          timeLoggedForDay(day)
                        )} / ${secondToShortWorkDayFormat(
                          loggingRequiredForDay(day)
                        )}`
                      }}</span
                    >
                  </b-progress>
                </div>
              </loading-component>
            </div>
          </div>
          <div class="column is-narrow location-column">
            <loading-component>
              <div class="subtitle-container">
                <h6 class="subtitle">
                  {{ $tf("timesheetSummary.location|Helyszín") }}
                  <clickable-icon
                    v-if="editable"
                    icon="pencil"
                    @click="toWorkScheduleEdit"
                  ></clickable-icon>
                </h6>
              </div>
            </loading-component>
            <loading-component v-for="day in 5" :key="day" style="height: 32px">
              {{
                workSchedule?.[WORK_SCHEDULE_INDEXES[day - 1].site].identifier
              }}
            </loading-component>
          </div>
        </div>
      </div>
      <div class="column is-one-eight" />
      <div class="column is-one-fifth">
        <loading-component>
          <h4 class="subtitle" style="font-size: 20px">
            {{ $tf("timesheetSummary.summaryTitle|Heti összesítő") }}
          </h4>
        </loading-component>
        <div
          class="summary-items mt-5 is-flex is-flex-direction-column has-gap-1"
        >
          <loading-component>
            <b-icon icon="sigma" />
            <label class="ml-1 mr-1">{{
              $tf("timesheetSummary.balance|Egyenleg:")
            }}</label>
            <label
              :class="{ 'has-text-red': summedTimeActWeek < 0 }"
              class="has-font-weight-500"
              >{{ secondToShortWorkDayFormat(summedTimeActWeek) }}</label
            >
          </loading-component>

          <div>
            <loading-component>
              <b-icon icon="calendar-week" />
              <label class="ml-1 mr-1">{{
                $tf("timesheetSummary.daysWorked|Nap munkával töltve:")
              }}</label>
              <label class="has-font-weight-500">{{
                $tf(
                  "timesheetSummary.daysWorked.display|{worked} / {expected} nap",
                  { worked: workedDaysActWeek, expected: expectedDaysActWeek }
                )
              }}</label>
            </loading-component>

            <loading-component>
              <b-icon icon="laptop" />
              <label class="ml-1 mr-1">{{
                $tf("timesheetSummary.loggedTime|Logolt idő:")
              }}</label>
              <label class="has-font-weight-500">
                {{
                  $tf(
                    "timesheetSummary.loggedTime.display|{logged} / {expected}",
                    {
                      logged: secondToShortWorkDayFormat(totalTimespentActWeek),
                      expected: secondToShortWorkDayFormat(
                        expectedTimespentActWeek
                      ),
                    }
                  )
                }}</label
              >
            </loading-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  formatDate,
  getTotalSecondsCss,
  randomColors,
  roundToTwoDecimals,
  secondsToHoursAndMinutes,
  secondToShortWorkDayFormat,
} from "@/utils/util";
import {
  DAY_NUMBER_SHORT_LABEL,
  DAY_NUMBER_LABEL,
  SHORT_MONTH_NAMES,
  WORK_SCHEDULE_INDEXES,
} from "@/utils/const";
import LoadingComponent from "@/components/loading/LoadingComponent.vue";
import LoadingMixin from "@/mixins/LoadingMixin";
import apexchart from "vue3-apexcharts";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";

export default {
  name: "TimeSheetSummary",
  components: { ClickableIcon, LoadingComponent, apexchart },
  props: {
    userId: {
      type: String,
      required: false,
    },
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [LoadingMixin],
  async mounted() {
    await this.doStartLoading();
    await this.fetchWorkLogs();
    await this.fetchAbsenceRequests();
    await this.fetchSpecialDays();
    await this.fetchProfile();
    await this.$store.dispatch("association/getMine", {
      from: formatDate(this.from),
      to: formatDate(this.to),
    });
    await this.getWorkSchedules();
    this.calcAbsenceRequestsDates();
    this.calcHolidaysDates();
    this.hours = this.calcTimesheetHours();
    this.calcDayArray();
    await this.doFinishLoading();
  },
  computed: {
    getChartData() {
      let data = {};
      this.associations?.forEach((a) => {
        if (data[a.projectId]) {
          data[a.projectId].hours += a.hours;
        } else {
          data[a.projectId] = {
            hours: a.hours,
            identifier: a.projectData.identifier,
          };
        }
      });
      return Object.keys(data).map((key) => data[key]);
    },
    getChartOptions() {
      return {
        chart: {
          type: "donut",
        },
        stroke: {
          width: 2,
          show: false,
        },
        legend: {
          position: "right",
          labels: {
            colors: "var(--text)",
          },
          fontWeight: 700,
        },
        labels: this.getChartData.map((data) => data.identifier),
        dataLabels: {
          formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex] / 8 + " MWD";
          },
        },
        title: {
          text: this.$tf(
            "timesheetSummary.chart.title|Heti erőforrás foglalás"
          ),
          align: "left",
          style: {
            fontSize: "20px",
            fontFamily: "Roboto, serif",
            color: "var(--text)",
          },
        },
        noData: {
          text: this.$tf(
            "timesheetSummary.chart.empty|Nincs erre a hétre beállított allokációd"
          ),
        },
        tooltip: {
          y: {
            formatter: (val, opts) => {
              return `${roundToTwoDecimals(
                this.loggedHoursForProject(
                  this.getChartData[opts.seriesIndex].identifier
                ) / 8
              )} MWD`;
            },
            title: {
              formatter: () =>
                this.$tf("timesheetSummary.chart.worked|Ledolgozva:"),
            },
          },
        },
        colors: randomColors(10),
      };
    },
    ...mapGetters({
      workScheduleMode: "session/workScheduleMode",
      associations: "association/associations",
      loggingRequired: "worklog/myLoggingRequired",
      specialDaysGetter: "specialday/specialDaysAll",
      workSchedule: "work_schedule/ownWorkSchedules",
      sites: "work_schedule/workScheduleSites",
    }),
    years() {
      return new Set([
        new Date(this.from).getFullYear(),
        new Date(this.to).getFullYear(),
      ])
        .values()
        .toArray();
    },
    specialDays() {
      return this.specialDaysGetter(...this.years);
    },
    timelogActWeek() {
      return this.worklog(this.from);
    },
    expectedActWeek() {
      return this.required(this.from);
    },
    expectedTimespentActWeek() {
      return this.expectedActWeek ? this.expectedActWeek.total * 3600 : 0;
    },
    expectedDaysActWeek() {
      return this.expectedActWeek ? this.expectedActWeek.neededDays : 0;
    },
    summedTimeActWeek() {
      return this.totalTimespentActWeek - this.expectedTimespentActWeek;
    },
    totalTimespentActWeek() {
      return this.timelogActWeek ? this.timelogActWeek.totalSeconds : 0;
    },
    workedDaysActWeek() {
      return this.timelogActWeek ? this.timelogActWeek.days.length : 0;
    },
    ...mapState({
      absenceRequests(state, getters) {
        return getters[
          this.userId
            ? "absence_request/userAbsenceRequests"
            : "absence_request/mineAbsenceRequests"
        ];
      },
      worklog(state, getters) {
        return getters[
          this.userId ? "worklog/worklogOfWeek" : "worklog/myWorklogOfWeek"
        ];
      },
      required(state, getters) {
        return getters[
          this.userId ? "worklog/expectedOfWeek" : "worklog/myExpectedOfWeek"
        ];
      },
    }),
  },
  data() {
    return {
      WORK_SCHEDULE_INDEXES,
      DAY_NUMBER_SHORT_LABEL,
      DAY_NUMBER_LABEL,
      isModalOpen: false,
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      day: new Date().getDate(),
      dayArray: [],
      holidaysDates: [],
      absenceRequestsDates: [],
      hours: [],
      userData: null,
      siteEditData: {},
      secondsToHoursAndMinutes,
    };
  },
  methods: {
    secondToShortWorkDayFormat,
    formatDate,

    async fetchSpecialDays() {
      this.years.forEach((year) =>
        this.$store.dispatch("specialday/fetchSpecialDaysAll", { year })
      );
    },
    async fetchProfile() {
      await this.$store.dispatch("census_user/fetchProfile");
      this.userData = this.$store.getters["census_user/profile"];
    },
    async fetchAbsenceRequests() {
      const searchParams = new URLSearchParams();
      searchParams.append("unpaged", "true");
      const requestParams = {
        params: searchParams,
      };
      if (this.userId) {
        await this.$store.dispatch("absence_request/getUserAbsenceRequests", {
          user: this.userId,
          params: requestParams,
        });
      } else {
        await this.$store.dispatch("absence_request/getMine", {
          params: requestParams,
        });
      }
    },
    loggedHoursForProject(identifier) {
      return (
        this.timelogActWeek.days
          .map((day) => day.worklogs)
          .flat()
          .filter((worklog) => worklog.projectIdentifier === identifier)
          .reduce((partialSum, worklog) => partialSum + worklog.timespent, 0) /
        3600
      );
    },
    async fetchWorkLogs() {
      let actWeek = {
        user: this.userId,
        year: this.year,
        from: this.from,
        to: this.to,
      };

      await this.$store.dispatch(
        this.userId ? "worklog/fetchWeek" : "worklog/fetchMyWeek",
        actWeek
      );
      await this.$store.dispatch(
        this.userId
          ? "worklog/fetchExpectedWeek"
          : "worklog/fetchMyExpectedWeek",
        actWeek
      );
      await this.$store.dispatch("worklog/fetchMyLoggingRequired", actWeek);
    },
    async getWorkSchedules() {
      this.$store.commit("work_schedule/setOwnWorkSchedules", null);
      await this.$store.dispatch("work_schedule/getWorkSchedulesForMe", true);
      WORK_SCHEDULE_INDEXES.map((indexName) => indexName.site).forEach(
        (siteProp) => {
          this.siteEditData[siteProp] = this.workSchedule[siteProp]?.id;
        }
      );
    },
    calcTimesheetHours: function () {
      if (this.timelogActWeek) {
        let dayArray = this.timelogActWeek.days;
        let hours = {};
        for (let d in dayArray) {
          let day = dayArray[d];
          hours[day.day] = {};

          let hour = hours[day.day];
          hour.dailyTotalSeconds = day.dailyTotalSeconds;
          hour.dailyTotalOvertimeSeconds = day.dailyTotalOvertimeSeconds;
          hour.tracked = day.dailyTimespent;
          hour.untracked = day.dailyUntrackedTimespent;
          hour.conflicted = day.dailyConflictedTimespent;
          hour.hasCashOvertime = day.dailyCashOvertime > 0;
          hour.hasShiftOvertime = day.dailyShiftOvertime > 0;
        }
        return hours;
      }
      return {};
    },
    calcDayArray: function () {
      let dayArray = [];
      let rollingDate = new Date(this.year, this.month, this.day, 0);
      let sumOfWorkedHoursInWeek = 0;
      let sumOfExpectedWorkHoursInWeek = 0;
      let isWeekWorkCompleted = false;

      let block = 1;
      while (rollingDate.getDay() !== block) {
        rollingDate = rollingDate.substractDays(1);
      }

      while (
        rollingDate.getTime() <= new Date().getTime() ||
        (rollingDate.getMonth() === 11 && this.month === 0) || // this happens in january if the week started in the prev year
        (rollingDate.getMonth() === 0 && this.month === 11)
      ) {
        if (
          rollingDate.getFullYear() > this.year &&
          rollingDate.getDay() === 1
        ) {
          break;
        }

        let dateStr = formatDate(rollingDate);
        let css = "is-disabled";

        let hour = this.hours[dateStr];
        if (rollingDate.getDay() === 0) {
          isWeekWorkCompleted =
            sumOfWorkedHoursInWeek >= sumOfExpectedWorkHoursInWeek;

          css = isWeekWorkCompleted ? "is-week-complete" : "is-week-incomplete";

          sumOfWorkedHoursInWeek = 0;
          sumOfExpectedWorkHoursInWeek = 0;
        } else {
          if (hour) {
            sumOfWorkedHoursInWeek =
              sumOfWorkedHoursInWeek + hour.dailyTotalSeconds / 3600;
          }
          if (this.expectedHoursOfDay(dateStr)) {
            sumOfExpectedWorkHoursInWeek =
              sumOfExpectedWorkHoursInWeek + this.expectedHoursOfDay(dateStr);
          }
        }

        if (!hour) {
          if (
            this.expectedHoursOfDay(dateStr) > 0 &&
            dateStr < formatDate(new Date())
          ) {
            css = "is-danger";
          }
        } else {
          css = getTotalSecondsCss(hour.dailyTotalSeconds);
        }
        dayArray.push({
          date: dateStr,
          isCurrentMonth: this.month === rollingDate.getMonth(),
          monthDay: `${this.$tf(
            SHORT_MONTH_NAMES[rollingDate.getMonth()]
          )}. ${rollingDate.getDate()}`,
          isSunday: rollingDate.getDay() === 0,
          isHoliday: this.isHoliday(dateStr),
          isAbsence:
            this.isAbsenceRequest(dateStr) ||
            this.expectedHoursOfDay(dateStr) === 0,
          isToday: formatDate(new Date()) === dateStr,
          isFuture: dateStr > formatDate(new Date()),
          isWeekWorkCompleted: isWeekWorkCompleted,
          logged: hour ? hour.dailyTotalSeconds : 0,
          unrecognized: hour ? hour.untracked : null,
          overtime: hour ? hour.dailyTotalOvertimeSeconds : 0,
          hasUntracked: hour ? hour.untracked > 0 : false,
          hasConflicted: hour ? hour.conflicted > 0 : false,
          hasCashOvertime: hour ? hour.hasCashOvertime : false,
          hasShiftOvertime: hour ? hour.hasShiftOvertime : false,
          css: css,
        });

        rollingDate.setDate(rollingDate.getDate() + 1);
      }

      this.dayArray = dayArray;
    },
    calcHolidaysDates: function () {
      if (this.specialDays) {
        this.holidaysDates = this.specialDays
          .filter((sd) => sd.type === "HOLIDAY")
          .map((sd) => sd.dayDate);
      }
    },
    isHoliday(day) {
      return this.holidaysDates.includes(day);
    },
    calcAbsenceRequestsDates: function () {
      if (this.absenceRequests.items) {
        this.absenceRequestsDates = [];
        this.absenceRequests.items
          .filter((ar) =>
            [
              "ACTIVE",
              "APPROVED",
              "LINE_APPROVED",
              "REQUEST",
              "TAKEN",
            ].includes(ar.status.enum)
          )
          .forEach(
            (ar) =>
              (this.absenceRequestsDates = this.absenceRequestsDates.concat(
                ar.absenceRequestDates
              ))
          );
      }
    },
    isAbsenceRequest(day) {
      return this.absenceRequestsDates.includes(day);
    },
    expectedHoursOfDay(day) {
      return this.expectedActWeek?.days
        .filter((e) => e.day === day)
        .map((e) => e.hours)[0];
    },
    getDateFromDayNumber(dayOfWeek) {
      return formatDate(new Date(this.from).addDays(dayOfWeek - 1));
    },
    loggingRequiredForDay(dayOfWeek) {
      return (this.loggingRequired[dayOfWeek - 1]?.hours ?? 0) * 3600;
    },
    timeLoggedForDay(dayOfWeek) {
      return (
        this.timelogActWeek?.days?.find(
          (day) => day.day === this.getDateFromDayNumber(dayOfWeek)
        )?.dailyTotalSeconds ?? 0
      );
    },

    getProgressType(totalPerExpected) {
      if (totalPerExpected < 1) {
        return "is-primary";
      } else {
        return "is-success";
      }
    },
    totalPerExpected(totalTimeSpent, expectedTimeSpent) {
      return expectedTimeSpent === 0 ? 1 : totalTimeSpent / expectedTimeSpent;
    },
    toWorkScheduleEdit() {
      this.isModalOpen = true;
    },
    async editMyWorkScheduleSites() {
      this.doStartLoading();
      await this.$store.dispatch(
        "work_schedule/updateWorkScheduleSitesByUser",
        this.siteEditData
      );
      await this.getWorkSchedules();
      this.isModalOpen = false;
      this.doFinishLoading();
    },
    getProgressForDay(day) {
      const value =
        (this.timeLoggedForDay(day) / this.loggingRequiredForDay(day)) * 100;
      return !!value ? value : 0;
    },
  },
};
</script>

<style lang="scss">
.timesheet-summary {
  .days > div {
    flex: 1 1 0;
    width: 0;
    max-width: 14%;
  }

  .timesheet-chart {
    .apexcharts-legend.apx-legend-position-right {
      justify-content: center !important;
    }
  }

  .location-column {
    position: relative;

    .subtitle-container {
      position: absolute;
      top: -16px;
    }
  }

  .is-one-eight {
    flex: none;
    width: 12.5%;
  }
}
</style>
