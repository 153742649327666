<template>
  <td
    :class="{
      'absence-table-divider': addDivider,
    }"
    style="padding: 0.2rem 0.3rem"
  >
    <div class="day-title" :style="`background-color: ${todayBackgroundColor}`">
      <b-tooltip
        class="customTooltip headerToolTip m-0"
        :label="getSpecialDayTooltipText"
        :active="!!getSpecialDayTooltipText"
      >
        <p
          class="title mb-1"
          :class="[{ 'active-day': isActiveToday }, todayTextColor]"
        >
          {{ dayName }}
        </p>
        <div
          class="date is-flex is-align-items-center is-justify-content-center"
          :class="[{ 'active-day': isActiveToday }, todayTextColor]"
        >
          <p>
            {{ dateString }}
          </p>
        </div>
      </b-tooltip>
    </div>
  </td>
</template>

<script>
import { formatDate, addDay } from "@/utils/util";

export default {
  name: "DashboardDay",
  props: {
    dayOfTheWeek: {
      type: Number,
      required: true,
    },
    dayName: {
      type: String,
      required: true,
    },
    fromDate: {
      type: Date,
      required: true,
    },
    toDate: {
      type: Date,
      required: true,
    },
    specialDays: {
      type: Array,
      required: true,
    },
    addDivider: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actWeekDates: [],
      today: formatDate(new Date()),
      dateString: null,
    };
  },
  created() {
    this.updateDays();
  },
  computed: {
    currentWeekDates() {
      let currentWeekDates = [];
      for (let i = 0; i < 14; i++) {
        currentWeekDates.push(addDay(this.fromDate, i));
      }
      return currentWeekDates;
    },
    isActiveToday() {
      let isCurrentWeek =
        this.fromDate <= new Date() &&
        new Date().setHours(0, 0, 0, 0) <= this.toDate;
      return (
        isCurrentWeek &&
        this.actWeekDates[this.dayOfTheWeek] === this.today.toString()
      );
    },
    todayBackgroundColor() {
      //     if (this.isActiveToday) {
      //return "#59C7C7";
      //     } else {
      let specialDay;
      if (this.specialDays) {
        this.toDate.setHours(23, 59, 59, 999);
        specialDay = this.specialDays.filter(this.filterDay).shift();
      }
      if (specialDay) {
        switch (specialDay.type) {
          case "WEEKEND":
          //           return "#e37b96";
          case "HOLIDAY":
          //          return "#c7cf59";
          case "EXTRA_WORKDAY":
          //          return "#3275a8";
        }
      }
      return "transparent";
      //     }
    },
    todayTextColor() {
      if (this.isActiveToday) {
        return "has-text-white";
      } else {
        let specialDay;
        if (this.specialDays) {
          this.toDate.setHours(23, 59, 59, 999);
          specialDay = this.specialDays.filter(this.filterDay).shift();
        }
        if (specialDay) {
          switch (specialDay.type) {
            case "WEEKEND":
              return "has-text-red";
            case "HOLIDAY":
              return "has-text-yellow";
            case "EXTRA_WORKDAY":
              return "has-text-blue";
          }
        }
        return "has-text-grey-dark";
      }
    },
    getSpecialDayTooltipText() {
      return (this.specialDays?.filter(this.filterDay) ?? [{}])[0]?.name;
    },
  },
  methods: {
    filterDay(day) {
      return (
        day?.dayDate ===
        formatDate(new Date(this.fromDate).addDays(this.dayOfTheWeek))
      );
    },
    updateDays() {
      this.actWeekDates = [];
      for (let i = 0; i < 14; i++) {
        this.actWeekDates.push(addDay(this.fromDate, i));
      }

      let date = this.currentWeekDates[this.dayOfTheWeek];
      this.dateString = new Date(date).getDate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
.day-title {
  max-width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  .title {
    font-size: 1vw;
  }
  .date {
    font-size: 1.5vw;
  }
}
p.active-day {
  color: $custom-light-blue !important;
}
div.active-day {
  color: $white !important;
  background-color: $custom-light-blue;
  border-radius: 9999px;
  width: 2.5vw;
  height: 2.5vw;
}
</style>
