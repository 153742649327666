<template>
  <div class="is-flex card-shadowed m-2">
    <div>
      <b-tooltip v-if="tooltip" :label="tooltip" class="ml-0" multilined>
        <b-icon size="is-large" class="icon-xl mr-1" :icon="icon" />
      </b-tooltip>
      <b-icon v-else size="is-large" class="icon-xl mr-1" :icon="icon" />
    </div>
    <div class="dashboard-card-text project project-data">
      <div>
        <p class="project-text">{{ name }}</p>
      </div>
      <div>
        <p class="project-text lower-text">
          {{ value }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatisticsCard",
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors";
img {
  height: 32px;
  width: 32px;
  min-width: 32px;
  margin-right: 10px;
  margin-top: 10px;
}
.dashboard-card-text {
  padding-top: 5px;
}
.project-data {
  margin-right: 10px;
}
.project {
  .project-text {
    font-size: 16px;
    white-space: nowrap;
  }
}
.lower-text {
  font-weight: bold;
}
.is-danger {
  color: $red;
}
.is-warning {
  color: $yellow;
}
.is-success {
  color: $green;
}

.subtitle {
  font-size: 18px;
  color: $custom-grayer-blue;
}
p {
  color: $custom-dark-blue;
}
</style>
