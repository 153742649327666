<template>
  <div>
    <ul>
      <li>
        {{
          $tf("riskManagement.missingSheets.totalTime|Teljes hiányzó logolás:")
        }}
        &nbsp;
        <b>{{
          loading
            ? $tf("riskManagement.missingSheets.loading|Töltés...")
            : secondToShortWorkDayFormat(records.sumSeconds) +
              " ebből lejárt: " +
              secondToShortWorkDayFormat(records.sumPastMonthsSeconds)
        }}</b>
      </li>
      <li>
        {{
          $tf(
            "riskManagement.missingSheets.totalCost|Teljes hiányzó, tényleges, nem lelogolt költség:"
          )
        }}
        <b>{{
          loading
            ? $tf("riskManagement.missingSheets.loading|Töltés...")
            : moneyify(records.sumCost) +
              " ebből lejárt: " +
              moneyify(records.sumPastMonthsCost)
        }}</b>
      </li>
    </ul>
    <b-table
      :data="records?.records || []"
      paginated
      :per-page="50"
      hoverable
      :loading="loading"
    >
      <b-table-column
        field="name"
        :label="$tf('riskManagement.missingSheets.table.user|Munkavállaló')"
        searchable
        v-slot="props"
      >
        <UserInfo
          :user="{
            userId: props.row.userId,
            name: props.row.name,
            avatar: props.row.avatar,
            email: props.row.email,
            phone: props.row.phone,
          }"
          displayMode="long"
          tooltipPosition="bottom"
        />
      </b-table-column>
      <b-table-column
        field="currentMonthSeconds"
        :label="$tf('riskManagement.missingSheets.table.thisMonth|Jelen hónap')"
        sortable
        v-slot="props"
      >
        <div>
          {{ secondToShortWorkDayFormat(props.row.currentMonthSeconds) }}
        </div>
        <div>{{ moneyify(props.row.currentMonthCost) }}</div>
      </b-table-column>
      <b-table-column
        field="pastMonthsSeconds"
        :label="
          $tf('riskManagement.missingSheets.table.pastMonths|Lezárt 3 hónap')
        "
        v-slot="props"
      >
        <div class="has-text-weight-bold">
          {{ secondToShortWorkDayFormat(props.row.pastMonthsSeconds) }}
        </div>
        <div class="has-text-weight-bold">
          {{ moneyify(props.row.pastMonthsCost) }}
        </div>
      </b-table-column>
      <b-table-column
        field="lastMonthSeconds"
        :label="$tf('riskManagement.missingSheets.table.onePrior|Múlt hónap')"
        sortable
        v-slot="props"
      >
        <div>{{ secondToShortWorkDayFormat(props.row.lastMonthSeconds) }}</div>
        <div>{{ moneyify(props.row.lastMonthCost) }}</div>
      </b-table-column>
      <b-table-column
        field="twoPriorMonthSeconds"
        :label="$tf('riskManagement.missingSheets.table.twoPrior|-2. hónap')"
        sortable
        v-slot="props"
      >
        <div>
          {{ secondToShortWorkDayFormat(props.row.twoPriorMonthSeconds) }}
        </div>
        <div>{{ moneyify(props.row.twoPriorMonthCost) }}</div>
      </b-table-column>
      <b-table-column
        field="threePriorMonthSeconds"
        :label="$tf('riskManagement.missingSheets.table.threePrior|-3. hónap')"
        sortable
        v-slot="props"
      >
        <div>
          {{ secondToShortWorkDayFormat(props.row.threePriorMonthSeconds) }}
        </div>
        <div>{{ moneyify(props.row.threePriorMonthCost) }}</div>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { secondToShortWorkDayFormat, formatDate, moneyify } from "@/utils/util";
import { mapGetters } from "vuex";
import UserInfo from "@/components/module/info/UserInfo.vue";
export default {
  name: "RiskManagementMissingSheetsTable",
  components: { UserInfo },
  computed: {
    ...mapGetters({
      records: "risk_management/getMissingTimesheets",
    }),
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.$store.dispatch("risk_management/clearCache");
      await this.$store.dispatch("risk_management/fetchMissingTimesheets");
      this.loading = false;
    },
  },
  watch: {
    onlyMyProjects() {
      this.fetchData();
    },
    showUnknown() {
      this.fetchData();
    },
  },
  data() {
    return {
      moneyify: moneyify,
      secondToShortWorkDayFormat: secondToShortWorkDayFormat,
      formatDate: formatDate,
      onlyMyProjects: true,
      showUnknown: false,
      loading: true,
    };
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style></style>
