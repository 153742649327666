<template>
  <b-datepicker
    :date-formatter="dateFormatter"
    :day-names="dayNames"
    :first-day-of-week="firstDayOfWeek"
    :month-names="monthNames"
  >
    <template v-for="(_, name) in $slots" #[name]="scope">
      <slot :name v-bind="scope ?? {}" />
    </template>
  </b-datepicker>
</template>

<script>
export default {
  name: "FDatepicker",
  props: {
    monthView: {
      type: Boolean,
      default: false,
    },
    dayView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    monthNames() {
      return [
        this.$tf("month.january|Január"),
        this.$tf("month.february|Február"),
        this.$tf("month.march|Március"),
        this.$tf("month.april|Április"),
        this.$tf("month.may|Május"),
        this.$tf("month.june|Június"),
        this.$tf("month.july|Július"),
        this.$tf("month.august|Augusztus"),
        this.$tf("month.september|Szeptember"),
        this.$tf("month.october|Október"),
        this.$tf("month.november|November"),
        this.$tf("month.december|December"),
      ];
    },

    dayNames() {
      return [
        this.$tf("day.short.sunday|Vas"),
        this.$tf("day.short.monday|Hé"),
        this.$tf("day.short.tuesday|Ke"),
        this.$tf("day.short.wednesday|Sze"),
        this.$tf("day.short.thursday|Csü"),
        this.$tf("day.short.friday|Pé"),
        this.$tf("day.short.saturday|Szo"),
      ];
    },

    firstDayOfWeek() {
      return 1;
    },

    dateFormatter() {
      if (this.$attrs["customFormatter"] || this.$attrs["custom-formatter"]) {
        return undefined;
      }
      if (this.monthView) {
        return this.returnMonth;
      }
      if (this.dayView) {
        return this.returnDay;
      }
      return undefined;
    },
  },
  methods: {
    returnMonth(date) {
      const monthIndex = date.getMonth();
      return this.monthNames[monthIndex];
    },
    returnDay(date) {
      return new Intl.DateTimeFormat("default", { day: "numeric" }).format(
        date
      );
    },
  },
};
</script>
