import { newLogger, subtractDay, addDay, formatDate } from "@/utils/util";
import StoreCore from "@/store/StoreCore";

let storeName = "DashboardStore";
let logger = newLogger(storeName);

let compareDates = function (a, b) {
  if (a.dates.length > b.dates.length) {
    return -1;
  }
  if (a.dates.length < b.dates.length) {
    return 1;
  }
  if (a.dates.length === b.dates.length) {
    if (a.userId < b.userId) {
      return -1;
    }
    if (a.userId > b.userId) {
      return 1;
    }
  }
  return 0;
};

let getDateOfISOWeek = function (w, y) {
  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getDay();
  const ISOWeekStart = simple;
  if (dow <= 4) ISOWeekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOWeekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOWeekStart;
};

let getISOWeek = function (dt) {
  const tdt = new Date(dt.valueOf());
  const dayNum = (dt.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayNum + 3);
  const firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
  }
  return 1 + Math.ceil((firstThursday - tdt) / 604800000);
};

const urls = {
  actives: "/absence/absence-request/dashboard",
  workSchedules: "/census/work-schedule/dashboard",
};

const initState = () => {
  return {
    fromDate: null,
    toDate: null,
    absences: [],
    workSchedules: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch, state }, { params, force } = {}) {
    try {
      let absences = await this.remoteRequest(
        "get",
        urls.actives,
        params,
        true,
        dispatch,
        "Aktív szabadságok betöltése...",
        null,
        force
      );
      commit("save", absences);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      } else {
        logger.info(storeName + ".fetch() was cached!");
      }
    }
  }.bind(storeCore),
  fetchWorkSchedules: async function (
    { commit, dispatch, state },
    { params, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.workSchedules,
        params,
        true,
        dispatch,
        "Munkarendek betöltése...",
        null,
        force
      );
      commit("saveWorkSchedules", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  save: (state, absences) => {
    if (state.absences) {
      state.absences.length = 0;
    }
    for (let abs in absences) {
      if (!absences.hasOwnProperty(abs) || !absences[abs]) continue;
      state.absences.push(absences[abs]);
    }
    state.absences.sort(compareDates);
  },
  saveWorkSchedules: (state, workschedules) => {
    if (state.workSchedules) {
      state.workSchedules.length = 0;
    }
    for (let ws in workschedules) {
      if (!workschedules.hasOwnProperty(ws) || !workschedules[ws]) continue;
      state.workSchedules.push(workschedules[ws]);
    }
  },
};

const getters = {
  absences: (state) => {
    return state.absences;
  },
  workschedules: (state) => {
    return state.workSchedules;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
