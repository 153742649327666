<template>
  <div
    @click="onClick"
    class="button-container"
    :class="{ 'cursor-pointer': clickable }"
  >
    <b-icon icon="arrow-up-right-from-square" />
    {{ $tf("exportButton.export|Export") }}
  </div>
</template>

<script>
export default {
  name: "ExportButton",
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
  },
  methods: {
    onClick() {
      if (this.clickable) this.$emit("openExport");
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/colors.scss";

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 130%;

  color: $custom-dark-blue;
}
.starred {
  color: $yellow;
}
</style>
