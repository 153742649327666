<template>
  <td class="week">
    <div>
      <p class="week-number">
        {{ week }} {{ $tf("rallocPage.nthWeek|. hét") }}
        <span v-if="thisWeek">{{ $tf("rallocPage.thisWeek| (e hét)") }} </span>
        <b-icon
          icon="pen"
          size="is-small"
          class="week-pen-icon"
          @click="$emit('open-conflict-overlay', week, dateRange, year)"
        />
      </p>
      <p class="week-range">{{ dateRange }}</p>
    </div>
  </td>
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment/moment";
import { localFetch } from "@/utils/util";

export default defineComponent({
  name: "WeekHeader",
  props: {
    week: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      storedLocale: localFetch("stored_locale") ?? "hu",
    };
  },
  computed: {
    dateRange() {
      const formattedWeek = this.week.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      const startOfWeek = moment(`${this.year}W${formattedWeek}`)
        .startOf("isoWeek")
        .locale(this.storedLocale);
      const endOfWeek = moment(`${this.year}W${formattedWeek}`)
        .endOf("isoWeek")
        .locale(this.storedLocale);
      return `${startOfWeek.format("MMM D")}. - ${endOfWeek.format("MMM D")}.`;
    },
    thisWeek() {
      const now = new Date();
      return this.year === now.getUTCFullYear() && this.week === now.getWeek();
    },
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors";

.week-number {
  font-weight: 700;
  font-size: 10px;
  margin-bottom: 0 !important;
  color: $custom-dark-blue;

  span {
    font-weight: 400 !important;
  }
}

p {
  margin: 0;
}

.week-range {
  font-size: 10px;
  color: $custom-dark-blue;
}

.week {
  text-align: left !important;

  &-pen-icon {
    color: $custom-dark-blue;
    display: inline-block;
    float: right;
    cursor: pointer;
  }
}
</style>
