<template>
  <div>
    <loading-skeleton v-if="loading" />
    <div v-else>
      <b-modal
        v-model="isModalOpen"
        aria-modal
        aria-role="dialog"
        has-modal-card
        style="height: 100%"
        v-on:cancel="editedActivity = null"
      >
        <div class="card p-4">
          <billable-form
            :disable-fee="disableFee"
            :edited-activity="editedActivity"
            :prefix="prefix"
            :project-identifier="projectDetails.identifier"
            @activity-submitted="handleSubmit"
          />
        </div>
      </b-modal>
      <div v-if="selectedActivity">
        <div class="is-inline-flex is-align-items-center">
          <h4 class="mr-4 my-auto">
            <a @click="selectedActivity = null">
              <b-icon icon="chevron-left" />
              {{ selectedActivity.name }}
            </a>
          </h4>
          <b-button
            class="mr-2"
            icon-left="pencil"
            type="is-info"
            @click="handleEdit"
            >{{
              $tf("projectPage.billables.details.edit|Aktivitás szerkesztése")
            }}
          </b-button>
          <b-button
            class="mr-2"
            icon-left="trash"
            type="is-danger"
            @click="handleDelete()"
          ></b-button>
        </div>
        <div class="mt-4">
          <h6>
            {{
              $tf(
                "projectPage.billables.details.activityStatistics|Aktivitás statisztikák"
              )
            }}
          </h6>
        </div>
        <div class="is-inline-flex">
          <div class="mr-4">
            <project-form-info-box
              :header="
                $tf('projectPage.billables.details.infoBox.loggedDays|Munkaidő')
              "
              :text="`${workdayify(
                selectedActivity.financials.loggedSeconds / 28800
              )}/${workdayify(selectedActivity.financials.allocatedDays)}`"
              :type="
                selectedActivity.financials.loggedSeconds / 28800 >
                selectedActivity.financials.allocatedDays
                  ? 'is-danger'
                  : 'is-info'
              "
              has-borders
            >
            </project-form-info-box>
          </div>
          <div class="mr-4">
            <project-form-info-box
              :header="
                $tf('projectPage.billables.details.infoBox.income|Bevétel')
              "
              :text="`${
                disableFee ? '-' : moneyify(selectedActivity.financials.income)
              }`"
              has-borders
              type="is-info"
            >
            </project-form-info-box>
          </div>
          <div class="mr-4">
            <project-form-info-box
              :header="
                $tf('projectPage.billables.details.infoBox.budget|Büdzsé')
              "
              :text="`${moneyify(selectedActivity.financials.budget)}`"
              has-borders
              type="is-info"
            >
            </project-form-info-box>
          </div>
          <div class="mr-4">
            <project-form-info-box
              :header="
                $tf('projectPage.billables.details.infoBox.totalSpent|Költség')
              "
              :text="`${moneyify(selectedActivity.financials.totalSpent)}`"
              :type="
                selectedActivity.financials.totalSpent >
                selectedActivity.financials.budget
                  ? 'is-danger'
                  : 'is-info'
              "
              has-borders
            >
            </project-form-info-box>
          </div>
          <div class="mr-4">
            <project-form-info-box
              :header="
                $tf('projectPage.billables.details.infoBox.profit|Profit')
              "
              :text="`${
                disableFee
                  ? '-'
                  : moneyify(selectedActivity.financials.totalSpent)
              }`"
              :type="
                selectedActivity.financials.profit > 0 ? 'is-info' : 'is-danger'
              "
              has-borders
            >
            </project-form-info-box>
          </div>
          <div>
            <project-form-info-box
              :header="
                $tf(
                  'projectPage.billables.details.infoBox.invoiceDate|Elszámolás dátuma'
                )
              "
              :text="`${selectedActivity.invoiceDate}`"
              has-borders
              type="is-info"
            >
            </project-form-info-box>
          </div>
        </div>
        <div class="mt-4">
          <support-billable-details
            :activity="selectedActivity"
            :employees="employees"
            :project-id="projectDetails.id"
            :project-identifier="projectDetails.identifier"
          ></support-billable-details>
        </div>
      </div>
      <div v-else>
        <div>
          <div>
            <h4 class="title">
              {{ $tf("projectPage.billables.list.title|Aktivitások listája") }}
            </h4>
          </div>
          <div>
            <b-table
              ref="expenseTable"
              :data="projectDetails.billables ?? []"
              :per-page="10"
              :show-detail-icon="false"
              aria-current-label="Aktuális lap"
              aria-next-label="Következő lap"
              aria-page-label="Lap"
              aria-previous-label="Előző lap"
              detail-key="identifier"
              detailed
            >
              <template #empty>
                <div class="has-text-centered">
                  {{
                    $tf(
                      "projectpage.billables.table.empty|Nincsenek aktivitások"
                    )
                  }}
                </div>
              </template>
              <b-table-column
                :label="
                  $tf('projectpage.billables.table.column.name|Aktivitás neve')
                "
                centered
                field="name"
                sortable
              >
                <template v-slot="props">
                  <a @click="selectedActivity = props.row">{{
                    props.row.name
                  }}</a>
                </template>
              </b-table-column>
              <b-table-column
                :label="
                  $tf(
                    'projectpage.billables.table.column.billableType|Aktivitás típusa'
                  )
                "
                centered
                field="billableType"
                sortable
              >
                <template v-slot="props">
                  {{ $tf(BILLABLE_TYPE[props.row.billableType]) }}
                </template>
              </b-table-column>
              <b-table-column
                :label="
                  $tf(
                    'projectpage.billables.table.column.allocatedDays|Össz. allokált munkaidő'
                  )
                "
                centered
                field="hours"
                sortable
              >
                <template v-slot="props">
                  {{ workdayify(props.row.financials.allocatedDays) }}
                </template>
              </b-table-column>
              <b-table-column
                :label="
                  $tf(
                    'projectpage.billables.table.column.loggedSeconds|Logolt munkaidő'
                  )
                "
                centered
                field="loggedTime"
                sortable
              >
                <template v-slot="props">
                  {{ workdayify(props.row.financials.loggedSeconds / 28800) }}
                </template>
              </b-table-column>
              <b-table-column
                :label="
                  $tf(
                    'projectpage.billables.table.column.invoiceDate|Elszámolás dátuma'
                  )
                "
                centered
                field="invoiceDate"
                sortable
              >
                <template v-slot="props">
                  {{ props.row.invoiceDate }}
                </template>
              </b-table-column>
              <b-table-column
                :label="
                  $tf('projectpage.billables.table.column.income|Bevétel')
                "
                centered
                field="income"
                sortable
              >
                <template v-slot="props">
                  <template v-if="disableFee"> -</template>
                  <template v-else>
                    {{ moneyify(props.row.financials?.income) }}
                  </template>
                </template>
              </b-table-column>
              <b-table-column
                :label="
                  $tf('projectpage.billables.table.column.totalSpent|Költség')
                "
                centered
                field="totalSpent"
                sortable
              >
                <template v-slot="props">
                  {{ moneyify(props.row.financials?.totalSpent) }}
                </template>
              </b-table-column>
              <b-table-column
                :label="$tf('projectpage.billables.table.column.budget|Büdzsé')"
                centered
                field="budget"
                sortable
              >
                <template v-slot="props">
                  {{ moneyify(props.row.financials?.budget) }}
                </template>
              </b-table-column>
              <b-table-column
                :label="$tf('projectpage.billables.table.column.profit|Profit')"
                centered
                field="profit"
                sortable
              >
                <template v-slot="props">
                  <template v-if="disableFee"> -</template>
                  <template v-else>
                    {{ moneyify(props.row.financials?.profit) }}
                  </template>
                </template>
              </b-table-column>
            </b-table>
          </div>
          <div class="ml-auto">
            <b-button
              icon-left="circle-plus"
              type="is-info"
              @click="isModalOpen = true"
            >
              {{ $tf("projectpage.activities.add|Új aktivitás hozzáadása") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";
import { moneyify, openDeleteConfirm, workdayify } from "@/utils/util";
import { BILLABLE_TYPE, ISSUE_TRACKING_SYSTEM } from "@/utils/const";
import { mapGetters } from "vuex";
import BillableForm from "@/components/enterprise/project/form/BillableForm.vue";
import ProjectFormInfoBox from "@/components/enterprise/project/module/ProjectFormInfoBox.vue";
import SupportBillableDetails from "@/components/enterprise/project/activity/SupportBillableDetails.vue";
import ExternalIssueTargetingLink from "@/components/enterprise/targeting/ExternalIssueTargetingLink.vue";

export default {
  name: "BillableTabItem",
  components: {
    SupportBillableDetails,
    ProjectFormInfoBox,
    BillableForm,
    LoadingSkeleton,
  },
  data() {
    return {
      selectedActivity: null,
      editedActivity: null,
      isModalOpen: false,
      loading: false,
      refreshKey: 0,
    };
  },
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  computed: {
    disableFee() {
      return !!this.projectDetails?.internal;
    },
    BILLABLE_TYPE() {
      return BILLABLE_TYPE;
    },
    ISSUE_TRACKING_SYSTEM() {
      return ISSUE_TRACKING_SYSTEM;
    },
    ...mapGetters({
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
  },
  methods: {
    workdayify,
    moneyify,
    async handleSubmit() {
      this.isModalOpen = false;
      await this.refreshData();
    },
    handleEdit() {
      this.editedActivity = this.selectedActivity;
      this.isModalOpen = true;
    },
    async handleDelete() {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("support_projects/deleteActivity", {
          projectId: this.projectDetails.id,
          activityType: "BILLABLE",
          id: this.selectedActivity.id,
        });
        await this.refreshData();
      });
    },
    async refreshData() {
      this.loading = true;
      let params = new URLSearchParams();
      params.append("year", new Date().getFullYear());
      params.append("includeFixes", "true");
      params.append("includeLockups", "true");
      params.append("includeBillables", "true");
      await this.$store.dispatch("support_projects/fetchProjectDetails", {
        projectIdentifier: this.projectDetails.identifier,
        requestParams: { params },
        force: true,
      });
      const field = this.projectDetails[this.activityField];
      if (!this.selectedActivity) {
        this.selectedActivity = field ? field[0] : undefined;
      } else {
        this.selectedActivity = field?.find(
          (a) => a.id === this.selectedActivity.id
        );
      }
      this.refreshKey++;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
