<template>
  <section class="modal-card-body bordered-modal" v-if="loaded">
    <div class="is-flex is-flex-direction-column">
      <div class="pb-1">
        <p class="has-text-weight-bold is-size-5 has-text-centered mb-0">
          {{ allocationEdit.weekData.week }}.
          {{ $tf("resource.allocationBox.subtitle.week|hét") }}
        </p>
        <p class="has-text-grey has-text-centered">
          {{ allocationEdit.weekData.startDate }} -
          {{ allocationEdit.weekData.endDate }}
        </p>
      </div>

      <div class="is-flex has-gap-1 is-align-items-center">
        <button
          class="button is-flex is-align-items-center"
          @click="handleMinusButton"
        >
          <b-icon icon="minus" />
        </button>

        <b-input
          class="has-text-centered mx-2 py-2"
          :value="formatAllocationTime(allocationEdit.allocatedHours)"
          disabled
        />
        <button
          class="button is-flex is-justify-content-center is-align-items-center"
          @click="handlePlusButton"
        >
          <b-icon icon="plus" />
        </button>
      </div>
      <div class="uppercase-subtitle-small">
        {{ $tf("resource.allocationBox.criticality|Kritikusság") }}
      </div>
      <div>
        <b-select
          v-model="allocationEdit.priority"
          @change="modifyAllocation"
          expanded
        >
          <option :value="RALLOC_PRIORITY.LOW.value">
            {{ $tf(RALLOC_PRIORITY.LOW.token) }}
          </option>
          <option :value="RALLOC_PRIORITY.MEDIUM.value">
            {{ $tf(RALLOC_PRIORITY.MEDIUM.token) }}
          </option>
          <option :value="RALLOC_PRIORITY.HIGH.value">
            {{ $tf(RALLOC_PRIORITY.HIGH.token) }}
          </option>
        </b-select>
      </div>
      <div class="mt-4 ml-1">
        <div class="is-flex">
          <div style="width: 1.35rem">
            <b-icon size="is-small" icon="clock-rotate-left" />
          </div>
          <div>
            :
            <span class="has-font-weight-700" v-html="availabilityHtml" />
          </div>
        </div>

        <div class="is-flex is-flex-direction-column mt-3">
          <div class="uppercase-subtitle-small">
            {{ $tf("resource.allocationRow.weeklyAllocation|Heti allokáció") }}
          </div>
          <div
            class="is-flex is-align-items-center"
            v-for="(allocation, index) of allocationsByProject"
            :key="index"
          >
            <div
              class="circle mr-1"
              :style="{
                backgroundColor:
                  allocation.projectData?.projectColor || 'green',
              }"
            />
            <span class="uppercase has-font-weight-600">
              {{ allocation.projectData?.identifier }}:
            </span>
            <span class="ml-1">
              {{ formatAllocationTime(allocation.hours) }}
            </span>
          </div>
          <div class="mt-2">
            <b-progress format="percent">
              <template #bar>
                <b-progress-bar
                  v-for="(allocation, index) of allocationsByProject"
                  :key="index"
                  :value="(allocation.hours / expectedHours) * 100"
                  :style="{
                    backgroundColor:
                      allocation.projectData?.projectColor || 'green',
                  }"
                />
              </template>
            </b-progress>
          </div>
        </div>

        <!--        TODO: backend műtét-->
        <!--        <div class="is-flex">-->
        <!--          <div style="width: 1.35rem">-->
        <!--            <img-->
        <!--              src="/beach.svg"-->
        <!--              :alt="$tf('resource.allocationBox.imageAlt.dayOff|Szabadság')"-->
        <!--            />-->
        <!--          </div>-->
        <!--          <p>: -</p>-->
        <!--        </div>-->
      </div>
    </div>
  </section>
  <div v-else>
    <b-loading v-model="isPageLoading" :is-full-page="true" />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { deepCopy } from "@/utils/util";
import LoadingMixin from "@/mixins/LoadingMixin";
import { RALLOC_PRIORITY } from "@/utils/const";

export default defineComponent({
  name: "AllocationBox",
  mixins: [LoadingMixin],
  props: {
    allocationData: {
      type: Object,
      required: true,
    },
    showAllocatedTimeInHours: {
      type: Boolean,
      required: false,
      default: false,
    },
    expectedHours: {
      type: String,
      Number,
      required: true,
    },
    allocationsByProject: {
      type: Array,
      required: true,
    },
    availabilityHtml: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    this.allocationEdit = await deepCopy(this.allocationData);
    this.loaded = true;
  },
  data() {
    return {
      RALLOC_PRIORITY,
      allocationEdit: {},
      loaded: false,
    };
  },
  methods: {
    handlePlusButton() {
      const unit = this.showAllocatedTimeInHours ? 1 : 4; //min 1 hours OR min 0.5 MWD (4 hours)
      this.allocationEdit.allocatedHours = this.nextDivisible(
        this.allocationEdit.allocatedHours,
        unit
      );
      this.$emit("modify-allocation", this.allocationEdit);
    },
    nextDivisible(x, n) {
      x = parseFloat(x);
      n = parseFloat(n);
      return x % n === 0 ? x + n : x + (n - (x % n));
    },
    handleMinusButton() {
      const unit = this.showAllocatedTimeInHours ? 1 : 4; //min 1 hours OR min 0.5 MWD (4 hours)
      this.allocationEdit.allocatedHours = this.previousDivisible(
        this.allocationEdit.allocatedHours,
        unit
      );
      this.$emit("modify-allocation", this.allocationEdit);
    },
    previousDivisible(x, n) {
      let result = x % n === 0 ? x - n : x - (x % n);
      return result < 0 ? 0 : result;
    },
    modifyAllocation() {
      this.$emit("modify-allocation", this.allocationEdit);
    },
    //TODO fuck this shit...
    // validateInput(event) {
    //   // Allow the user to clear the input
    //   if (event.key === 'Backspace' || event.keyCode === 8) {
    //     this.allocationEdit.allocatedHours = '';
    //     return;
    //   }
    //
    //   let input;
    //   if(this.allocationEdit.allocatedHours.length > 0){
    //     input = parseFloat(this.allocationEdit.allocatedHours) + parseFloat(event.key);
    //   } else{
    //     input = parseFloat(event.key);
    //   }
    //
    //   let value = parseFloat(input);
    //
    //   // If the input is not a valid number, reset it
    //   if (isNaN(value)) {
    //     this.allocationEdit.allocatedHours = '';
    //     return;
    //   }
    //
    //   // Determine step and max value based on context (hours or half-day)
    //   const step = this.showAllocatedTimeInHours ? 1 : 0.5;
    //   const max = this.showAllocatedTimeInHours ? 40 : 5.0;
    //
    //   // Ensure the value is within the acceptable range
    //   if (value < 0) {
    //     value = 0;
    //   } else if (value > max) {
    //     value = max;
    //   }
    //
    //   // Round the value to the nearest step (either 1 or 0.5)
    //   value = Math.round(value / step) * step;
    //
    //   // Set the validated value back to the input
    //   this.allocationEdit.allocatedHours = value.toFixed(step === 1 ? 0 : 1); // Format for .5 values if needed
    // },

    formatAllocationTime(timeInHours) {
      return this.showAllocatedTimeInHours
        ? `${timeInHours} H`
        : `${timeInHours / 8} MWD`;
    },
  },
});
</script>
<style>
.modal.allocation-box .modal-content {
  max-width: 20vw !important;
}

.modal.is-active.allocation-box .modal-background {
  background: rgb(75 75 75 / 15%) !important;
}

.modal.is-active.allocation-box .modal-content {
  width: auto !important;
}

.allocation-box > .modal-background {
  background-color: #1e1e1f24;
}
</style>
<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.bordered-box {
  border: 1px solid $grey-lighter;
  border-radius: 7.5px;
}

.bordered-modal {
  border: 1px solid black;
  border-radius: 5px;
}

.input-with-suffix {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.suffix {
  position: absolute;
  right: 10px;
  pointer-events: none;
  color: #888;
  margin-left: -30px;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
</style>
