import { mapGetters } from "vuex";
import {
  FORECAST_BASE_DATA_TYPE,
  FORECAST_RULES,
  FORECAST_YEAR_QUARTER,
} from "@/utils/const";
import { deepCopy, findForecastRule } from "@/utils/util";
import { decimal, minValue, required, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { snakeCase } from "lodash/string";
import { getCurrentInstance } from "vue";

export default {
  props: {
    edit: {
      type: Object,
      required: false,
    },
    parent: {
      type: Object,
      required: false,
    },
    selectedYear: {
      type: Number,
      required: true,
    },
  },
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  async mounted() {
    this.createMode = !this.edit || !this.edit?.id;
    const promises = [];
    promises.push(
      // this.$store.dispatch("employee/fetch"),
      this.$store.dispatch("enterprise_clients/fetchClients"),
      this.$store.dispatch("forecast_chance_category/fetchChanceCategories"),
      this.$store.dispatch("forecast_segment/fetchAllSegments")
    );

    for (const rule of FORECAST_RULES) {
      promises.push(
        this.$store.dispatch(rule.fetch.function, rule.fetch.params)
      );
    }

    await Promise.all(promises);

    if (this.edit) {
      this.initData(this.edit);
    } else {
      this.setSegmentRulesetVariables();
    }

    this.loaded = true;
  },
  data() {
    return {
      editObject: {
        supervisor: undefined,
        noClient: true,
        segment: undefined,
        project: undefined,
        ...FORECAST_RULES.map((it) => it.enum).reduce((acc, val) => {
          acc[val] = undefined;
          return acc;
        }, {}),
        ...Object.keys(FORECAST_YEAR_QUARTER).reduce((acc, val) => {
          acc[`hasIncome${val}`] = false;
          acc[`hasIncomeNextYear${val}`] = false;
          acc[`income${val}`] = undefined;
          acc[`incomeNextYear${val}`] = undefined;
          acc[`chanceCategory${val}`] = undefined;
          acc[`chanceCategoryNextYear${val}`] = undefined;
          return acc;
        }, {}),
      },
      hasIncomeNextYear: false,
      loaded: false,
      createMode: false,
      FORECAST_BASE_DATA_TYPE,
      FORECAST_RULES,
      FORECAST_YEAR_QUARTER,
    };
  },
  computed: {
    ...mapGetters({
      employees: "employee/employees",
      baseData: "project_base_data/getByType",
      clients: "enterprise_clients/clients",
      chanceCategories: "forecast_chance_category/chanceCategories",
      segments: "forecast_segment/allSegments",
      currency: "uiConfigStore/getCurrencySymbol",
      ...FORECAST_RULES.map((rule) => {
        return {
          [rule.enum]: rule.get.function,
        };
      }).reduce((acc, val) => Object.assign(acc, val)),
    }),
    parentSalesType() {
      return this.parent?.SALES_TYPE;
    },
    parentUserId() {
      return this.parent?.userId;
    },
    parentSegmentId() {
      return this.parent?.segment?.id;
    },
    parentAccountId() {
      return this.parent?.id;
    },
    selectedSegment() {
      return this.segments.find((it) => it.id === this.editObject.segment);
    },
    selectedSupervisor() {
      return this.employees.find((it) => it.id === this.editObject.supervisor);
    },
    filteredEmployees() {
      if (!this.selectedSegment?.users) return this.employees;
      return this.employees.filter((it) =>
        this.selectedSegment.users.includes(it.id)
      );
    },
    filterByRuleset() {
      return (type, all = false) => {
        const rule = FORECAST_RULES.find((it) => it.enum === type);
        let data = this[type];
        if (rule.get.index) data = data(rule.get.index);
        const filters = this.selectedSegment?.filters;
        if (!filters || all) return data;
        const filter = filters[type];
        if (!filter) return data;
        data = data.filter((it) =>
          filter.find((f) => f[rule.get.key] === it[rule.get.key])
        );
        if (
          this.v$.editObject[type]?.$params?.required?.type === "required" &&
          !data.map((it) => it[rule.get.key]).includes(this.editObject[type])
        ) {
          this.editObject[type] =
            data.length === 1 ? data[0][rule.get.key] : undefined;
        }
        return data;
      };
    },
  },
  watch: {
    "editObject.noClient": function (val) {
      if (val) this.editObject.ACCOUNT = undefined;
      else this.initAccount();
    },
    "editObject.hasIncomeQ1": function (val) {
      if (!val) {
        this.editObject.incomeQ1 = undefined;
        this.editObject.chanceCategoryQ1 = undefined;
      }
    },
    "editObject.hasIncomeQ2": function (val) {
      if (!val) {
        this.editObject.incomeQ2 = undefined;
        this.editObject.chanceCategoryQ2 = undefined;
      }
    },
    "editObject.hasIncomeQ3": function (val) {
      if (!val) {
        this.editObject.incomeQ3 = undefined;
        this.editObject.chanceCategoryQ3 = undefined;
      }
    },
    "editObject.hasIncomeQ4": function (val) {
      if (!val) {
        this.editObject.incomeQ4 = undefined;
        this.editObject.chanceCategoryQ4 = undefined;
      }
    },
    "editObject.hasIncomeNextYearQ1": function (val) {
      if (!val) {
        this.editObject.incomeNextYearQ1 = undefined;
        this.editObject.chanceCategoryNextYearQ1 = undefined;
      }
    },
    "editObject.hasIncomeNextYearQ2": function (val) {
      if (!val) {
        this.editObject.incomeNextYearQ2 = undefined;
        this.editObject.chanceCategoryNextYearQ2 = undefined;
      }
    },
    "editObject.hasIncomeNextYearQ3": function (val) {
      if (!val) {
        this.editObject.incomeNextYearQ3 = undefined;
        this.editObject.chanceCategoryNextYearQ3 = undefined;
      }
    },
    "editObject.hasIncomeNextYearQ4": function (val) {
      if (!val) {
        this.editObject.incomeNextYearQ4 = undefined;
        this.editObject.chanceCategoryNextYearQ4 = undefined;
      }
    },
    selectedSegment() {
      this.setSegmentRulesetVariables();
    },
  },
  validations: {
    editObject: {
      name: {
        required,
      },
      ACCOUNT: {
        required: requiredIf(function () {
          return !this.editObject.noClient;
        }),
      },
      SALES_TYPE: {
        required,
      },
      ...Object.keys(FORECAST_YEAR_QUARTER)
        .map((quarter) => {
          return {
            [`income${quarter}`]: {
              required: requiredIf(function () {
                return this.editObject[`hasIncome${quarter}`];
              }),
              decimal,
              minValue: minValue(0),
            },
            [`incomeNextYear${quarter}`]: {
              required: requiredIf(function () {
                return this.editObject[`hasIncomeNextYear${quarter}`];
              }),
              decimal,
              minValue: minValue(0),
            },
            [`chanceCategory${quarter}`]: {
              required: requiredIf(function () {
                return this.editObject[`hasIncome${quarter}`];
              }),
              decimal,
              minValue: minValue(0),
            },
            [`chanceCategoryNextYear${quarter}`]: {
              required: requiredIf(function () {
                return this.editObject[`hasIncomeNextYear${quarter}`];
              }),
              decimal,
              minValue: minValue(0),
            },
          };
        })
        .reduce((acc, val) => Object.assign(acc, val), {}),
    },
  },
  methods: {
    findForecastRule,
    close() {
      this.$emit("close");
    },
    initData(initData) {
      this.editObject = deepCopy(initData);

      FORECAST_RULES.forEach((rule) => {
        const key = Object.keys(initData).find(
          (it) => snakeCase(it).toUpperCase() === rule.enum.toUpperCase()
        );

        if (key && this.editObject[key]) {
          this.editObject[rule.enum] = initData[key][rule.get.key];
        }
      });
      this.editObject.segment = this.editObject.segment?.id;
      this.editObject.supervisor = this.editObject.userId;
      this.editObject.project = this.editObject.project?.identifier;
      if (!this.editObject.ACCOUNT) this.editObject.noClient = true;
      Object.keys(FORECAST_YEAR_QUARTER).forEach((quarter) => {
        if (this.editObject[`income${quarter}`]) {
          this.editObject[`hasIncome${quarter}`] = true;
          this.editObject[`chanceCategory${quarter}`] =
            this.editObject[`chanceCategory${quarter}`]?.id;
        }

        if (this.editObject[`incomeNextYear${quarter}`]) {
          this.hasIncomeNextYear = true;
          this.editObject[`hasIncomeNextYear${quarter}`] = true;
          this.editObject[`chanceCategoryNextYear${quarter}`] =
            this.editObject[`chanceCategoryNextYear${quarter}`]?.id;
        }
      });
    },
    setSegmentRulesetVariables() {
      this.initSalesType();
      this.initIncomeType();
      this.initServiceType();
      this.initSupervisor();
      this.initAccount();
    },
    initSalesType() {
      const salesTypes = this.filterByRuleset(
        FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE
      );
      if (salesTypes.length === 1) {
        const salesType = salesTypes[0];
        this.editObject.SALES_TYPE =
          salesType[
            findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE).get.key
          ];
      } else if (
        this.editObject.SALES_TYPE &&
        !salesTypes
          .map(
            (it) =>
              it[
                findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE).get
                  .key
              ]
          )
          .includes(this.editObject.SALES_TYPE)
      ) {
        this.editObject.SALES_TYPE = undefined;
      }
    },
    initIncomeType() {
      const incomeTypes = this.filterByRuleset(
        FORECAST_BASE_DATA_TYPE.ENUM.INCOME_TYPE
      );
      if (
        this.editObject.INCOME_TYPE &&
        !incomeTypes
          .map(
            (it) =>
              it[
                findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.INCOME_TYPE).get
                  .key
              ]
          )
          .includes(this.editObject.INCOME_TYPE)
      ) {
        this.editObject.INCOME_TYPE = undefined;
      }
    },
    initServiceType() {
      const serviceTypes = this.filterByRuleset(
        FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE
      );
      if (
        this.editObject.SERVICE_TYPE &&
        !serviceTypes
          .map(
            (it) =>
              it[
                findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE).get
                  .key
              ]
          )
          .includes(this.editObject.SERVICE_TYPE)
      ) {
        this.editObject.SERVICE_TYPE = undefined;
      }
    },
    initSupervisor() {
      if (this.editObject.segment) {
        const segment = this.segments.find(
          (it) => it.id === this.editObject.segment
        );
        if (
          this.editObject.supervisor &&
          !segment.users.map((it) => it.id).includes(this.editObject.supervisor)
        ) {
          this.editObject.supervisor = undefined;
        }
      }
    },
    initAccount() {
      if (this.editObject.segment && !this.editObject.noClient) {
        const clients = this.filterByRuleset("ACCOUNT");
        if (clients.length === 1) {
          this.editObject.ACCOUNT = clients[0].id;
        } else if (
          this.editObject.ACCOUNT &&
          !clients.map((it) => it.id).includes(this.editObject.ACCOUNT)
        ) {
          this.editObject.ACCOUNT = undefined;
        }
      }
    },
  },
};
