<template>
  <div>
    <div v-if="weekly.noData" class="utilization-top-row">
      {{ $tf("rallocPage.projectUtilization.noAllocation|N/A") }}
    </div>
    <div v-if="!weekly.noData && future" class="utilization-top-row">
      <b-icon icon="ballot-check" />{{ workdayify(weekly.allocatedHours) }}
    </div>
    <div v-if="!weekly.noData && !future" class="utilization-top-row">
      <b-icon icon="ballot-check" />{{ workdayify(weekly.allocatedHours) }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { percentify, workdayify } from "@/utils/util";

export default defineComponent({
  name: "WeeklyProjectUtilization",
  props: {
    weekly: {
      type: Object,
      required: true,
    },
    future: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    percentify,
    workdayify,
  },
  computed: {},
});
</script>

<style scoped lang="scss"></style>
