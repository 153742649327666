<template>
  <section class="section">
    <div class="container">
      <div class="is-flex is-justify-content-center mb-2">
        <b-button
          class="ml-auto"
          icon-left="chevron-left"
          style="height: 48px"
          @click="calendarYear--"
        >
        </b-button>
        <div>
          <b-button class="mx-2" style="height: 48px">
            <div>
              {{ calendarYear }}
            </div>
          </b-button>
        </div>
        <b-button
          class="mr-auto"
          icon-left="chevron-right"
          style="height: 48px"
          @click="calendarYear++"
        >
        </b-button>
      </div>
      <div class="columns is-multiline">
        <div
          v-for="month in 12"
          class="column is-one-quarter mt-4"
          :key="month"
        >
          <div class="subtitle has-text-centered mb-0">
            {{ VUE_CAL_LOCALE[storedLocale]?.months[month - 1] }}
          </div>
          <vue-cal
            hide-view-selector
            hide-title-bar
            :locale="VUE_CAL_LOCALE[storedLocale]"
            :disable-views="['years', 'year', 'week', 'day']"
            active-view="month"
            :time="false"
            xsmall
            :selected-date="new Date(this.calendarYear, month - 1)"
            :events="calendarEvents"
          >
            <template #cell-content="{ cell, events }">
              <span
                class="vuecal__cell-date"
                :class="getCellClass(cell, events)"
                style="height: inherit"
                @click="handleClick(events, cell.formattedDate)"
              >
                {{ cell.content }}
              </span>
            </template>
          </vue-cal>
        </div>
      </div>

      <section class="section">
        <div class="container">
          <h1 class="title">{{ $tf("specialDays.title|Különleges napok") }}</h1>
          <h2 class="subtitle">
            {{
              $tf(
                "specialDays.subtitle|Ünnepnapok, hétvégék, ledolgozandók, egyéb különleges napok megadása."
              )
            }}
          </h2>
        </div>
        <hr />
        <div class="columns">
          <div
            class="column is-2 is-offset-5 card is-centered has-text-centered"
          >
            <b-field
              :label="$tf('specialDays.year|Év')"
              label-position="on-border"
            >
              <b-select
                v-model="selectedYear"
                :placeholder="$tf('specialDays.year.placeholder|Cél év')"
                expanded
                icon="calendar-alt"
                @update:modelValue="setSelectedYear(selectedYear)"
              >
                <option
                  v-for="year in years"
                  v-bind:key="year"
                  :modelValue="year"
                >
                  {{ year }}
                </option>
              </b-select>
            </b-field>
            <b-button type="is-info" @click="initWeekends">
              {{ $tf("specialDays.initWeekends|Hétvégék felvétele") }}
            </b-button>
          </div>
        </div>

        <div class="column is-4 is-offset-4 newSpecialDay">
          <form @submit.prevent="submit">
            <b-collapse v-model="isCollapseOpen" animation="fade" class="panel">
              <template v-slot:trigger>
                <div class="panel-heading text-center">
                  <strong>{{
                    $tf("specialDays.addSpecialDay|Új nap felvitele")
                  }}</strong>
                </div>
              </template>
              <div class="column collapseBlock">
                <b-field :expanded="true">
                  <vuelidated-form :validations="v$.formData.type">
                    <template v-slot="{ errors, invalid }">
                      <b-field
                        :expanded="true"
                        :message="v$.formData.type.$error ? errors[0] : null"
                        :type="{ 'is-danger': invalid }"
                        label-for="type"
                      >
                        <template #label>
                          {{ $tf("specialDays.type|Típus") }}
                          <span class="has-text-danger">*</span>
                        </template>
                        <b-autocomplete
                          v-model="typeSelected"
                          :data="filteredTypeArray"
                          :expanded="true"
                          :placeholder="
                            $tf('specialDays.type.placeholder|Nap típusa')
                          "
                          clearable
                          field="display"
                          open-on-focus
                          @select="(option) => (formData.type = option)"
                        >
                          <template #empty
                            >{{ $tf("specialDays.type.empty|Nincs találat") }}
                          </template>
                        </b-autocomplete>
                      </b-field>
                    </template>
                  </vuelidated-form>
                </b-field>

                <b-field :expanded="true">
                  <vuelidated-input
                    v-model="formData.name"
                    :expanded="true"
                    :label="$tf('specialDays.name|Név')"
                    :placeholder="$tf('specialDays.name.placeholder|Nap neve')"
                    :ref-value="'name'"
                    :validation-rule="v$.formData.name"
                  />
                </b-field>

                <b-field :expanded="true">
                  <vuelidated-input
                    v-model="formData.description"
                    :expanded="true"
                    :label="$tf('specialDays.description|Leírás')"
                    :placeholder="
                      $tf('specialDays.description.placeholder|Leírás')
                    "
                    :ref-value="'description'"
                    :validation-rule="v$.formData.description"
                  />
                </b-field>

                <b-field expanded>
                  <vuelidated-form :validations="v$.formData.dayDate">
                    <template v-slot="{ errors, invalid }">
                      <b-field
                        :expanded="true"
                        :message="v$.formData.dayDate.$error ? errors[0] : null"
                        :type="{ 'is-danger': invalid }"
                        label-for="dayDate"
                      >
                        <template #label>
                          {{ $tf("specialDays.date|Dátum") }}
                          <span class="has-text-danger">*</span>
                        </template>
                        <f-datepicker
                          id="dayDate"
                          ref="dayDate"
                          v-model="formData.dayDate"
                          :events="specDaysForDatePicker"
                          :expanded="true"
                          :placeholder="
                            $tf('specialDays.date.placeholder|Nap kiválasztása')
                          "
                          icon="calendar-alt"
                          indicators="bars"
                          name="dayDate"
                          position="is-top-right"
                        />
                      </b-field>
                    </template>
                  </vuelidated-form>
                </b-field>

                <b-field>
                  <b-button native-type="submit" type="is-info">
                    {{ $tf("specialDays.submit|Küldés") }}
                  </b-button>
                </b-field>
              </div>
            </b-collapse>
          </form>
        </div>
        <div class="container">
          <b-table
            ref="table"
            :data="specialDays?.items ?? []"
            :loading="loading"
            :per-page="pagination.size"
            :total="pagination.totalCount"
            backend-pagination
            backend-sorting
            default-sort-direction="asc"
            hoverable
            mobile-cards
            paginated
            striped
            @sort="onSort"
            @page-change="onPageChange"
          >
            <b-table-column
              v-slot="props"
              :label="$tf('specialDays.table.type|Típus')"
              field="type"
              sortable
              width="160"
            >
              {{ props.row.type }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              :label="$tf('specialDays.table.name|Név')"
              field="name"
              sortable
              width="160"
            >
              {{ props.row.name }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              :label="$tf('specialDays.table.date|Dátum')"
              field="dayDate"
              sortable
              width="80"
            >
              <span v-if="props.row.type === 'WEEKEND'" class="tag is-light">
                {{ formatDate(props.row.dayDate) }}
              </span>
              <span
                v-else-if="props.row.type === 'EXTRA_WORKDAY'"
                class="tag is-success"
              >
                {{ formatDate(props.row.dayDate) }}
              </span>
              <span
                v-else-if="props.row.type === 'HOLIDAY'"
                class="tag is-danger"
              >
                {{ formatDate(props.row.dayDate) }}
              </span>
            </b-table-column>

            <b-table-column
              v-slot="props"
              :label="$tf('specialDays.table.description|Leírás')"
              field="desc"
              width="160"
            >
              {{ props.row.description }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              :label="$tf('specialDays.table.actions|Műveletek')"
              centered
              field="actions"
              width="80"
            >
              <b-tooltip
                :label="$tf('specialDays.table.actions.delete|Törlés')"
                type="is-light"
              >
                <clickable-icon
                  icon="trash-alt"
                  type="is-info"
                  @click="deleteDay(props.row.id)"
                />
              </b-tooltip>

              <b-tooltip
                :label="$tf('specialDays.table.actions.edit|Módosítás')"
                type="is-light"
              >
                <clickable-icon
                  icon="edit"
                  type="is-info"
                  @click="launchEditModal(props.row.id)"
                />
              </b-tooltip>
            </b-table-column>

            <template #bottom-left>
              <div class="is-flex has-gap-1 is-align-items-center">
                <div>{{ $tf("specialDays.table.pageSize|Oldal mérete") }}</div>
                <div id="size-selector" class="ml-2 mr-2">
                  <b-select
                    v-model="pagination.size"
                    size="is-small"
                    @update:modelValue="getSpecialDays"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                  </b-select>
                </div>
                <div>
                  {{ $tf("specialDays.table.sum|Összesen:") }}
                  <strong>{{ pagination.totalCount }}</strong>
                </div>
              </div>
            </template>

            <template #empty>
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>
                    {{
                      $tf(
                        "specialDays.table.empty|Erre az évre nincs megadva különleges nap."
                      )
                    }}
                  </p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </section>
      <b-modal
        v-model="isEditSpecialDayActive"
        aria-modal
        aria-role="dialog"
        scroll="clip"
      >
        <special-day-modal
          :spec-days="specDaysForDatePicker"
          :edited-special-day="specialDayToEdit"
          @on-submit="getSpecialDays()"
        />
      </b-modal>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate, localFetch } from "@/utils/util";
import VuelidatedForm from "@/components/module/input/VuelidatedForm";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { maxLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import SpecialDayModal from "@/components/absence/specialday/SpecialDayModal";
import FDatepicker from "@/components/module/input/FDatepicker.vue";
import VueCal from "vue-cal";
import { VUE_CAL_LOCALE } from "@/utils/const";

export default {
  name: "SpecialDay",
  components: {
    FDatepicker,
    SpecialDayModal,
    ClickableIcon,
    VuelidatedInput,
    VuelidatedForm,
    VueCal,
  },
  setup: () => ({ v$: useVuelidate() }),

  async mounted() {
    await this.getSpecialDays();
  },

  data: function () {
    return {
      VUE_CAL_LOCALE,
      calendarApi: null,
      formData: {
        type: null,
        name: null,
        description: null,
        dayDate: null,
      },
      formatDate,
      pagination: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sort: {
        field: "dayDate",
        order: "asc",
      },
      calendarYear: new Date().getFullYear(),
      selectedYear: new Date().getFullYear(),
      specDayTypes: [
        {
          enum: "WEEKEND",
          display: this.$tf("specialDays.type.weekend|Hétvége"),
        },
        {
          enum: "HOLIDAY",
          display: this.$tf("specialDays.type.holiday|Ünnepnap"),
        },
        {
          enum: "EXTRA_WORKDAY",
          display: this.$tf("specialDays.type.extraWorkday|Munkanap"),
        },
      ],
      isCollapseOpen: false,
      specDayTypeName: "",
      typeSelected: null,
      dayDate: null,
      specialDayToEdit: null,
      specialDayIdToEdit: null,
      isEditSpecialDayActive: false,
      loading: true,
      storedLocale: localFetch("stored_locale") ?? "hu",
    };
  },

  validations: {
    formData: {
      type: {
        required,
      },
      name: {
        required,
      },
      description: {
        maxLength: maxLength(255),
      },
      dayDate: {
        required,
      },
    },
  },

  watch: {
    calendarYear() {
      this.getSpecialDays();
    },
    selectedYear() {
      this.getSpecialDays();
    },
  },

  computed: {
    calendarEvents() {
      return (
        this.specialDaysAll?.map((specialDay) => {
          return {
            specialDayId: specialDay.id,
            title: specialDay.name,
            start: specialDay.dayDate,
            end: specialDay.dayDate,
            allDay: true,
            type: specialDay.type,
            description: specialDay.description,
          };
        }) ?? []
      );
    },
    years() {
      let years = [];
      let curYear = new Date().getFullYear() + 1;
      while (curYear >= 2020) {
        years.push(curYear);
        curYear--;
      }
      return years;
    },
    ...mapGetters({
      specialDaysGetter: "specialday/specialDays",
      specialDaysAllGetter: "specialday/specialDaysAll",
    }),
    specialDays() {
      return this.specialDaysGetter(this.selectedYear);
    },
    specialDaysAll() {
      return this.specialDaysAllGetter(this.calendarYear);
    },
    specDaysForDatePicker() {
      let specDaysForDatePicker = [];
      if (this.specialDaysAll && this.specialDaysAll.length > 0) {
        for (let s in this.specialDaysAll) {
          if (this.specialDaysAll[s].type === "EXTRA_WORKDAY") {
            let specDay = {
              date: new Date(this.specialDaysAll[s].dayDate),
              type: "is-success",
            };
            specDaysForDatePicker.push(specDay);
          } else if (this.specialDaysAll[s].type === "HOLIDAY") {
            let specDay = {
              date: new Date(this.specialDaysAll[s].dayDate),
              type: "is-danger",
            };
            specDaysForDatePicker.push(specDay);
          } else if (this.specialDaysAll[s].type === "WEEKEND") {
            let specDay = {
              date: new Date(this.specialDaysAll[s].dayDate),
              type: "is-light",
            };
            specDaysForDatePicker.push(specDay);
          }
        }
      }
      return specDaysForDatePicker;
    },
    filteredTypeArray() {
      return this.specDayTypes.filter((option) => {
        return (
          option.display
            .toString()
            .toLowerCase()
            .indexOf(this.specDayTypeName.toLowerCase()) >= 0
        );
      });
    },
  },

  methods: {
    handleClick(events, date) {
      if (events.length > 0) {
        this.specialDayToEdit = {
          id: events[0].specialDayId,
          name: events[0].title,
          dayDate: events[0].start,
          type: events[0].type,
          description: events[0].description,
        };
        this.isEditSpecialDayActive = true;
      } else {
        const el = this.$el.getElementsByClassName("newSpecialDay")[0];
        this.isCollapseOpen = true;
        this.formData.dayDate = new Date(date);
        el.scrollIntoView();
      }
    },
    async getSpecialDays(force) {
      this.loading = true;
      await this.$store.dispatch("specialday/fetchSpecialDaysAll", {
        year: this.calendarYear,
        force,
      });
      const { page, size } = this.pagination;
      const sort =
        this.sort.field !== null
          ? `${this.sort.field},${this.sort.order}`
          : null;
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append("page", page);
      urlSearchParams.append("size", size);
      urlSearchParams.append("sort", sort);
      urlSearchParams.append("year", this.selectedYear);
      await this.$store.dispatch("specialday/getSpecialDays", {
        params: { params: urlSearchParams },
        force,
      });
      if (this.specialDays) {
        this.pagination = this.specialDays.pagination;
      }
      this.loading = false;
    },
    initWeekends() {
      this.$store
        .dispatch("specialday/initWeekends", this.selectedYear)
        .then(() => {
          this.getSpecialDays(true);
        });
    },
    submit() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        let request = {
          type: this.formData.type.enum,
          name: this.formData.name,
          description: this.formData.description,
          dayDate: formatDate(this.formData.dayDate),
        };
        this.$store
          .dispatch("specialday/createSpecialDay", request)
          .then(() => {
            this.isCollapseOpen = false;
            this.getSpecialDays(true);
          });
      }
    },
    deleteDay(dayId) {
      this.$buefy.dialog.confirm({
        title: this.$tf("specialDays.deleteModal.title|Különleges nap törlése"),
        message: this.$tf(
          "specialDays.deleteModal.message|Biztos vagy benne, hogy törölni szeretnéd a napot?"
        ),
        confirmText: this.$tf("specialDays.deleteModal.confirm|Nap törlése"),
        cancelText: this.$tf("specialDays.deleteModal.cancel|Mégse"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          await this.$store
            .dispatch("specialday/deleteSpecialDay", dayId)
            .then(() => {
              this.getSpecialDays(true);
              this.$buefy.toast.open(
                this.$tf(
                  "specialDays.deleteModal.success|Különleges nap törölve!"
                )
              );
            });
        },
      });
    },
    async setSelectedYear(selectedYear) {
      this.isCollapseOpen = false;
      this.selectedYear = selectedYear;
      await this.getSpecialDays();
    },

    launchEditModal(dayId) {
      this.specialDayIdToEdit = dayId;
      this.isEditSpecialDayActive = true;
    },

    onPageChange: function (page) {
      this.pagination.page = page - 1;
      this.getSpecialDays();
    },
    onSort: function (field, order) {
      this.sort.field = field;
      this.sort.order = order;
      this.getSpecialDays();
    },
    getCellClass(cell, events) {
      if (!cell.outOfScope) {
        if (events[0]?.type === "HOLIDAY") {
          return "holiday-cell";
        } else if (events[0]?.type === "WEEKEND") {
          return "weekend-cell";
        }
      }
    },
  },
};
</script>

<style lang="stylus">

.collapseBlock {
  border: 1px $grey-light solid;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.holiday-cell {
  background-color: #f08080
}
.weekend-cell {
  background-color: silver;
  color: black;
}

.vuecal__cell--selected, .vuecal__cell--today {
  background-color: transparent !important;
}
.b-tooltip {
  margin-left: 0;
}
</style>
