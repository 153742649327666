<template>
  <div class="modal-card container" style="width: 100%">
    <p class="title">
      {{
        $tf(
          "absencePastRequestModal.title|Korábbi szabadságkérelmek böngészése"
        )
      }}
    </p>
    <hr />
    <div class="container overflow-auto">
      <b-field
        :label="$tf('absencePastRequestModal.worker|Munkatárs')"
        label-position="on-border"
        class="is-long mb-2"
      >
        <b-autocomplete
          rounded
          v-model="name"
          :data="filteredEmployees"
          :placeholder="
            $tf('absencePastRequestModal.worker.placeholder|Pl. Elektrom Ágnes')
          "
          icon="search"
          clearable
          field="name"
          @select="(option) => (selectedUser = option)"
        >
          <template #empty>{{
            $tf("absencePastRequestModal.worker.empty|Nincs találat")
          }}</template>
        </b-autocomplete>
      </b-field>

      <b-table
        :data="requests.items"
        :loading="loading"
        ref="table"
        striped
        hoverable
        mobile-cards
        paginated
        backend-pagination
        :total="pagination.totalCount"
        :per-page="pagination.size"
        @page-change="onPageChange"
        backend-sorting
        @sort="onSort"
        default-sort-direction="asc"
      >
        <b-table-column
          field="created"
          :label="$tf('absencePastRequestModal.table.date|Kérelem dátuma')"
          width="150"
          sortable
          v-slot="props"
        >
          {{ formatDate(props.row.created) }}
        </b-table-column>

        <b-table-column
          field="type"
          :label="$tf('absencePastRequestModal.table.type|Típus')"
          sortable
          v-slot="props"
        >
          <b-tag :style="style(props.row.type.color)">
            {{ props.row.type.name }}
          </b-tag>
        </b-table-column>

        <b-table-column
          field="status"
          :label="$tf('absencePastRequestModal.table.status|Státusz')"
          sortable
          v-slot="props"
        >
          {{ props.row.status ? props.row.status.display : "-" }}
        </b-table-column>

        <b-table-column
          field="description"
          :label="$tf('absencePastRequestModal.table.description|Leírás')"
          centered
          v-slot="props"
        >
          {{ props.row.description }}
        </b-table-column>

        <b-table-column
          field="date"
          :label="$tf('absencePastRequestModal.requestDate|Dátum')"
          centered
          width="450"
          v-slot="props"
        >
          <b-tag
            v-for="(actDate, index) in props.row.absenceRequestDates"
            :key="index"
            :type="getDateType(props.row.status ? props.row.status.enum : null)"
          >
            {{ formatDate(actDate) }}
          </b-tag>
        </b-table-column>

        <template #empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p><b-icon icon="smile-beam" size="is-large"></b-icon></p>
              <p>
                {{
                  $tf(
                    "absencePastRequestModal.table.empty|A kiválasztott felhasználónak nincs korábbi szabadságkérelme."
                  )
                }}
              </p>
            </div>
          </section>
        </template>

        <template #bottom-left>
          {{ $tf("absencePastRequestModal.table.pageSize|Oldal mérete") }}
          <div id="size-selector" class="ml-2 mr-2">
            <b-select
              v-model="pagination.size"
              @update:modelValue="getPastRequests"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
              <option :value="100">100</option>
            </b-select>
          </div>
          <p>
            {{
              $tf("absencePastRequestModal.table.sum|Összesen: {sum}", {
                sum: pagination.totalCount,
              })
            }}
          </p>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  createUrlSearchParams,
  formatDate,
  getContrastedColor,
} from "@/utils/util";

export default {
  name: "PastRequestBrowserModal",
  props: {
    employees: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      requests: "absence_request/pmAbsenceRequestsPast",
    }),
    filteredEmployees() {
      let employees = this.employees || [];
      return employees.filter((element) => {
        if (!this.name || !element.name) return false;
        return (
          element.name
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
  },
  watch: {
    async selectedUser(value) {
      if (value) {
        await this.getPastRequests();
      }
    },
  },
  data() {
    return {
      loading: false,
      formatDate,
      pagination: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sort: {
        field: "created",
        order: "desc",
      },
      selectedUser: {},
      name: "",
    };
  },
  methods: {
    async getPastRequests() {
      this.loading = true;
      const searchParams = createUrlSearchParams(this.pagination, this.sort);
      let params = {
        searchParams,
        user: this.selectedUser.id,
      };
      await this.$store.dispatch(
        "absence_request/getPmAbsenceRequestsPast",
        params
      );
      if (this.requests.pagination) {
        this.pagination = this.requests.pagination;
      }
      this.loading = false;
    },
    getDateType(status) {
      switch (status) {
        case "REQUEST":
          return "is-info";
        case "LINE_APPROVED":
          return "is-info";
        case "APPROVED":
          return "is-info";
        case "ACTIVE":
          return "is-success";
        case "TAKEN":
          return "is-light";
        case "DENIED":
          return "is-danger";
        case "REVOKED":
          return "is-light";
      }
    },
    onPageChange: function (page) {
      this.pagination.page = page - 1;
      this.getAbsenceRequests();
    },
    onSort: function (field, order) {
      this.sort.field = field;
      this.sort.order = order;
      this.getAbsenceRequests();
    },
    style(color) {
      return (
        "color: " +
        getContrastedColor(color) +
        ";" +
        "background-color:" +
        color
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
