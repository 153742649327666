<template>
  <loading-component>
    <template #loader>
      <div-with-loading
        loading-height="144px"
        loading-width="100%"
      ></div-with-loading>
    </template>
    <div
      class="card timesheet-day"
      :class="{
        'is-today': data.isToday,
        'is-clickable': !dashboardView && (shouldHaveWorklog || haveWorklog),
        'is-disabled': disabled,
      }"
      :style="`background: linear-gradient(90deg, ${completionBackground} ${completionPercentage}%, transparent ${completionPercentage}%);`"
      @click="onClick"
      @keyup.enter="onClick"
      @keyup.space="onClick"
      :tabindex="
        !this.dashboardView && !this.disabled && this.shouldHaveWorklog ? 0 : -1
      "
    >
      <template v-if="data.isCurrentMonth">
        <div class="timesheet-card-header">
          <label v-if="dashboardView" class="mr-2">{{
            $tf(getNameOfDay(data.date))
          }}</label>
          <label v-else class="mr-2 timesheet-card-header-name">{{
            data.monthDay
          }}</label>

          <span v-if="!dashboardView" class="timesheet-card-header-right mr-1">
            <b-tag
              rounded
              class="overtime-badge"
              v-if="data.hasCashOvertime || data.hasShiftOvertime"
              >{{ $tf("dayTime.overtime|Túlóra") }}</b-tag
            >

            <b-tooltip
              :label="
                $tf('dayTime.untrackedLog|Beazonosítatlan projektre logolt idő')
              "
              class="ml-2 mt-1"
              v-if="data.hasUntracked"
            >
              <b-icon
                icon="exclamation-triangle"
                size="is-small"
                class="untracked-triangle"
              />
            </b-tooltip>

            <b-tooltip
              :label="
                $tf(
                  'dayTime.conflictedLog|Több jegyhez tartozó időbejegyzés! Keresd fel a Projekt Menedzsert!'
                )
              "
              class="ml-2 mt-1"
              v-if="data.hasConflicted"
            >
              <b-icon
                icon="exclamation"
                size="is-small"
                class="conflicted-error"
              />
            </b-tooltip>

            <div class="ml-2 mt-1" v-if="shouldHaveWorklog && !disabled">
              <b-icon
                v-if="isComplete"
                icon="check-circle"
                size="is-small"
                class="complete-circle"
              />
              <b-icon
                v-else-if="isOvercomplete"
                icon="plus-circle"
                size="is-small"
                class="overcomplete-circle"
              />
              <b-icon
                v-else
                icon="exclamation-circle"
                size="is-small"
                class="incomplete-circle"
              />
            </div>
          </span>
        </div>
        <div
          v-if="shouldHaveWorklog"
          class="work-day-content"
          :class="data.css"
          :style="
            !dashboardView && (data.hasCashOvertime || data.hasShiftOvertime)
              ? 'padding-top: 0;'
              : ''
          "
        >
          <div class="work-day-content-time mr-1 do-not-wrap">
            <b-icon icon="clock" size="is-small" class="mr-2" pack="fad" />
            {{ $filterSecondToTimeFormat(data.logged) }}
            <label class="overtime" v-if="data.overtime"
              >+ {{ $filterSecondToTimeFormat(data.overtime) }}</label
            >
          </div>

          <div
            class="work-day-content-badges mr-3"
            v-if="
              !dashboardView && (data.hasCashOvertime || data.hasShiftOvertime)
            "
          >
            <b-tag
              rounded
              class="overtime-badge overtime-badge-green ml-1 mr-1 mt-1"
              v-if="data.hasCashOvertime"
              >{{ $tf("dayTime.overtimeType.payout|Kifizetés") }}
            </b-tag>

            <b-tag
              rounded
              class="overtime-badge overtime-badge-green ml-1 mr-1 mt-1"
              v-if="data.hasShiftOvertime"
              >{{ $tf("dayTime.overtimeType.shift|Csúsztatás") }}
            </b-tag>
          </div>
        </div>
        <div
          v-else
          class="free-day-content"
          :title="$tf('dayTime.holiday|Szünnap')"
        >
          <div class="work-day-content" :class="data.css">
            <div
              v-if="data.logged > 0 || data.overtime > 0"
              class="work-day-content-time mr-1 do-not-wrap"
            >
              <b-icon icon="clock" size="is-small" class="mr-2" pack="fad" />
              {{ $filterSecondToTimeFormat(data.logged) }}
              <label class="overtime" v-if="data.overtime"
                >+ {{ $filterSecondToTimeFormat(data.overtime) }}</label
              >
            </div>
          </div>
          <div
            class="is-flex is-align-items-center mr-3"
            style="max-height: 4rem; max-width: 4rem"
          >
            <WeekendGuru v-if="data.isWeekend" />
            <HolidayBeach v-else />
          </div>
        </div>
      </template>
      <div v-else class="not-current-month">
        {{ data.monthDay }}
      </div>
    </div>
  </loading-component>
</template>

<script>
import WeekendGuru from "@/components/timesheet/tab/tile/WeekendGuru.vue";
import HolidayBeach from "@/components/timesheet/tab/tile/HolidayBeach.vue";
import LoadingComponent from "@/components/loading/LoadingComponent.vue";
import DivWithLoading from "@/components/loading/DivWithLoading.vue";

export default {
  name: "DayTime",
  components: { HolidayBeach, WeekendGuru, LoadingComponent, DivWithLoading },
  props: {
    data: {
      required: true,
    },
    dashboardView: {
      required: false,
      default: false,
    },
  },
  computed: {
    disabled() {
      return (
        (this.shouldHaveWorklog && this.data.isFuture) ||
        !this.data.isCurrentMonth
      );
    },
    shouldHaveWorklog() {
      return (
        !this.data.isHoliday && !this.data.isWeekend && !this.data.isAbsence
      );
    },
    haveWorklog() {
      return this.data.overtime > 0 || this.data.logged > 0;
    },
    isComplete() {
      return this.data.expectedHoursOfDay * 60 * 60 === this.data.logged;
    },
    isOvercomplete() {
      return this.data.expectedHoursOfDay * 60 * 60 < this.data.logged;
    },
    completionPercentage() {
      let logged = this.data.logged;
      const expected = this.data.expectedHoursOfDay * 60 * 60;
      if ((!this.shouldHaveWorklog || this.data.isFuture) && logged === 0)
        return 0;
      if (expected === 0 && logged > expected) return 100;
      if (logged === 0 && expected > 0 && this.shouldHaveWorklog) return 100;
      if (logged >= expected * 2) return 100;
      if (logged > expected) logged = logged % expected;
      return Math.round((logged / expected) * 100);
    },
    completionBackground() {
      const logged = this.data.logged;
      const expected = this.data.expectedHoursOfDay * 60 * 60;
      if (logged > expected) return "var(--purple--10--rgba)";
      if (logged === 0) return "var(--red--10--rgba)";
      if (logged < expected) return "var(--yellow--10--rgba)";
      if (logged === expected) return "var(--green--10--rgba)";
      return "#00000000";
    },
  },
  data() {
    return {
      days: [
        "days.sunday|Vasárnap",
        "days.monday|Hétfő",
        "days.tuesday|Kedd",
        "days.wednesday|Szerda",
        "days.thursday|Csütörtök",
        "days.friday|Péntek",
        "days.saturday|Szombat",
      ],
    };
  },
  methods: {
    getNameOfDay(date) {
      return this.days[new Date(date).getDay()];
    },
    onClick() {
      if (
        !this.dashboardView &&
        !this.disabled &&
        (this.shouldHaveWorklog || this.haveWorklog)
      ) {
        this.$emit("open", this.data.date);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/colors";
@import "~@/assets/scss/media-queries.scss";

.timesheet-day {
  border: 1px solid $grey-lighter;
  border-radius: 8px !important;
  background: $white;
  box-shadow: none !important;
  color: $custom-dark-blue !important;
  width: 100%;
  height: 100%;
  min-height: 118px;

  &.is-disabled {
    cursor: default !important;
    background-color: $grey-lightest !important;
    border: 1px solid $grey-light;

    .work-day-content {
      color: $grey-lighter;
    }
  }

  label {
    cursor: inherit;
  }

  .timesheet-card-header {
    border-bottom: 1px solid $grey-lightest;
    box-shadow: none;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem 0.5rem 1rem;
    max-height: 2.5rem;
    min-height: 2.5rem;

    &-name {
      white-space: nowrap;
      display: inline-block;
    }

    @include lg-max {
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      &-name {
        font-size: 0.8rem;
        white-space: nowrap;
        display: inline-block;
        margin-right: 0 !important;
      }
    }

    &-right {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
  }

  .free-day-content {
    height: calc(100% - 2.5rem);
    width: 100%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
    background-position: center right;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .work-day-content {
    font-size: 1.5rem;
    font-weight: bold;
    align-items: center;
    padding-left: 1rem;
    padding-top: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    min-height: 4rem;

    .work-day-content-badges {
      display: flex;
      margin-left: auto;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 0;
      position: absolute;
      bottom: -0.5rem;
      right: -1.5rem;
    }

    .overtime {
      color: $yellow !important;
      white-space: nowrap;
      padding-left: 0.65rem;
      display: block;
    }

    &.is-primary {
      color: $purple;
    }

    &.is-danger {
      color: $red;
    }

    &.is-week-complete {
      color: $green;
    }

    &.is-week-incomplete {
      color: $red;
    }
  }

  .not-current-month {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.25rem;
    width: 100%;
    height: 100%;
    cursor: default;
    padding-top: 1rem;
  }
}
</style>
