<template>
  <div class="issues">
    <b-modal
      v-model="taskModalOpen"
      aria-modal
      aria-role="dialog"
      has-modal-card
    >
      <div class="card p-4">
        <task-form
          :edited-task="taskEdit"
          :employees="employees"
          :project-id="projectDetails.id"
          :tigs="projectDetails.tigDetails"
          project-type="BASIC"
          @task-submitted="handleTaskSubmit"
        ></task-form>
      </div>
    </b-modal>
    <div class="is-flex">
      <b-button
        class="ml-auto"
        rounded
        size="is-small"
        type="is-info"
        @click="onExportClick"
      >
        {{ $tf("projectPage.tasks.list.export|Exportálás") }}
      </b-button>
    </div>
    <br />
    <div v-for="(issues, tigId) in groupedIssues" :key="tigId" class="mt-4">
      <div class="columns">
        <div class="column pb-0">
          <h3>
            {{ $tf("projectPage.tasks.list.table.tig|Mérföldkő") }}:
            {{ pasteTigInfo(parseInt(tigId)) }}
          </h3>
        </div>
        <div class="column pb-0 is-narrow">
          <b-button
            :disabled="changedStatuses.length === 0"
            class="justify-end"
            rounded
            size="is-small"
            type="is-primary"
            @click="updateIssueStatus"
            >{{
              $tf("projectPage.tasks.list.actions.status|Státuszok frissítése")
            }}
          </b-button>
        </div>
      </div>
      <div class="flipped-table-container">
        <div class="flipped-table-content">
          <b-table
            ref="journalTable"
            :data="issues || []"
            :per-page="20"
            :show-detail-icon="true"
            aria-current-label="Aktuális lap"
            aria-next-label="Következő lap"
            aria-page-label="Lap"
            aria-previous-label="Előző lap"
            class="bigger-table-margins"
            detail-key="key"
            detailed
            paginated
          >
            <b-table-column
              v-slot="props"
              :label="$tf('projectPage.tasks.list.table.key|Kulcs')"
              field="key"
              sortable
            >
              <a
                :href="createTicketUrl(props.row.key)"
                :title="props.row.key"
                target="_blank"
                >{{ props.row.key }}</a
              >
            </b-table-column>

            <b-table-column
              v-slot="props"
              :label="$tf('projectPage.tasks.list.table.name|Név')"
              field="name"
              sortable
            >
              {{ props.row.name }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              :label="$tf('projectPage.tasks.list.table.status|Státusz')"
              centered
              field="status"
              sortable
            >
              <b-select
                v-if="isSelfTrackingMode"
                v-model="issueStatuses[props.row.id]"
              >
                <option value="TODO">
                  {{ $tf("projectPage.tasks.list.status.todo|TEENDŐ") }}
                </option>
                <option value="IN_PROGRESS">
                  {{
                    $tf("projectPage.tasks.list.status.inProgress|FOLYAMATBAN")
                  }}
                </option>
                <option value="DONE">
                  {{ $tf("projectPage.tasks.list.status.done|KÉSZ") }}
                </option>
              </b-select>
              <b-tag v-else :type="getStatusObject(props.row.status).type">
                {{ getStatusObject(props.row.status).text }}
              </b-tag>
            </b-table-column>

            <b-table-column
              v-slot="props"
              :label="$tf('projectPage.tasks.list.table.type|Típus')"
              field="type"
              sortable
            >
              {{ props.row.type }}
            </b-table-column>

            <b-table-column
              :label="$tf('projectPage.tasks.list.table.ratio|Arány')"
              field="type"
              sortable
            >
              <template v-slot:header="{ column }">
                <div class="column-header">
                  {{ column.label }}
                </div>
              </template>
              <template v-slot="props">
                <div class="columns width-140">
                  <div class="column">
                    <b-progress
                      :show-value="
                        isNaN(
                          calcProgressValue(
                            props.row.estimateSeconds,
                            props.row.spentSeconds
                          )
                        ) || props.row.estimateSeconds < props.row.spentSeconds
                      "
                      :type="
                        calcProgressColor(
                          props.row.estimateSeconds,
                          props.row.spentSeconds
                        )
                      "
                      :value="
                        calcProgressValue(
                          props.row.estimateSeconds,
                          props.row.spentSeconds
                        )
                      "
                      class="less-rounded-progress-bar"
                      size="is-medium"
                    >
                      <p
                        v-if="
                          isNaN(
                            calcProgressValue(
                              props.row.estimateSeconds,
                              props.row.spentSeconds
                            )
                          )
                        "
                      >
                        N/A
                      </p>
                      <p v-else>
                        -{{
                          secondToShortWorkDayFormat(
                            Math.abs(
                              props.row.estimateSeconds - props.row.spentSeconds
                            )
                          )
                        }}
                      </p>
                    </b-progress>
                  </div>
                  <div
                    class="pl-0 pr-0 is-flex is-justify-content-center is-align-items-center is-percent-container"
                  >
                    <div>
                      <template
                        v-if="
                          isNaN(
                            calcProgressValue(
                              props.row.estimateSeconds,
                              props.row.spentSeconds
                            )
                          )
                        "
                      >
                        <b-icon class="mr-2" icon="error" />
                      </template>
                      <template v-else>
                        ({{
                          calcProgressValue(
                            props.row.estimateSeconds,
                            props.row.spentSeconds
                          )
                        }}%)
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </b-table-column>

            <b-table-column
              v-slot="props"
              :label="$tf('projectPage.tasks.list.table.spent|Ráfodítás')"
              field="spentSeconds"
              sortable
            >
              {{ convertSeconds(props.row.spentSeconds) }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              :label="$tf('projectPage.tasks.list.table.estimation|Becslés')"
              field="estimateSeconds"
              sortable
            >
              {{ convertSeconds(props.row.estimateSeconds) }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              :label="
                $tf('projectPage.tasks.list.table.resolved|Lezárás dátuma')
              "
              field="resolved"
              sortable
            >
              {{
                props.row.resolved
                  ? formatDate(new Date(props.row.resolved))
                  : "-"
              }}
            </b-table-column>

            <b-table-column
              v-if="isSelfTrackingMode"
              v-slot="props"
              :label="$tf('projectPage.tasks.list.table.actions|Műveletek')"
              field="actions"
              width="80"
            >
              <b-tooltip
                :label="
                  $tf(
                    'projectPage.tasks.list.table.actions.edit|Jegy szerkesztése'
                  )
                "
              >
                <clickable-icon
                  icon="wrench"
                  type="is-black"
                  @click="editTask(props.row)"
                ></clickable-icon>
              </b-tooltip>
              <b-tooltip
                :label="
                  $tf(
                    'projectPage.tasks.list.table.actions.delete|Jegy törlése'
                  )
                "
              >
                <clickable-icon
                  icon="trash"
                  type="is-danger"
                  @click="deleteTask(props.row.id)"
                ></clickable-icon>
              </b-tooltip>
            </b-table-column>

            <template #detail="props">
              <div class="">
                <div class="is-flex has-gap">
                  <div class="tig-info-section mb-4">
                    <div class="tig-info-header has-font-weight-500 mb-2">
                      <p>
                        {{
                          $tf("projectPage.tasks.list.table.details|Részletek")
                        }}
                      </p>
                    </div>
                    <div class="is-flex tig-info-content">
                      <tig-detail-box
                        :header="
                          $tf('projectPage.tasks.list.table.details.key|Kulcs')
                        "
                        :text="props.row.key"
                        link
                      />
                      <tig-detail-box
                        :header="
                          $tf(
                            'projectPage.tasks.list.table.details.tig|Mérföldkő'
                          )
                        "
                        :text="pasteTigInfo(props.row.tigId)"
                        link
                      />
                      <tig-detail-box
                        :header="
                          $tf(
                            'projectPage.tasks.list.table.details.summary|Összegzés'
                          )
                        "
                        :text="props.row.name"
                      />
                    </div>
                  </div>

                  <div class="tig-info-section mb-4">
                    <div class="tig-info-header has-font-weight-500 mb-2">
                      <p>
                        {{
                          $tf("projectPage.tasks.list.table.edited|Szerkesztve")
                        }}
                      </p>
                    </div>
                    <div class="is-flex tig-info-content">
                      <tig-detail-box
                        :header="
                          $tf(
                            'projectPage.tasks.list.table.edited.created|Létrehozva'
                          )
                        "
                        :text="formatDateTime(props.row.created)"
                      />
                      <tig-detail-box
                        :header="
                          $tf(
                            'projectPage.tasks.list.table.edited.lastModified|Lezárás időpontja'
                          )
                        "
                        :text="formatDateTime(props.row.resolved)"
                      />
                    </div>
                  </div>
                </div>

                <div class="is-flex has-gap">
                  <div class="tig-info-section mb-4">
                    <div class="tig-info-header has-font-weight-500 mb-2">
                      <p>
                        {{
                          $tf("projectPage.tasks.list.table.time|Időráfordítás")
                        }}
                      </p>
                    </div>
                    <div class="is-flex tig-info-content">
                      <tig-detail-box
                        :header="
                          $tf(
                            'projectPage.tasks.list.table.time.estimation|Becslés'
                          )
                        "
                        :text="secondToWorkDayFormat(props.row.estimateSeconds)"
                      />
                      <tig-detail-box
                        :header="
                          $tf(
                            'projectPage.tasks.list.table.time.spent|Ráfordítás'
                          )
                        "
                        :text="secondToWorkDayFormat(props.row.spentSeconds)"
                      />
                    </div>
                  </div>

                  <div class="tig-info-section mb-4">
                    <div class="tig-info-header has-font-weight-500 mb-2">
                      <p>
                        {{ $tf("projectPage.tasks.list.table.tags|Tagek") }}
                      </p>
                    </div>
                    <div class="is-flex tig-info-content">
                      <b-field>
                        <b-tag
                          v-if="props.row.status === 'TODO'"
                          size="is-large"
                          type="is-dark"
                          >{{
                            $tf(
                              "projectPage.tasks.list.table.tags.todo|Tennivaló"
                            )
                          }}
                        </b-tag>
                        <b-tag
                          v-if="props.row.status === 'IN_PROGRESS'"
                          size="is-large"
                          type="is-info"
                          >{{
                            $tf(
                              "projectPage.tasks.list.table.tags.inprogress|Folyamatban"
                            )
                          }}
                        </b-tag>
                        <b-tag
                          v-if="props.row.status === 'DONE'"
                          size="is-large"
                          type="is-success"
                          >{{
                            $tf("projectPage.tasks.list.table.tags.done|Kész")
                          }}
                        </b-tag>
                      </b-field>
                      <b-field>
                        <b-tag
                          v-if="props.row.type === 'DEV'"
                          size="is-large"
                          type="is-primary"
                          >{{
                            $tf(
                              "projectPage.tasks.list.table.tags.development|Fejlesztés"
                            )
                          }}
                        </b-tag>
                        <b-tag
                          v-if="props.row.type === 'OTHER'"
                          size="is-large"
                          type="is-dark"
                          >{{
                            $tf("projectPage.tasks.list.table.tags.misc|Egyéb")
                          }}
                        </b-tag>
                        <b-tag
                          v-if="props.row.type === 'GENERAL'"
                          size="is-large"
                          type="is-light"
                          >{{
                            $tf(
                              "projectPage.tasks.list.table.tags.general|Általános"
                            )
                          }}
                        </b-tag>
                        <b-tag
                          v-if="props.row.type === 'BUG'"
                          size="is-large"
                          type="is-danger"
                          >{{
                            $tf("projectPage.tasks.list.table.tags.bug|Hiba")
                          }}
                        </b-tag>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="tig-info-section mb-4">
                  <project-collapse
                    id="issue-details-collapse"
                    :header="$tf('projectPage.tasks.list.worklogs|Munkanaplók')"
                  >
                    <b-table
                      :data="!!props.row.worklogs ? props.row.worklogs : []"
                    >
                      <b-table-column
                        v-slot="props"
                        :label="
                          $tf(
                            'projectPage.tasks.list.worklogs.table.date|Dátum'
                          )
                        "
                        field="day"
                        sortable
                      >
                        {{ props.row.day }}
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        :label="
                          $tf('projectPage.tasks.list.worklogs.table.name|Név')
                        "
                        field="author"
                        searchable
                        sortable
                      >
                        {{ props.row.author }}
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        :label="
                          $tf('projectPage.tasks.list.worklogs.table.time|Idő')
                        "
                        field="seconds"
                        sortable
                      >
                        {{ secondToWorkDayFormat(props.row.seconds) }}
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        :label="
                          $tf(
                            'projectPage.tasks.list.worklogs.table.comment|Megjegyzés'
                          )
                        "
                        centered
                        field="comment"
                      >
                        {{ props.row.comment || "Nincs megjegyzés" }}
                      </b-table-column>
                    </b-table>
                  </project-collapse>
                </div>
              </div>
            </template>
            <template #empty>
              <p>
                {{ $tf("projectPage.tasks.list.empty|Nem találhatók jegyek") }}
              </p>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  createTicketUrl,
  formatDate,
  formatDateTime,
  openDeleteConfirm,
  secondToShortWorkDayFormat,
  secondToWorkDayFormat,
} from "@/utils/util";
import TigDetailBox from "@/components/enterprise/project/module/TigDetailBox";
import ClickableIcon from "../../../module/icon/ClickableIcon";
import ProjectCollapse from "../module/ProjectCollapse";
import IssueExportModal from "../../export/IssueExportModal";
import { ISSUE_TRACKING_SYSTEM } from "@/utils/const";
import TaskForm from "@/components/enterprise/project/form/TaskForm.vue";

const localStorageKey = (key) => {
  return `loginet_internal_issuecolumn_${key}`;
};
const convertSeconds = (seconds) => {
  if (!seconds) {
    return 0;
  }
  let inMinutes = Math.floor(seconds / 60);
  let hours = Math.floor(inMinutes / 60);
  let minutes = inMinutes % 60;
  return `${hours ? hours + "h" : ""}${minutes ? minutes + "m" : ""}`;
};

export default {
  name: "IssueDetails",
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    issues: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formatDate,
      formatDateTime,
      convertSeconds,
      secondToWorkDayFormat,
      secondToShortWorkDayFormat,
      commentModalVisible: false,
      taskModalOpen: false,
      commentModalText: "",
      taskEdit: null,
      issueStatuses: {},
    };
  },
  components: {
    TaskForm,
    ProjectCollapse,
    ClickableIcon,
    TigDetailBox,
  },
  watch: {
    issues(val) {
      this.issueStatuses = {};
      val.forEach((issue) => (this.issueStatuses[issue.id] = issue.status));
    },
  },
  computed: {
    isSelfTrackingMode() {
      return this.issueTrackingSystem === ISSUE_TRACKING_SYSTEM.SELF.enum;
    },
    filteredIssues() {
      let issues = this.issues;
      issues = issues.sort((fe, se) => {
        if (se.status === fe.status) {
          return new Date(se.created) - new Date(fe.created);
        } else {
          return fe.status.localeCompare(se.status);
        }
      });
      return issues;
    },
    groupedIssues() {
      return this.filteredIssues.reduce((groups, issue) => {
        const key = issue.tigId;
        if (!groups[key]) {
          groups[key] = [];
        }
        groups[key].push(issue);
        return groups;
      }, {});
    },
    changedStatuses() {
      let issues = this.issues;
      return issues
        .filter((issue) => this.issueStatuses[issue.id] !== issue.status)
        .map((issue) => {
          return {
            id: issue.id,
            status: this.issueStatuses[issue.id],
          };
        });
    },
    ...mapGetters({
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
  },
  methods: {
    createTicketUrl,
    shortenKey(key) {
      return key.substr(key.indexOf("-") + 1);
    },
    pasteTigInfo(id) {
      let tig = this.projectDetails.tigDetails.filter(
        (tig) => tig.id === id
      )[0];
      return `${tig?.name} (${tig?.identifier})`;
    },
    calcProgressValue(estimate, spent) {
      if (!estimate) return NaN;
      if (!spent) return 0;
      return Math.round((spent / estimate) * 100);
    },
    calcProgressColor(estimate, spent) {
      if (!estimate) estimate = 0;
      if (!spent) spent = 0;
      return estimate - spent >= 0 ? "is-success" : "is-danger";
    },
    changeColumns(name) {
      localStorage.setItem(localStorageKey(name), this[name]);
    },
    determineDefault(column, def) {
      return localStorage.getItem(localStorageKey(column)) === null
        ? def
        : localStorage.getItem(localStorageKey(column)) === "true";
    },
    getStatusObject(status) {
      let imageName = "";
      let text = "";
      let type = "";
      switch (status) {
        case "DONE": {
          imageName = "project-check.svg";
          text = this.$tf("projectPage.tasks.list.status.done|KÉSZ");
          type = "is-success";
          break;
        }
        case "IN_PROGRESS": {
          imageName = "project-load.svg";
          text = this.$tf(
            "projectPage.tasks.list.status.inProgress|FOLYAMATBAN"
          );
          type = "is-info";
          break;
        }
        default:
          imageName = "project-question.svg";
          text = this.$tf("projectPage.tasks.list.status.undone|ELVÉGZETLEN");
          type = "is-warning";
          break;
      }
      const image = require(`@/assets/images/project/${imageName}`);
      return {
        text,
        image: `<img src="${image}" alt="${imageName}" style="min-width: 14px; max-width: 20px;" />`,
        type,
      };
    },
    async onExportClick() {
      this.$buefy.modal.open({
        parent: this,
        component: IssueExportModal,
        props: {
          projectKey: this.projectDetails.id,
          tigKey: "sanyi", // todo fixme :)
        },
        hasModalCard: true,
        trapFocus: true,
      });
    },
    editTask(issue) {
      this.taskEdit = issue;
      this.taskModalOpen = true;
    },
    async deleteTask(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("enterprise_core/deleteIssue", id);
        await this.$store.dispatch(
          "enterprise_core/fetchIssues",
          this.projectDetails.id
        );
      });
    },
    async handleTaskSubmit() {
      this.taskModalOpen = false;
      await this.$store.dispatch(
        "enterprise_core/fetchIssues",
        this.projectDetails.id
      );
    },
    async updateIssueStatus() {
      await this.$store.dispatch(
        "enterprise_core/updateIssueStatus",
        this.changedStatuses
      );
      await this.$store.dispatch(
        "enterprise_core/fetchIssues",
        this.projectDetails.id
      );
    },
  },
  mounted() {
    this.issues.forEach(
      (issue) => (this.issueStatuses[issue.id] = issue.status)
    );
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.less-rounded-progress-bar {
  .progress {
    border-radius: 4px !important;
  }
}

.progress-column {
  .th-wrap {
    width: 400px !important;
  }
}

.bigger-table-margins {
  .th-wrap {
    margin-right: 50px !important;
  }
}
</style>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.column-header {
  width: 100px;
}

.is-percent-container {
  width: 30px;
}

.width-140 {
  width: 140px;
}

.width-50 {
  width: 50px;
}

.flipped-table-container {
  transform: rotateX(180deg);
  overflow-y: auto;

  .flipped-table-content {
    transform: rotateX(180deg);
  }
}

.tig-details {
  width: 60%;
}

.tig-info-section {
  .tig-info-header {
    color: $custom-dark-blue;
  }

  .tig-info-content {
    gap: 32px;
  }

  button {
    box-shadow: 0 5px 30px rgba(48, 52, 62, 0.1);
    border-radius: 8px;
    width: 100px;
    height: 51px;
  }
}

.has-gap {
  gap: 40px;
}

/* SCROLLBAR */
::-webkit-scrollbar {
  height: 8px;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: $grey-lighter;
}

::-webkit-scrollbar-thumb:hover {
  background: $custom-grayer-blue;
}

::-webkit-scrollbar-track {
  background: $grey-lightest;
}
</style>
