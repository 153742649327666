<template>
  <section class="section">
    <div class="container" v-if="!isPageLoading">
      <div class="is-flex is-justify-content-space-between">
        <h1 class="title">
          {{ $tf("projectDimensions.title|Projekt dimenziók") }}
        </h1>
        <b-button
          icon-left="diagram-project"
          type="is-primary"
          @click="$router.push('/settings/project-dimensions/assign')"
        >
          {{ $tf("projectDimensions.assigner|Projekt-dimenzió hozzárendelés") }}
        </b-button>
      </div>
      <b-tabs
        v-if="projectDimensions"
        v-model="bigTab"
        type="is-toggle"
        expanded
        class="tab-without-padding"
      >
        <b-tab-item
          :label="$tf('projectDimensions.tabs.color|Szín')"
          key="color"
        >
          <div class="is-flex">
            <b-switch v-model="showClosed">{{
              $tf(
                "projectDimensions.colors.showClosedProjects|Lezárt projektek mutatása"
              )
            }}</b-switch>
            <b-button
              type="is-info"
              class="ml-auto mb-5"
              @click="openEditColorPopup()"
              ><b-icon icon="plus-circle" class="mr-2" />
              {{ $tf("projectDimensions.color.add|Új szín felvétele") }}
            </b-button>
          </div>

          <b-table
            :data="projectColors"
            ref="tableColor"
            striped
            hoverable
            paginated
            per-page="5"
            detailed
            detail-key="id"
            :show-detail-icon="true"
            :opened-detailed="openRows"
            @details-open="(row) => closeAllOtherDetails(row)"
            aria-next-label="Következő lap"
            aria-previous-label="Előző lap"
            aria-page-label="Lap"
            aria-current-label="Aktuális lap"
          >
            <b-table-column
              field="name"
              :label="$tf('projectDimensions.table.name|Név')"
              v-slot="props"
              sortable
            >
              <b-field grouped group-multiline>
                <b-field>
                  <div class="is-flex">
                    <div
                      :style="{ backgroundColor: props.row.colorInHex }"
                      class="mt-1 mr-1 color-box"
                    />
                    <div class="has-font-weight-600">
                      {{ props.row.colorName }}
                    </div>
                  </div>
                </b-field>
              </b-field>
            </b-table-column>

            <b-table-column
              :label="
                $tf('projectDimensions.table.projectCount|Projektek száma')
              "
              field="projects.length"
              v-slot="props"
              sortable
            >
              <b-field grouped group-multiline>
                <b-field>
                  {{ props.row.projects.length }}
                </b-field>
              </b-field>
            </b-table-column>

            <b-table-column
              field="actions"
              :label="$tf('projectDimensions.table.actions|Műveletek')"
              cell-class="do-not-wrap has-text-right"
              header-class="has-text-right is-flex is-justify-content-end"
              v-slot="props"
            >
              <clickable-icon
                icon="pencil"
                type="is-info"
                class="mr-2"
                @click="openEditColorPopup(props.row)"
              ></clickable-icon>
              <clickable-icon
                icon="trash"
                type="is-danger"
                @click="deleteColor(props.row)"
              ></clickable-icon>
            </b-table-column>

            <template #empty>
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p><b-icon icon="frown" size="is-large"></b-icon></p>
                  <p>
                    {{
                      $tf(
                        "projectDimensions.table.colors.empty|Még nincsenek felvéve színek."
                      )
                    }}
                  </p>
                </div>
              </section>
            </template>

            <template #detail="props">
              <div class="container content">
                <div v-if="props.row.projects && props.row.projects.length > 0">
                  <p>
                    <strong>
                      {{
                        $tf(
                          "projectDimensions.table.details.projects|Projektek:"
                        )
                      }}
                    </strong>
                  </p>
                  <p
                    v-for="project in props.row.projects"
                    :key="project.identifier"
                  >
                    <router-link
                      :to="getProjectPage(project.type, project.identifier)"
                    >
                      {{ project.name }}
                    </router-link>
                  </p>
                </div>
                <div v-else>
                  {{
                    $tf(
                      "projectDimensions.table.details.projects.empty|Még nem tartoznak projektek ehhez a színhez!"
                    )
                  }}
                </div>
              </div>
            </template>
          </b-table>
        </b-tab-item>
        <template v-for="(dimension, index) in projectDimensions" :key="index">
          <b-tab-item :label="dimension.name">
            <h2 class="subtitle">
              {{
                $tf(
                  "projectDimensions.dimensionSettings.title|Dimenzió beállításai"
                )
              }}
            </h2>
            <b-field grouped>
              <b-field
                :label="
                  $tf('projectDimensions.dimensionSettings.name|Elnevezés')
                "
                label-position="on-border"
              >
                <b-input
                  :placeholder="
                    $tf(
                      'projectDimensions.dimensionSettings.name.placeholder|Dimenzió neve'
                    )
                  "
                  icon="list"
                  v-model="dimension.name"
                />
              </b-field>

              <b-field>
                <b-switch
                  class="mt-2"
                  v-model="dimension.active"
                  type="is-success"
                >
                  {{ $tf("projectDimensions.dimensionSettings.active|Aktív") }}
                </b-switch>
              </b-field>

              <b-field>
                <b-switch
                  class="mt-2"
                  v-model="dimension.multipleAssignment"
                  type="is-success"
                  :disabled="
                    hasProjectWithMultipleAssignment(projectDimensionValues)
                  "
                >
                  {{
                    $tf(
                      "projectDimensions.dimensionSettings.multipleAssigment|Egy projektre több is beállítható"
                    )
                  }}
                </b-switch>
              </b-field>

              <b-field class="ml-auto">
                <b-button
                  type="is-info"
                  icon-left="save"
                  class="mr-2"
                  @click="editDimension"
                  >{{
                    $tf("projectDimensions.dimensionSettings.save|Mentés")
                  }}</b-button
                >
                <b-button
                  type="is-danger"
                  icon-left="trash-alt"
                  @click="confirmDimensionDelete"
                  >{{
                    $tf("projectDimensions.dimensionSettings.delete|Törlés")
                  }}</b-button
                >
              </b-field>
            </b-field>

            <hr />

            <div class="is-flex">
              <b-switch v-model="showClosed">{{
                $tf(
                  "projectDimensions.showClosedProjects|Lezárt projektek mutatása"
                )
              }}</b-switch>
              <b-button
                type="is-info"
                class="ml-auto mb-5"
                @click="openEditPopup()"
                ><b-icon icon="plus-circle" class="mr-2" />
                {{
                  $tf("projectDimensions.addNewValue|Új lehetőség felvétele")
                }}
              </b-button>
            </div>

            <b-table
              :data="projectDimensionValues"
              ref="table"
              striped
              hoverable
              paginated
              per-page="5"
              detailed
              detail-key="id"
              :show-detail-icon="true"
              :opened-detailed="openRows"
              @details-open="(row) => closeAllOtherDetails(row)"
              aria-next-label="Következő lap"
              aria-previous-label="Előző lap"
              aria-page-label="Lap"
              aria-current-label="Aktuális lap"
            >
              <b-table-column
                field="name"
                :label="$tf('projectDimensions.valuesTable.name|Név')"
                v-slot="props"
                sortable
              >
                <b-field grouped group-multiline>
                  <b-field>
                    {{ props.row.name }}
                  </b-field>
                </b-field>
              </b-table-column>

              <b-table-column
                :label="
                  $tf(
                    'projectDimensions.valuesTable.projectCount|Projektek száma'
                  )
                "
                field="projects.length"
                v-slot="props"
                sortable
                numeric
              >
                <b-field grouped group-multiline>
                  <b-field>
                    {{ props.row.projects.length }}
                  </b-field>
                </b-field>
              </b-table-column>

              <b-table-column
                field="actions"
                :label="$tf('projectDimensions.valuesTable.actions|Műveletek')"
                cell-class="do-not-wrap has-text-right"
                header-class="has-text-right is-flex is-justify-content-end"
                v-slot="props"
              >
                <clickable-icon
                  icon="pencil"
                  type="is-info"
                  class="mr-2"
                  @click="openEditPopup(props.row)"
                ></clickable-icon>
                <clickable-icon
                  icon="trash"
                  type="is-danger"
                  @click="deleteDimensionValue(props.row)"
                ></clickable-icon>
              </b-table-column>

              <template #empty>
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p><b-icon icon="frown" size="is-large"></b-icon></p>
                    <p>
                      {{
                        $tf(
                          "projectDimensions.valuesTable.empty|Még nem tartoznak értékek ehhez a dimenzióhoz."
                        )
                      }}
                    </p>
                  </div>
                </section>
              </template>

              <template #detail="props">
                <div class="container content">
                  <div
                    v-if="props.row.projects && props.row.projects.length > 0"
                  >
                    <p>
                      <strong>
                        {{
                          $tf(
                            "projectDimensions.valuesTable.details.projects|Projektek:"
                          )
                        }}
                      </strong>
                    </p>
                    <p
                      v-for="project in props.row.projects"
                      :key="project.identifier"
                    >
                      <router-link
                        :to="getProjectPage(project.type, project.identifier)"
                      >
                        {{ project.name }}
                      </router-link>
                    </p>
                  </div>
                  <div v-else>
                    {{
                      $tf(
                        "projectDimensions.valuesTable.details.projects.empty|Még nem tartoznak projektek ehhez a dimenzió értékhez!"
                      )
                    }}
                  </div>
                </div>
              </template>
            </b-table>
          </b-tab-item>
        </template>

        <b-tab-item
          v-if="projectDimensions.length < 5"
          :key="`add-new-${projectDimensions.length}`"
        >
          <template #header>
            <b-icon class="mt-1" icon="plus-circle" />
          </template>

          <h2 class="subtitle">
            {{
              $tf(
                "projectDimensions.dimensionSettings.title|Dimenzió beállításai"
              )
            }}
          </h2>
          <b-field grouped>
            <b-field
              :label="$tf('projectDimensions.dimensionSettings.name|Elnevezés')"
              label-position="on-border"
            >
              <b-input
                :placeholder="
                  $tf(
                    'projectDimensions.dimensionSettings.name.placeholder|Dimenzió neve'
                  )
                "
                icon="closed-captioning"
                v-model="dimensionForm.name"
              />
            </b-field>

            <b-field>
              <b-switch
                class="mt-2"
                v-model="dimensionForm.active"
                type="is-success"
              >
                {{ $tf("projectDimensions.dimensionSettings.active|Aktív") }}
              </b-switch>
            </b-field>

            <b-field>
              <b-switch
                class="mt-2"
                v-model="dimensionForm.multipleAssignment"
                type="is-success"
              >
                {{
                  $tf(
                    "projectDimensions.dimensionSettings.multipleAssigment|Egy projektre több is beállítható"
                  )
                }}
              </b-switch>
            </b-field>

            <b-field class="ml-auto">
              <b-button
                type="is-info"
                icon-left="save"
                class="mr-2"
                @click="createDimension"
                >{{
                  $tf(
                    "projectDimensions.dimensionSettings.create|Dimenzió létrehozása"
                  )
                }}</b-button
              >
            </b-field>
          </b-field>
        </b-tab-item>
      </b-tabs>
    </div>
    <div v-else>
      <b-loading :active="isPageLoading" :is-full-page="false"></b-loading>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ProjectDimensionValueForm from "./ProjectDimensionValueForm.vue";
import ProjectColorForm from "./ProjectColorForm.vue";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import LoadingMixin from "@/mixins/LoadingMixin";
import { nextTick } from "vue";
import { getProjectPage } from "@/utils/util";

export default {
  name: "ProjectDimensions",
  data() {
    return {
      dimensionForm: { name: null, active: false, multipleAssignment: false },
      selectedDimension: null,
      bigTab: 0,
      openRows: [],
      showClosed: true,
    };
  },
  components: { ClickableIcon },
  mixins: [LoadingMixin],
  computed: {
    ...mapGetters({
      projectDimensionValues: "project_dimensions/getProjectDimensionValues",
      projectDimensions: "project_dimensions/getProjectDimensions",
      projectColors: "project_dimensions/getProjectColors",
    }),
  },
  watch: {
    showClosed() {
      if (this.bigTab > 0) {
        this.fetchList(false, this.projectDimensions[this.bigTab - 1].id);
      }
    },
    async bigTab() {
      if (this.bigTab > 0) {
        this.selectedDimension = this.projectDimensions[this.bigTab - 1];
        if (this.selectedDimension) {
          await this.fetchList(false, this.selectedDimension.id);
        }
      }
    },
  },
  methods: {
    getProjectPage,
    hasProjectWithMultipleAssignment(dimensionValues) {
      const projectsIds = new Set();

      for (const dimensionValue of dimensionValues) {
        for (const project of dimensionValue.projects) {
          if (projectsIds.has(project.id)) {
            return true;
          }
          projectsIds.add(project.id);
        }
      }
      return false;
    },
    async createDimension() {
      this.$store
        .dispatch("project_dimensions/createDimension", this.dimensionForm)
        .then(async () => {
          this.bigTab = 0;
          await this.fetchProjectDimensions(true);
        });
    },
    editDimension() {
      this.$store
        .dispatch("project_dimensions/updateDimension", this.selectedDimension)
        .then(() => {
          this.fetchProjectDimensions(true);
        });
    },
    confirmDimensionDelete() {
      this.$buefy.dialog.confirm({
        type: "is-danger",
        hasIcon: true,
        title: "Dimenzió törlése",
        message:
          "Biztosan törölni szeretnéd a dimenziót? Minden beállított érték elveszik!",
        cancelText: "Mégsem",
        confirmText: "Törlés",
        onConfirm: () => this.deleteDimension(),
      });
    },

    async deleteDimension() {
      await this.$store
        .dispatch(
          "project_dimensions/deleteDimension",
          this.selectedDimension.id
        )
        .then(() => {
          this.bigTab = 0;
          this.fetchProjectDimensions();
        });
    },
    closeAllOtherDetails(row) {
      nextTick(() => {
        this.openRows = [row.id];
      });
    },
    openEditPopup(dimensionValue) {
      this.$buefy.modal.open({
        parent: this,
        component: ProjectDimensionValueForm,
        props: {
          dimensionValue: dimensionValue,
          dimensionId: this.projectDimensions[this.bigTab - 1].id,
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          "after-save": () =>
            this.fetchList(true, this.projectDimensions[this.bigTab - 1].id),
        },
      });
    },
    deleteDimensionValue(dimensionValue) {
      let message = this.$tf(
        "projectDimensions.dimensionValueDeleteModal.message|Biztosan törölni akarod?"
      );
      if (dimensionValue.projects && dimensionValue.projects.length > 0) {
        message +=
          " " +
          this.$tf(
            "projectDimensions.dimensionValueDeleteModal.message.hasProjects|A dimenzió értékhez tartoznak projektek!"
          );
      }
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "projectDimensions.dimensionValueDeleteModal.title|Törlés megerősítés"
        ),
        message: message,
        cancelText: this.$tf(
          "projectDimensions.dimensionValueDeleteModal.cancel|Mégse"
        ),
        confirmText: this.$tf(
          "projectDimensions.dimensionValueDeleteModal.save|Törlés"
        ),
        type: "is-danger",
        onConfirm: async () => {
          await this.$store.dispatch(
            "project_dimensions/deleteDimensionValue",
            dimensionValue.id
          );
          await this.fetchList(
            true,
            this.projectDimensions[this.bigTab - 1].id
          );
        },
      });
    },
    deleteColor(color) {
      let message = this.$tf(
        "projectDimensions.colorValueDeleteModal.message|Biztosan törölni akarod?"
      );
      if (color.projects && color.projects.length > 0) {
        message +=
          " " +
          this.$tf(
            "projectDimensions.colorValueDeleteModal.message.hasProjects|A színhez tartoznak projektek!"
          );
      }
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "projectDimensions.colorValueDeleteModal.title|Törlés megerősítés"
        ),
        message: message,
        cancelText: this.$tf(
          "projectDimensions.colorValueDeleteModal.cancel|Mégse"
        ),
        confirmText: this.$tf(
          "projectDimensions.colorValueDeleteModal.confirm|Törlés"
        ),
        type: "is-danger",
        onConfirm: async () => {
          await this.$store.dispatch(
            "project_dimensions/deleteColor",
            color.id
          );
          await this.fetchProjectColors(true);
        },
      });
    },
    openEditColorPopup(color) {
      this.$buefy.modal.open({
        parent: this,
        component: ProjectColorForm,
        props: {
          color: color,
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          "after-save": () => this.fetchProjectColors(true),
        },
      });
    },
    async fetchList(force, id) {
      let params = new URLSearchParams();
      if (this.showClosed) {
        params.append("hideClosed", false);
      }
      await this.$store.dispatch(
        "project_dimensions/fetchProjectDimensionValues",
        {
          params: { params: params },
          dimensionId: id,
          force,
        }
      );
    },
    async fetchProjectDimensions(force) {
      this.dimensionForm = {
        name: null,
        active: false,
        multipleAssignment: false,
      };
      await this.$store.dispatch(
        "project_dimensions/fetchAllProjectDimensions",
        {
          force,
        }
      );
    },
    async fetchProjectColors(force) {
      await this.$store.dispatch("project_dimensions/fetchProjectColors", {
        force,
      });
    },
  },
  async mounted() {
    this.doStartLoading();
    await this.fetchProjectColors();
    await this.fetchProjectDimensions();
    this.doFinishLoading();
    // await this.fetchList(false, this.projectDimensions[this.bigTab].id);
    // this.selectedDimension = this.projectDimensions[this.bigTab];
  },
};
</script>

<style>
.tab-without-padding {
  .tab-content {
    padding: 0 !important;
    padding-top: 1rem !important;
  }
}

.color-box {
  width: 18px;
  height: 18px;
}
</style>
