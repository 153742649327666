<template>
  <section>
    <div class="container">
      <b-field label="Iroda" label-position="on-border">
        <b-select
          v-model="selectedSite"
          class="mr-2"
          placeholder="Telephely kiválasztása"
          @update:modelValue="selectSite"
        >
          <option
            v-for="site in sites"
            :key="site.identifier"
            :value="site.identifier"
          >
            {{ site.identifier }}
          </option>
        </b-select>
        <b-field
          :hidden="this.selectedSite === null"
          class="mr-2"
          label="Emelet neve"
          label-position="on-border"
        >
          <div>
            <b-input
              v-if="floors[currentFloor]"
              v-model="floors[currentFloor].name"
              :disabled="this.isReadOnly"
              placeholder="Adj meg egy nevet az emeletnek..."
            />
          </div>
        </b-field>
        <div :hidden="!isReadOnly">
          <f-datepicker
            v-model="selectedDate"
            :disabled="selectedSite === null"
            :icon-right="selectedDate ? 'close-circle' : ''"
            icon="calendar-day"
            icon-right-clickable
            placeholder="Dátum kiválasztása..."
            @icon-right-click="selectedDate = null"
          >
          </f-datepicker>
        </div>
      </b-field>
      <div :hidden="this.selectedSite === null" class="content">
        <b-field>
          <template v-if="!isReadOnly">
            <b-field
              class="canvas-controls canvas-controls-on-top"
              label="Elhelyezés"
              label-position="on-border"
            >
              <b-radio v-model="radio" native-value="room"> Terem</b-radio>
              <div :hidden="this.radio !== 'room'">
                <b-field class="mr-2">
                  <b-select
                    v-model="selectedRoom"
                    :disabled="rooms.length === 0"
                    class="mr-2"
                    placeholder="Terem kiválasztása"
                    @update:modelValue="(room) => (this.selectedRoom = room)"
                  >
                    <option
                      v-for="room in rooms"
                      :key="room.identifier"
                      :value="room.identifier"
                    >
                      {{ room.identifier }}
                    </option>
                  </b-select>
                  <b-button
                    :disabled="
                      !this.isRoomAlreadyDrawn({ name: this.selectedRoom })
                    "
                    type="is-danger"
                    @click="deleteSelectedRoom"
                    >Törlés
                  </b-button>
                </b-field>
              </div>
              <b-radio v-model="radio" native-value="desk"> Asztal</b-radio>
              <div
                :hidden="this.radio !== 'desk' || this.isReadOnly"
                class="mr-2"
              >
                <b-button
                  :disabled="this.selectedDesk === null"
                  type="is-danger"
                  @click="deleteSelectedDesk"
                  >Törlés
                </b-button>
              </div>

              <b-radio v-model="radio" native-value="seat">
                Munkaállomás
              </b-radio>
              <div :hidden="this.radio !== 'seat'">
                <b-select
                  v-model="selectedRoom"
                  :disabled="rooms.length === 0"
                  class="mr-2"
                  placeholder="Terem kiválasztása"
                  @update:modelValue="selectRoom"
                >
                  <option
                    v-for="room in rooms"
                    :key="room.identifier"
                    :value="room.identifier"
                  >
                    {{ room.identifier }}
                  </option>
                </b-select>
              </div>
              <div
                :hidden="this.radio !== 'seat' || this.selectedRoom === null"
              >
                <b-field class="mr-2">
                  <b-select
                    v-model="selectedSeat"
                    class="mr-2"
                    placeholder="Munkaállomás kiválasztása"
                    @update:modelValue="(seat) => this.selectSeat(seat)"
                  >
                    <option
                      v-for="seat in seats"
                      :key="seat.identifier"
                      :value="seat.identifier"
                    >
                      {{ seat.identifier }}
                    </option>
                  </b-select>
                  <b-button
                    :disabled="
                      !this.isSeatAlreadyDrawn({ name: this.selectedSeat })
                    "
                    type="is-danger"
                    @click="deleteSelectedSeat"
                    >Törlés
                  </b-button>
                </b-field>
              </div>
            </b-field>
          </template>
        </b-field>

        <b-field>
          <canvas
            id="canvas"
            height="500"
            width="1000"
            @click="(e) => click(e, false)"
            @mousedown="(e) => pan(e)"
            @mouseleave="clearCanvas"
            @mousemove="(e) => click(e, true)"
            @mouseup="panning = false"
            @wheel="(e) => zoom(e)"
          />
          <div class="container canvas-overlay">
            <b-field>
              <b-button
                v-if="currentFloor === floors.length - 1 || currentFloor === -1"
                :disabled="this.isReadOnly"
                @click="addNewFloor"
              >
                <b-icon icon="plus" />
              </b-button>
              <b-button v-else @click="goUp">
                <b-icon icon="arrow-up" />
              </b-button>
            </b-field>
            <b-field>
              <p style="font-size: 128px">{{ currentFloor }}</p>
            </b-field>
            <b-field>
              <b-button :disabled="currentFloor === 0" @click="goDown">
                <b-icon icon="arrow-down" />
              </b-button>
            </b-field>
            <div class="canvas-controls">
              <b-field class="canvas-controls-option">
                <b-radio v-model="radio" native-value="panning">
                  Mozgatás
                </b-radio>
              </b-field>
              <b-field class="canvas-controls-option">
                <b-radio v-model="radio" native-value="choose">
                  Kiválasztás
                </b-radio>
              </b-field>
              <b-field class="canvas-controls-option">
                <b-button type="is-info is-light" @click="recenter">
                  Középre
                </b-button>
              </b-field>
            </div>
          </div>
        </b-field>
      </div>

      <b-field :hidden="this.isReadOnly">
        <b-button
          :disabled="this.selectedSite === null"
          class="mr-2"
          icon-left="save"
          type="is-primary"
          @click="saveRemote"
        >
          Alaprajz elmentése a szerverre
        </b-button>

        <b-field style="margin-left: auto">
          <b-button
            :disabled="this.selectedSite === null"
            class="mr-2"
            icon-left="download"
            type="is-info is-dark"
            @click="saveToDrive"
          >
            Alaprajz letöltése (.json)
          </b-button>

          <b-field class="file is-info is-light">
            <b-upload
              v-model="file"
              accept=".json"
              class="file-label"
              required
              validation-message="Válassz ki egy .json fájlt!"
              @update:modelValue="load()"
            >
              <span class="file-cta seating-upload-button">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Alaprajz betöltése (.json)</span>
              </span>
            </b-upload>
          </b-field>
        </b-field>
      </b-field>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingMixin from "@/mixins/LoadingMixin";
import FDatepicker from "@/components/module/input/FDatepicker.vue";

export default {
  name: "SeatingArrangementCanvas",
  components: { FDatepicker },
  async mounted() {
    await this.init();
  },
  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [LoadingMixin],
  watch: {
    radio() {
      this.firstClick = true;
      this.x = 0;
      this.y = 0;
      this.phantomX = 0;
      this.phantomY = 0;
      if (this.radio !== "desk") this.selectedDesk = null;
      if (this.radio === "seat")
        if (this.selectedRoom) this.selectRoom(this.selectedRoom);
      this.redrawEverything();
    },

    async selectedDate() {
      if (this.selectedDate) {
        let detailParams = {
          year: this.selectedDate.getFullYear(),
          week: this.dateToISOWeek(this.selectedDate),
        };
        await this.$store.dispatch("seating_arrangement/fetchSeatDetails", {
          params: detailParams,
        });
        let day = this.selectedDate.getDay();
        if (day === 0) day = 7;
        --day;
        this.selectedDay = day;
      }
      this.redrawEverything();
    },
  },

  computed: {
    ...mapGetters({
      sites: "census_seating/sites",
      seatDetails: "seating_arrangement/seatDetails",
    }),
  },

  data() {
    return {
      CIRCLE_RADIUS: 30,
      ROOM_SIZE_MULTIPLIER: 3,
      canvasDocument: null,
      canvas: null,
      phantomX: 0,
      phantomY: 0,
      x: 0,
      y: 0,
      offsetX: -4096,
      offsetY: -4096,
      firstClick: true,
      radio: "panning",
      roomDrawings: [],
      deskDrawings: [],
      seatDrawings: [],
      phantomRoom: null,
      phantomDesk: null,
      phantomSeat: null,
      rooms: [],
      seats: [],
      selectedSite: null,
      selectedRoom: null,
      selectedDesk: null,
      selectedSeat: null,
      file: null,
      data: null,
      floors: [],
      currentFloor: 0,
      panStart: null,
      panEnd: null,
      panning: false,
      SCALE: 1,
      selectedDate: null,
      selectedDay: 0,
      loadedData: null,
    };
  },
  methods: {
    async init() {
      await this.doStartLoading();
      await this.fetchSites();
      await this.doFinishLoading();

      this.canvasDocument = document.getElementById("canvas");
      this.canvas = this.canvasDocument.getContext("2d");
      this.canvas.width = 8192;
      this.canvas.height = 8192;
      this.canvas.translate(-this.canvas.width / 2, -this.canvas.height / 2);
      this.resetCanvasSettings();
      this.initFirstFloor();
    },

    async fetchSites() {
      await this.$store.dispatch("census_seating/fetchSites");
    },

    isSeatInsideAnyDesk(seat) {
      return this.deskDrawings.some((desk) =>
        this.isInsideOtherObject(seat, desk)
      );
    },

    getRoomFromSeat(seat) {
      return this.roomDrawings.find((desk) =>
        this.isInsideOtherObject(seat, desk)
      );
    },

    isDeskInsideAnyRoom(desk) {
      return this.roomDrawings.some((room) =>
        this.isInsideOtherObject(desk, room)
      );
    },

    getDesksInsideRoom(room) {
      return this.deskDrawings.filter((desk) =>
        this.isInsideOtherObject(desk, room)
      );
    },

    getsSeatsInsideDesk(desk) {
      return this.seatDrawings.filter((seat) =>
        this.isInsideOtherObject(seat, desk)
      );
    },

    isInsideOtherObject(inside, outside) {
      if (inside.r) {
        inside = {
          x: inside.x - inside.r,
          y: inside.y - inside.r,
          width: inside.r * 2,
          height: inside.r * 2,
        };
      }
      return (
        inside.x >= outside.x &&
        inside.y >= outside.y &&
        inside.x + inside.width <= outside.x + outside.width &&
        inside.y + inside.height <= outside.y + outside.height
      );
    },

    isColliding(o1, o2) {
      if (o2.r) {
        o2 = {
          x: o2.x - o2.r,
          y: o2.y - o2.r,
          width: o2.r * 2,
          height: o2.r * 2,
        };
      }
      if (o1.r) {
        o1 = {
          x: o1.x - o1.r,
          y: o1.y - o1.r,
          width: o1.r * 2,
          height: o1.r * 2,
        };
      }
      return (
        o1.x <= o2.x + o2.width &&
        o1.x + o1.width >= o2.x &&
        o1.y <= o2.y + o2.height &&
        o1.y + o1.height >= o2.y
      );
    },

    isRectangleBigEnough(rectangle, type) {
      return type === "room"
        ? !(
            rectangle.width <
              this.CIRCLE_RADIUS * 2 * this.ROOM_SIZE_MULTIPLIER ||
            rectangle.height <
              this.CIRCLE_RADIUS * 2 * this.ROOM_SIZE_MULTIPLIER
          )
        : !(
            rectangle.width < this.CIRCLE_RADIUS * 2 ||
            rectangle.height < this.CIRCLE_RADIUS * 2
          );
    },

    isRoomColliding(room) {
      return this.roomDrawings.some((roomD) => this.isColliding(room, roomD));
    },

    isSeatColliding(seat) {
      return this.seatDrawings.some((seatD) => this.isColliding(seat, seatD));
    },

    isRoomAlreadyDrawn(room) {
      return this.floors.some((floor) =>
        floor.rooms.some((r) => r.name === room.name)
      );
    },

    isSeatAlreadyDrawn(seat) {
      return this.floors.some((floor) =>
        floor.seats.some((s) => s.name === seat.name)
      );
    },

    async getClickedItem(e) {
      let clickedX = (e.offsetX - this.offsetX) / this.SCALE;
      let clickedY = (e.offsetY - this.offsetY) / this.SCALE;
      for (let seat of this.seatDrawings) {
        if (
          clickedX >= seat.x - seat.r &&
          clickedY >= seat.y - seat.r &&
          clickedX <= seat.x - seat.r + seat.r * 2 &&
          clickedY <= seat.y - seat.r + seat.r * 2
        ) {
          if (!this.isReadOnly) {
            this.radio = "seat";
            await this.selectRoom(seat.roomName);
            this.selectedSeat = seat.name;
          }
          return [seat, "seat"];
        }
      }
      for (let desk of this.deskDrawings) {
        if (
          clickedX >= desk.x &&
          clickedY >= desk.y &&
          clickedX <= desk.x + desk.width &&
          clickedY <= desk.y + desk.height
        ) {
          if (!this.isReadOnly) {
            this.radio = "desk";
            this.selectedDesk = desk;
          }
          return [desk, "desk"];
        }
      }
      for (let room of this.roomDrawings) {
        if (
          clickedX >= room.x &&
          clickedY >= room.y &&
          clickedX <= room.x + room.width &&
          clickedY <= room.y + room.height
        ) {
          if (!this.isReadOnly) {
            this.radio = "room";
            this.selectedRoom = room.name;
          }
          return [room, "room"];
        }
      }
      return null;
    },

    deleteSelectedRoom() {
      let room = this.roomDrawings.find(
        (curr) => curr.name === this.selectedRoom
      );
      this.deleteItem(room, "room");
      this.selectedRoom = null;
    },

    deleteSelectedDesk() {
      this.deleteItem(this.selectedDesk, "desk");
      this.selectedDesk = null;
    },

    deleteSelectedSeat() {
      let seat = this.seatDrawings.find(
        (curr) => curr.name === this.selectedSeat
      );
      this.deleteItem(seat, "seat");
      this.selectedSeat = null;
    },

    deleteItem(item, type) {
      let index = -1;

      switch (type) {
        case "seat": {
          index = this.seatDrawings.indexOf(item);
          if (index > -1) this.seatDrawings.splice(index, 1);
          break;
        }
        case "desk": {
          index = this.deskDrawings.indexOf(item);
          let toBeDeletedSeats = [];
          if (index > -1) {
            this.seatDrawings.forEach((seat) => {
              if (this.isInsideOtherObject(seat, this.deskDrawings[index])) {
                toBeDeletedSeats.push(seat);
              }
            });
            toBeDeletedSeats.forEach((seat) => {
              this.deleteItem(seat, "seat");
            });
            this.deskDrawings.splice(index, 1);
          }
          break;
        }
        case "room": {
          index = this.roomDrawings.indexOf(item);
          if (index > -1) {
            let toBeDeletedDesks = [];
            this.deskDrawings.forEach((desk) => {
              if (this.isInsideOtherObject(desk, this.roomDrawings[index])) {
                toBeDeletedDesks.push(desk);
              }
            });
            toBeDeletedDesks.forEach((desk) => {
              this.deleteItem(desk, "desk");
            });
            this.roomDrawings.splice(index, 1);
          }
          break;
        }
      }
      this.redrawEverything();
    },

    createRectangle(e, type, phantom) {
      this.phantomRoom = null;
      this.phantomDesk = null;
      let eX = (e.offsetX - this.offsetX) / this.SCALE;
      let eY = (e.offsetY - this.offsetY) / this.SCALE;
      if (this.firstClick) {
        if (!phantom) {
          this.x = eX;
          this.phantomX = eX;
          this.phantomY = eY;
          this.y = eY;
          this.firstClick = false;
        }
      } else {
        if (!phantom) {
          if (eX < this.x) {
            let tX = this.x;
            this.x = eX;
            eX = tX;
          }
          if (eY < this.y) {
            let tY = this.y;
            this.y = eY;
            eY = tY;
          }
        } else {
          if (eX < this.x) {
            this.phantomX = eX;
            eX = this.x;
          }
          if (eY < this.y) {
            this.phantomY = eY;
            eY = this.y;
          }
        }

        let width = Math.abs(this.x - eX);
        let height = Math.abs(this.y - eY);

        if (phantom) {
          width = Math.abs(this.phantomX - eX);
          height = Math.abs(this.phantomY - eY);
        }

        if (type === "room") {
          let room = {
            x: this.x,
            y: this.y,
            width: width,
            height: height,
            name: this.selectedRoom,
          };

          if (phantom) {
            room.x = this.phantomX;
            room.y = this.phantomY;
            this.phantomRoom = room;
          } else {
            if (
              !this.isRectangleBigEnough(room, "room") ||
              this.isRoomAlreadyDrawn(room) ||
              this.isRoomColliding(room)
            ) {
              this.firstClick = true;
              return;
            }
            this.roomDrawings.push(room);
          }
        } else if (type === "desk") {
          let desk = {
            x: this.x,
            y: this.y,
            width: width,
            height: height,
          };
          if (phantom) {
            desk.x = this.phantomX;
            desk.y = this.phantomY;
            this.phantomDesk = desk;
          } else {
            if (
              !this.isRectangleBigEnough(desk, "desk") ||
              !this.isDeskInsideAnyRoom(desk)
            ) {
              this.firstClick = true;
              return;
            }
            this.deskDrawings.push(desk);
          }
        }
        if (!phantom) this.firstClick = true;
      }
    },

    createCircle(e, phantom) {
      this.phantomSeat = null;
      let seat = {
        x: (e.offsetX - this.offsetX) / this.SCALE,
        y: (e.offsetY - this.offsetY) / this.SCALE,
        r: this.CIRCLE_RADIUS,
        sAngle: 0,
        eAngle: 2 * Math.PI,
        name: this.selectedSeat,
        roomName: this.selectedRoom,
        fullName:
          this.selectedSite + "-" + this.selectedRoom + "-" + this.selectedSeat,
      };
      if (phantom) this.phantomSeat = seat;
      else if (
        this.isSeatInsideAnyDesk(seat) &&
        !this.isSeatAlreadyDrawn(seat) &&
        !this.isSeatColliding(seat) &&
        this.selectedSeat !== null
      )
        this.seatDrawings.push(seat);
    },

    drawAllRooms() {
      if (this.roomDrawings)
        this.roomDrawings.forEach((room) => {
          this.canvas.fillStyle = "#808080";
          if (this.radio === "room" && this.selectedRoom === room.name) {
            this.canvas.shadowBlur = 10;
          }
          for (let i = 0; i < 4; i++)
            this.canvas.fillRect(room.x, room.y, room.width, room.height);
          this.resetCanvasSettings();
        });
    },

    drawAllDesks() {
      if (this.deskDrawings)
        this.deskDrawings.forEach((desk) => {
          if (this.selectedDesk === desk) {
            this.canvas.shadowBlur = 10;
          }
          this.canvas.beginPath();
          this.canvas.fillStyle = "#B0B0B0";
          this.canvas.fillRect(desk.x, desk.y, desk.width, desk.height);
          for (let i = 0; i < 4; i++)
            this.canvas.rect(desk.x, desk.y, desk.width, desk.height);
          this.canvas.stroke();
          this.canvas.closePath();
          this.resetCanvasSettings();
        });
    },

    drawAllSeats() {
      if (this.seatDrawings) {
        this.seatDrawings.forEach((seat) => {
          if (
            (this.radio === "seat" && this.selectedSeat === seat.name) ||
            (this.radio === "choose" &&
              this.isReadOnly &&
              this.selectedSeat === seat.name)
          ) {
            this.canvas.shadowBlur = 10;
          }
          this.canvas.beginPath();
          this.canvas.arc(seat.x, seat.y, seat.r, seat.sAngle, seat.eAngle);
          if (this.isReadOnly && this.seatDetails[this.selectedDay]) {
            if (
              this.seatDetails[this.selectedDay].find(
                (detail) => detail.name === seat.fullName
              ).occupied
            ) {
              this.canvas.fillStyle = "#FF0000";
            } else {
              this.canvas.fillStyle = "#008000";
            }
            this.canvas.fill();
          }
          for (let i = 0; i < 4; i++) {
            this.canvas.stroke();
          }
          this.canvas.closePath();
          this.resetCanvasSettings();
        });
      }
    },

    drawPhantomRoom() {
      if (this.phantomRoom) {
        if (
          !this.isRectangleBigEnough(this.phantomRoom, "room") ||
          this.isRoomAlreadyDrawn(this.phantomRoom) ||
          this.isRoomColliding(this.phantomRoom)
        ) {
          this.canvas.fillStyle = "rgba(256, 128, 128, 0.5)";
          this.canvas.strokeStyle = "rgba(128, 0, 0, 0.5)";
        } else {
          this.canvas.fillStyle = "rgba(128, 128, 128, 0.5)";
          this.canvas.strokeStyle = "rgba(0, 0, 0, 0.5)";
        }

        this.canvas.fillRect(
          this.phantomRoom.x,
          this.phantomRoom.y,
          this.phantomRoom.width,
          this.phantomRoom.height
        );
        this.drawText(
          this.phantomRoom.name,
          this.phantomRoom.x + this.phantomRoom.width / 2,
          this.phantomRoom.y + this.phantomRoom.height / 2
        );
        this.resetCanvasSettings();
      }
    },

    drawPhantomDesk() {
      if (this.phantomDesk) {
        this.canvas.beginPath();
        if (
          !this.isRectangleBigEnough(this.phantomDesk, "desk") ||
          !this.isDeskInsideAnyRoom(this.phantomDesk)
        ) {
          this.canvas.fillStyle = "rgba(255, 140, 140, 0.5)";
          this.canvas.strokeStyle = "rgba(128, 0, 0, 0.5)";
        } else {
          this.canvas.fillStyle = "rgba(176, 176, 176, 0.5)";
          this.canvas.strokeStyle = "rgba(0, 0, 0, 0.5)";
        }
        this.canvas.fillRect(
          this.phantomDesk.x,
          this.phantomDesk.y,
          this.phantomDesk.width,
          this.phantomDesk.height
        );
        this.canvas.rect(
          this.phantomDesk.x,
          this.phantomDesk.y,
          this.phantomDesk.width,
          this.phantomDesk.height
        );
        this.canvas.stroke();
        this.canvas.closePath();
        this.resetCanvasSettings();
      }
    },

    drawPhantomSeat() {
      if (this.phantomSeat) {
        if (
          !this.isSeatInsideAnyDesk(this.phantomSeat) ||
          this.isSeatAlreadyDrawn(this.phantomSeat) ||
          this.isSeatColliding(this.phantomSeat) ||
          this.selectedSeat === null
        ) {
          this.canvas.fillStyle = "rgba(255, 140, 140, 0.5)";
          this.canvas.strokeStyle = "rgba(128, 0, 0, 0.5)";
        } else {
          this.canvas.strokeStyle = "rgba(0, 0, 0, 0.5)";
        }
        this.canvas.beginPath();
        this.canvas.arc(
          this.phantomSeat.x,
          this.phantomSeat.y,
          this.phantomSeat.r,
          this.phantomSeat.sAngle,
          this.phantomSeat.eAngle
        );
        this.canvas.stroke();
        this.canvas.closePath();
        this.drawText(
          this.phantomSeat.name,
          this.phantomSeat.x,
          this.phantomSeat.y
        );
        this.resetCanvasSettings();
      }
    },

    drawAllNames() {
      this.roomDrawings.forEach((room) => {
        this.drawText(
          room.name,
          room.x + room.width / 2,
          room.y + room.height / 2
        );
      });
      this.seatDrawings.forEach((seat) => {
        this.drawText(seat.name, seat.x, seat.y);
      });
    },

    redrawEverything() {
      this.canvas.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.drawAllRooms();
      this.drawPhantomRoom();
      this.drawAllDesks();
      this.drawPhantomDesk();
      this.drawAllSeats();
      this.drawPhantomSeat();
      this.drawAllNames();
      this.floors[this.currentFloor].rooms = this.roomDrawings;
      this.floors[this.currentFloor].desks = this.deskDrawings;
      this.floors[this.currentFloor].seats = this.seatDrawings;
    },

    recenter() {
      this.canvas.setTransform(1, 0, 0, 1, 0, 0);
      this.canvas.translate(-this.canvas.width / 2, -this.canvas.height / 2);
      this.panStart = null;
      this.panEnd = null;
      this.offsetX = -4096;
      this.offsetY = -4096;
      this.SCALE = 1;
      this.redrawEverything();
    },

    async click(e, phantom) {
      if (this.radio === "room" || this.radio === "desk") {
        this.createRectangle(e, this.radio, phantom);
      } else if (this.radio === "seat") {
        this.createCircle(e, phantom);
      } else if (this.radio === "panning") {
        if (this.panning) {
          this.panEnd = {
            x: e.offsetX,
            y: e.offsetY,
          };
          this.offsetX += (this.panEnd.x - this.panStart.x) * this.SCALE;
          this.offsetY += (this.panEnd.y - this.panStart.y) * this.SCALE;
          this.canvas.translate(
            this.panEnd.x - this.panStart.x,
            this.panEnd.y - this.panStart.y
          );
          this.panStart = this.panEnd;
        }
      } else if (this.radio === "choose" && !phantom) {
        let item = await this.getClickedItem(e);
        if (item) {
          if (this.isReadOnly) {
            if (item[0].fullName) {
              let user = this.seatDetails[this.selectedDay].find(
                (detail) => detail.name === item[0].fullName
              ).user;
              this.$buefy.dialog.alert(
                "Név: " +
                  item[0].name +
                  "<br>Teljes név: " +
                  item[0].fullName +
                  "<br>Felhasználó: " +
                  (user !== undefined ? user : "Nincs lefoglalva")
              );
            }
          }
        }
      } else if (this.radio === "choose" && phantom && this.isReadOnly) {
        let item = await this.getClickedItem(e);
        if (item) {
          if (item[1] === "seat") {
            this.selectedSeat = item[0].name;
          } else {
            this.selectedSeat = null;
          }
        } else {
          this.selectedSeat = null;
        }
      }
      this.redrawEverything();
    },

    pan(e) {
      this.panning = true;
      this.panStart = {
        x: e.offsetX,
        y: e.offsetY,
      };
    },

    zoom(e) {
      e.preventDefault();
      let zoomValue = 1 + e.deltaY * -0.0005;

      this.canvas.scale(zoomValue, zoomValue);

      this.SCALE *= zoomValue;

      let scaleValueOut = (220 * e.deltaY) / 100;
      let scaleValueIn = (200 * e.deltaY) / 100;

      if (zoomValue < 1) {
        this.offsetX += scaleValueOut * this.SCALE;
        this.offsetY += scaleValueOut * this.SCALE;
        this.canvas.translate(scaleValueOut, scaleValueOut);
      } else {
        this.offsetX += scaleValueIn * this.SCALE;
        this.offsetY += scaleValueIn * this.SCALE;
        this.canvas.translate(scaleValueIn, scaleValueIn);
      }

      this.redrawEverything();
    },

    drawText(text, x, y) {
      this.canvas.beginPath();
      this.canvas.strokeText(text, x, y);
      this.canvas.stroke();
      this.canvas.closePath();
    },

    clearCanvas() {
      this.panning = false;
      this.phantomRoom = null;
      this.phantomDesk = null;
      this.phantomSeat = null;
      this.redrawEverything();
    },

    async selectSite(site) {
      this.rooms = [];
      await this.$store.dispatch("census_seating/fetchRoomsById", site);
      this.rooms = this.$store.getters["census_seating/rooms"];
      this.selectedSite = site;
      let selectedSite = this.sites.find((s) => s.identifier === site);
      if (!this.loadedData) {
        try {
          let floors = JSON.parse(selectedSite.blueprint);
          this.populateFloors(floors);
        } catch (e) {}
      }
      this.selectedRoom = null;
      this.selectedSeat = null;
    },

    async selectRoom(room) {
      this.seats = [];
      await this.$store.dispatch("census_seating/fetchSeatsById", room);
      this.seats = this.$store.getters["census_seating/seats"];
      this.selectedRoom = room;
      this.selectedSeat = null;
    },

    selectSeat(seat) {
      this.selectedSeat = seat;
      this.redrawEverything();
    },

    resetCanvasSettings() {
      this.canvas.fillStyle = "#FFFFFF";
      this.canvas.strokeStyle = "#000000";
      this.canvas.lineWidth = "2";
      this.canvas.font = "24px Arial";
      this.canvas.textAlign = "center";
      this.canvas.shadowBlur = 0;
      this.canvas.shadowColor = "#FFFF00";
    },

    async getDataToSave() {
      let selectedFloors = [];

      for (let floor of this.floors) {
        let selectedFloor = {
          number: floor.number,
          name: floor.name,
          rooms: [],
        };
        for (let room of floor.rooms) {
          let selectedRoom = {
            drawing: room,
            desks: [],
          };
          for (let desk of floor.desks) {
            let selectedDesk = {
              drawing: desk,
              seats: [],
            };
            for (let seat of floor.seats) {
              if (this.isInsideOtherObject(seat, desk))
                selectedDesk.seats.push(seat);
            }
            if (this.isInsideOtherObject(desk, room))
              selectedRoom.desks.push(selectedDesk);
          }
          selectedFloor.rooms.push(selectedRoom);
        }
        selectedFloors.push(selectedFloor);
      }

      return {
        site: this.sites.find((site) => site.identifier === this.selectedSite),
        floors: selectedFloors,
      };
    },

    async saveToDrive() {
      let data = await this.getDataToSave();

      let json = JSON.stringify(data);

      let file = new Blob([json], {
        type: "application/json",
      });

      let a = document.createElement("a");
      a.href = URL.createObjectURL(file);
      a.download = "seating_arrangement_" + this.selectedSite + ".json";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async saveRemote() {
      let data = await this.getDataToSave();
      let site = data.site;
      site.blueprint = JSON.stringify(data.floors);
      await this.$store.dispatch("census_seating/saveSite", site);
    },

    async load() {
      if (this.file) {
        this.loadedData = await this.getDataFromFile(this.file);

        await this.selectSite(this.loadedData.site.identifier);
        this.populateFloors(this.loadedData);
        this.file = null;
        this.loadedData = null;
        this.recenter();
      }
    },

    populateFloors(data) {
      this.roomDrawings = [];
      this.seatDrawings = [];
      this.deskDrawings = [];
      this.currentFloor = 0;
      this.floors = [];

      let floors = data.floors ? data.floors : data;

      for (let floor of floors) {
        let drawFloor = {
          number: floor.number,
          name: floor.name,
          rooms: [],
          desks: [],
          seats: [],
        };
        for (let room of floor.rooms) {
          drawFloor.rooms.push(room.drawing);

          for (let desk of room.desks) {
            drawFloor.desks.push(desk.drawing);

            for (let seat of desk.seats) {
              drawFloor.seats.push(seat);
            }
          }
        }
        this.floors.push(drawFloor);
        this.roomDrawings = this.floors[0].rooms;
        this.deskDrawings = this.floors[0].desks;
        this.seatDrawings = this.floors[0].seats;
      }
      this.redrawEverything();
    },

    async getDataFromFile(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (res) => {
          let result = JSON.parse(res.target.result);
          resolve(result);
        };
        reader.onerror = (err) => console.log(err);
        reader.readAsText(file);
      });
    },

    initFirstFloor() {
      let newFloor = {
        number: 0,
        name: "",
        rooms: [],
        desks: [],
        seats: [],
      };
      this.floors.push(newFloor);
    },

    addNewFloor() {
      const newFloor = {
        number: this.currentFloor + 1,
        name: "",
        rooms: [],
        desks: [],
        seats: [],
      };
      this.floors.push(newFloor);
      this.goUp();
    },

    goUp() {
      this.currentFloor++;

      const upperFloor = this.floors[this.currentFloor];
      this.roomDrawings = upperFloor.rooms;
      this.deskDrawings = upperFloor.desks;
      this.seatDrawings = upperFloor.seats;
      this.redrawEverything();
    },

    goDown() {
      this.currentFloor--;

      let floor = this.floors[this.currentFloor];
      this.roomDrawings = floor.rooms;
      this.deskDrawings = floor.desks;
      this.seatDrawings = floor.seats;
      this.redrawEverything();
    },

    dateToISOWeek(dt) {
      let tdt = new Date(dt.valueOf());
      let dayn = (dt.getDay() + 6) % 7;
      tdt.setDate(tdt.getDate() - dayn + 3);
      let firstThursday = tdt.valueOf();
      tdt.setMonth(0, 1);
      if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
      }
      return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

canvas {
  border: 1px solid black;
  margin: 0 auto;
}

.canvas-overlay {
  size: 1000px 500px;
  text-align: center;
}

.file.is-info {
  .file-cta.seating-upload-button {
    background-color: $custom-light-blue;
    border-radius: 8px;

    &:hover,
    &:active {
      background-color: $blue;
    }
  }
}

.canvas-controls {
  background-color: $grey-lightest;
  border-radius: 8px;
  padding: 5px;
  width: 100%;
  margin-top: 10px;
  box-shadow: 0 5px 30px rgb(48 52 62 / 10%);

  .canvas-controls-option {
    margin-top: 10px;
  }
}

.canvas-controls-on-top {
  margin: 5px;
  height: calc(2.5em + 10px);
}
</style>
