<template>
  <div class="timeline-cards">
    <information-card class="timeline-cards-card">
      <template #card-upper-left>
        <div class="card-title">
          {{ $tf("projectPage.timeline.dueDate|Határidő") }}
        </div>
        <div class="card-value">
          {{ dueDate }}
        </div>
      </template>
      <template #card-upper-right>
        <div class="card-right-information">
          {{ daysWholePercent }}
        </div>
      </template>
      <template #card-lower-left>
        <div class="card-additional-information">
          {{ $tf("projectPage.timeline.today|Mai dátum") }}
        </div>
      </template>
      <template #card-lower-right>
        <div class="card-right-value">
          {{ date }}
        </div>
      </template>
    </information-card>
    <information-card class="timeline-cards-card">
      <template #card-upper-left>
        <div class="card-title">
          {{
            $tf(
              "projectPage.timeline.trendingFinish|Becsült befejezés (trendtartó)"
            )
          }}
        </div>
        <div class="card-value">
          {{ trendingDueDate }}
        </div>
      </template>
      <template #card-upper-right>
        <div class="card-right-information">
          <template v-if="diffTrendingTime">
            {{
              $tf("projectPage.timeline.trendingDiff|{diff} nap", {
                diff: diffTrendingTime,
              })
            }}
          </template>
        </div>
      </template>
    </information-card>
    <information-card class="timeline-cards-card">
      <template #card-upper-left>
        <div class="card-title">
          {{
            $tf(
              "projectPage.timeline.planningFinish|Becsült befejezés (tervtartó)"
            )
          }}
        </div>
        <div class="card-value">
          {{ planningDueDate }}
        </div>
      </template>
      <template #card-upper-right>
        <div class="card-right-information">
          <template v-if="diffPlanningTime">
            {{
              $tf("projectPage.timeline.planningDiff|{diff} nap", {
                diff: diffPlanningTime,
              })
            }}
          </template>
        </div>
      </template>
    </information-card>
  </div>
</template>

<script>
import InformationCard from "@/components/InformationCard.vue";
import { moneyify, percentify, workdayify, formatDate } from "@/utils/util";
import { PROJECT_PROGRESS_TYPE } from "@/utils/const";

export default {
  name: "NormalProjectTimelineCards",
  components: {
    InformationCard,
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      date: formatDate(new Date()),
    };
  },
  computed: {
    notTracked() {
      return this.projectDetails?.progressType === PROJECT_PROGRESS_TYPE.NONE;
    },

    time() {
      return this.projectDetails?.daysBetweenDue || 0;
    },
    planningTime() {
      if (this.notTracked) return "-";
      return this.projectDetails?.forecastPlanningTime || 0;
    },
    diffPlanningTime() {
      if (this.notTracked) return "-";
      const num = this.planningTime - this.time;
      return num > 0 ? `+${num}` : num;
    },
    trendingTime() {
      if (this.notTracked) return "-";
      return this.projectDetails?.forecastTrendingTime || 0;
    },
    diffTrendingTime() {
      if (this.notTracked) return "-";
      const num = this.trendingTime - this.time;
      return num > 0 ? `+${num}` : num;
    },

    dueDate() {
      if (!this.projectDetails?.dueDate) return "-";
      return formatDate(new Date(this.projectDetails.dueDate));
    },
    trendingDueDate() {
      if (this.notTracked || !this.projectData?.startDate) return "-";
      return formatDate(
        new Date(this.projectData.startDate).addDaysSkippingWeekends(
          this.trendingTime
        )
      );
    },
    planningDueDate() {
      if (this.notTracked || !this.projectData?.startDate) return "-";
      return formatDate(
        new Date(this.projectData.startDate).addDaysSkippingWeekends(
          this.planningTime
        )
      );
    },
    daysWholePercent() {
      if (
        !this.projectDetails?.daysSinceStart ||
        !this.projectDetails?.daysBetweenDue
      )
        return "";
      return percentify(
        this.projectDetails.daysSinceStart / this.projectDetails.daysBetweenDue
      );
    },
  },
  methods: {
    moneyify,
    percentify,
    workdayify,
    formatDate,
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.card {
  &-title {
    font-size: 12px;
    line-height: 16px;
    color: $custom-dark-blue;
    font-weight: 500;
  }

  &-value {
    font-size: 26px;
    line-height: 30px;
    color: $custom-dark-blue;
    font-weight: 700;
    padding-bottom: 6px;
    padding-top: 4px;
  }

  &-additional-information {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: $custom-grayer-blue;
  }

  &-right-information {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: $custom-grayer-blue;
    padding-top: 34px;
  }

  &-right-information-with-icon {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: $custom-grayer-blue;
  }

  &-right-value {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: $custom-dark-blue;
  }
}

.timeline-cards {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  padding-bottom: 10px;

  &-card {
    flex: 1 0 24%;
  }
}
</style>
