<template>
  <div class="modal-card">
    <div class="container">
      <section class="modal-card-body">
        <h1 class="title">
          <template v-if="createMode">
            {{
              $tf(
                "forecast.chanceCategory.edit.createTitle|Új esélykategória felvétele"
              )
            }}
          </template>
          <template v-else>
            {{
              $tf(
                "forecast.chanceCategory.edit.editTitle|Esélykategória módosítása"
              )
            }}
          </template>
        </h1>
        <form v-if="loaded">
          <div class="is-flex is-align-items-start has-gap-3">
            <div>
              <vuelidated-input
                :label="$tf('forecast.chanceCategory.edit.nameLabel|Név')"
                :placeholder="
                  $tf('forecast.chanceCategory.edit.namePlaceholder|Név')
                "
                v-model="editChanceCategory.name"
                :validation-rule="v$.editChanceCategory.name"
                label-position="on-border"
                ref="nameInput"
                ref-value="input"
                @keyup.enter="save"
              ></vuelidated-input>
            </div>

            <div class="is-flex-grow-0">
              <b-field>
                <vuelidated-form
                  :validations="v$.editChanceCategory.chance"
                  class="mt-5"
                >
                  <template v-slot="{ errors, invalid }">
                    <b-field
                      label-position="on-border"
                      :type="{ 'is-danger': invalid }"
                      :message="
                        v$.editChanceCategory.chance.$error ? errors[0] : null
                      "
                    >
                      <template #label>
                        Esély (%)
                        <span class="has-text-danger">*</span>
                      </template>
                      <b-numberinput
                        @keyup.enter="save"
                        v-model="editChanceCategory.chance"
                        controls-rounded
                        min="0"
                        max="100"
                        :disabled="!editable"
                      ></b-numberinput>
                    </b-field>
                  </template>
                </vuelidated-form>
              </b-field>
            </div>
          </div>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close">
              {{ $tf("forecast.chanceCategory.edit.cancel|Mégsem") }}
            </b-button>
            <b-button @click="save" type="is-info" class="ml-3">
              {{ $tf("forecast.chanceCategory.edit.save|Mentés") }}
            </b-button>
          </b-field>
        </form>

        <LoadingSkeleton v-else />
      </section>
    </div>
  </div>
</template>

<script>
import { integer, maxValue, minValue, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import { deepCopy } from "@/utils/util";
import LoadingSkeleton from "../../module/loading/LoadingSkeleton.vue";
import VuelidatedForm from "@/components/module/input/VuelidatedForm.vue";
import { getCurrentInstance, nextTick } from "vue";

export default {
  name: "ForecastDataChanceCategoryEditModal",
  components: { VuelidatedForm, LoadingSkeleton, VuelidatedInput },
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  props: {
    chanceCategory: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    this.createMode = !this.chanceCategory;

    if (this.chanceCategory) {
      this.editChanceCategory = deepCopy(this.chanceCategory);
    }

    this.loaded = true;
    nextTick(() => {
      this.$refs.nameInput.$refs.input.focus();
    });
  },
  data() {
    return {
      loaded: false,
      createMode: false,
      editChanceCategory: {
        name: undefined,
        chance: 0,
      },
    };
  },
  validations: {
    editChanceCategory: {
      name: {
        required,
      },
      chance: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(100),
        integer,
      },
    },
  },
  computed: {
    editable() {
      return !this.editChanceCategory.id || this.editChanceCategory.id > 5;
    },
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    async save() {
      this.v$.editChanceCategory.$touch();
      if (!this.v$.editChanceCategory.$invalid) {
        let request = deepCopy(this.editChanceCategory);

        if (this.createMode) {
          await this.$store.dispatch(
            "forecast_chance_category/createChanceCategory",
            request
          );
        } else {
          await this.$store.dispatch(
            "forecast_chance_category/updateChanceCategory",
            request
          );
        }
        this.$emit("save");
        this.close();
      }
    },
  },
};
</script>

<style scoped>
.control > button {
  border-radius: 8px !important;
}
</style>
