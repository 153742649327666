<template>
  <section class="section">
    <div class="container">
      <h1 class="title">
        {{ $tf("overtimePolicies.title|Túlóra szabályzatok") }}
      </h1>
      <h2 class="subtitle">
        {{
          $tf(
            "overtimePolicies.subtitle|Túlóra szabályzatok létrehozása, szerkesztése"
          )
        }}
      </h2>
    </div>

    <div class="container">
      <div class="is-flex">
        <b-button type="is-info" class="ml-auto mb-5" @click="openEditPopup()"
          ><b-icon icon="plus-circle" class="mr-2" />
          {{ $tf("overtimePolicies.add|Új túlóra szabályzat létrehozása") }}
        </b-button>
      </div>

      <b-table
        :data="overtimePolicies"
        ref="table"
        striped
        hoverable
        paginated
        per-page="30"
        :show-detail-icon="true"
        aria-next-label="Következő lap"
        aria-previous-label="Előző lap"
        aria-page-label="Lap"
        aria-current-label="Aktuális lap"
        :loading="loading"
      >
        <b-table-column
          field="name"
          :label="$tf('overtimePolicies.table.name|Név')"
          v-slot="props"
          sortable
          searchable
        >
          <b-field grouped group-multiline>
            <b-field>
              {{ props.row.name }}
            </b-field>
          </b-field>
        </b-table-column>

        <b-table-column
          field="multiplier"
          :label="$tf('overtimePolicies.table.multipliter|Szorzó')"
          v-slot="props"
          sortable
          searchable
        >
          <b-field grouped group-multiline>
            <b-field>
              {{ props.row.multiplier }}
            </b-field>
          </b-field>
        </b-table-column>

        <b-table-column
          field="active"
          :label="$tf('overtimePolicies.table.active|Aktív')"
          v-slot="props"
          sortable
          searchable
        >
          <b-field grouped group-multiline>
            <b-field>
              <b-switch
                v-model="props.row.active"
                @click="
                  props.row.active
                    ? deactivate($event, props.row.id)
                    : activate($event, props.row.id)
                "
              >
                {{
                  props.row.active
                    ? $tf("overtimePolicies.table.active.yes|Igen")
                    : $tf("overtimePolicies.table.active.no|Nem")
                }}
              </b-switch>
            </b-field>
          </b-field>
        </b-table-column>

        <b-table-column
          field="actions"
          :label="$tf('overtimePolicies.table.actions|Műveletek')"
          centered
          v-slot="props"
          width="32"
        >
          <clickable-icon
            icon="pencil"
            type="is-info"
            class="mr-2"
            @click="openEditPopup(props.row)"
          ></clickable-icon>
          <clickable-icon
            icon="trash"
            type="is-danger"
            @click="deleteOvertimePolicy(props.row)"
          ></clickable-icon>
        </b-table-column>

        <template #empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p><b-icon icon="frown" size="is-large"></b-icon></p>
              <p>
                {{
                  $tf(
                    "overtimePolicies.table.empty|Még nincs felvéve túlóra szabályzat"
                  )
                }}
              </p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import OvertimePolicyForm from "./OvertimePolicyForm";
import ClickableIcon from "@/components/module/icon/ClickableIcon";

export default {
  name: "OvertimePolicies",
  components: { ClickableIcon },
  computed: {
    ...mapGetters({
      overtimePolicies: "overtime_policies/getOvertimePolicies",
    }),
  },
  methods: {
    activate($event, id) {
      $event.preventDefault();
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "overtimePolicies.activateModal.title|Aktiválás megerősítése"
        ),
        message: this.$tf(
          "overtimePolicies.activateModal.message|Biztosan aktiválásra kerüljön a túlóra szabályzat?"
        ),
        cancelText: this.$tf("overtimePolicies.activateModal.cancel|Mégse"),
        confirmText: this.$tf(
          "overtimePolicies.activateModal.confirm|Aktiválás"
        ),
        type: "is-warning",
        onConfirm: async () => {
          await this.$store.dispatch(
            "overtime_policies/activateOvertimePolicy",
            id
          );
          await this.fetchList(true);
        },
      });
    },
    deactivate($event, id) {
      $event.preventDefault();
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "overtimePolicies.inactivateModal.title|Inktiválás megerősítése"
        ),
        message: this.$tf(
          "overtimePolicies.inactivateModal.message|Biztosan inaktiválásra kerüljön a túlóra szabályzat?"
        ),
        cancelText: this.$tf("overtimePolicies.inactivateModal.cancel|Mégse"),
        confirmText: this.$tf(
          "overtimePolicies.inactivateModal.confirm|Inaktiválás"
        ),
        type: "is-warning",
        onConfirm: async () => {
          await this.$store.dispatch(
            "overtime_policies/deactivateOvertimePolicy",
            id
          );
          await this.fetchList(true);
        },
      });
    },
    openEditPopup(overtimePolicy) {
      this.$buefy.modal.open({
        parent: this,
        component: OvertimePolicyForm,
        props: { overtimePolicy: overtimePolicy },
        hasModalCard: true,
        trapFocus: true,
      });
    },
    deleteOvertimePolicy(category) {
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "overtimePolicies.deleteModal.title|Törlés megerősítése"
        ),
        message: this.$tf(
          "overtimePolicies.deleteModal.message|Biztosan törlésre kerüljön a túlóra szabályzat?"
        ),
        cancelText: this.$tf("overtimePolicies.deleteModal.cancel|Mégse"),
        confirmText: this.$tf("overtimePolicies.deleteModal.conrifm|Törlés"),
        type: "is-danger",
        onConfirm: async () => {
          await this.$store.dispatch("overtime_policies/delete", category.id);
          await this.fetchList(true);
        },
      });
    },
    async fetchList(force) {
      this.loading = true;
      await this.$store.dispatch(
        "overtime_policies/fetchOvertimePolicies",
        force
      );
      this.loading = false;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  async mounted() {
    await this.fetchList();
  },
};
</script>

<style></style>
