<template>
  <div>
    <div v-if="loading || !filtersLoaded">
      <LoadingSkeleton></LoadingSkeleton>
    </div>
    <div v-else>
      <div class="is-flex is-align-items-center has-gap-6">
        <div class="is-flex is-align-items-center has-gap-2">
          <div>
            <b-dropdown aria-role="list" v-model="selectedYear">
              <template #trigger="{ active }">
                <b-button
                  :label="'Év: ' + selectedYear"
                  type="is-info"
                  :icon-right="active ? 'chevron-up' : 'chevron-down'"
                />
              </template>

              <b-dropdown-item
                v-for="year in years"
                :key="year"
                :value="year"
                aria-role="listitem"
                >{{ year }}</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
        <div class="is-flex is-align-items-center has-gap-2 ml-auto">
          <b-checkbox-button v-model="includedTypes" native-value="fixes">
            <span>Support</span>
          </b-checkbox-button>

          <b-checkbox-button v-model="includedTypes" native-value="lockups">
            <span>Lekötés</span>
          </b-checkbox-button>

          <b-checkbox-button v-model="includedTypes" native-value="billables">
            Elszámolható
          </b-checkbox-button>
        </div>
      </div>
      <!--      <div class="mt-5 mb-2 is-flex is-align-items-center has-gap-5">-->
      <!--        <div class="is-flex is-align-items-center has-gap-2">-->
      <!--          <div class="legend-stripe legend-stripe-warning" />-->
      <!--          <div>Költés arány</div>-->
      <!--        </div>-->
      <!--        <div class="is-flex is-align-items-center has-gap-2">-->
      <!--          <div class="legend-stripe legend-stripe-default" />-->
      <!--          <div>Idő arány</div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div style="overflow-x: auto; padding-bottom: 24px">
        <table class="support-project-list-table">
          <tr>
            <th style="width: 240px" colspan="2"></th>
            <th class="has-grey-left-border" colspan="6">
              <div class="header-cell-bubble">Aktuális hónap</div>
            </th>
            <th class="has-grey-left-border" colspan="6">
              <div class="header-cell-bubble">
                {{ $tf(SHORT_MONTH_NAMES[0]) }}-{{
                  $tf(SHORT_MONTH_NAMES[now.getMonth()])
                }}
              </div>
            </th>
            <th class="has-grey-left-border" colspan="6">
              <div class="header-cell-bubble">Éves FC</div>
            </th>
          </tr>
          <tr class="header-row">
            <td colspan="2" class="has-text-centered">
              Projekt, <br />
              Ügyfél, Felelős
            </td>
            <td class="has-grey-left-border">Bevétel</td>
            <td>Büdzsé</td>
            <td>Költés</td>
            <td>Risk KPI</td>
            <td>Várható költés</td>
            <td>Várható profit</td>
            <td class="has-grey-left-border">Bevétel</td>
            <td>Büdzsé</td>
            <td>Költés</td>
            <td>Risk KPI</td>
            <td>Várható költés</td>
            <td>Várható profit</td>
            <td class="has-grey-left-border">Bevétel</td>
            <td>Büdzsé</td>
            <td>Költés</td>
            <td>Risk KPI</td>
            <td>Várható költés</td>
            <td>Várható profit</td>
          </tr>
          <tbody v-for="project in projects.items" :key="project.id">
            <tr
              class="has-grey-top-border cursor-pointer"
              style="white-space: nowrap"
              @click="openProjectPage(project.identifier)"
            >
              <td rowspan="2" colspan="2" class="has-text-centered">
                <p>
                  <span>{{ project.identifier }}</span>
                  <br />
                  <span style="font-size: 0.9rem">{{ project.name }}</span>
                </p>
                <div
                  class="is-flex has-gap-2 is-align-items-center is-justify-content-center"
                >
                  <UserInfo
                    :user="getEmployeeDetails(project.lead)"
                    displayMode="minimal"
                    tooltipPosition="bottom"
                    :username="project.leadName"
                  />
                  <client-info
                    :client="getClientDetailsByName(project.clientName)"
                    displayMode="minimal"
                    tooltipPosition="bottom"
                  />
                </div>
              </td>

              <!-- actual month -->
              <td rowspan="2" class="has-grey-left-border">
                <template v-if="project.internal"> - </template>
                <template v-else>
                  {{ moneyify(project.currentMonthSummary?.all?.income) }}
                </template>
              </td>
              <td>{{ moneyify(project.currentMonthSummary?.all?.budget) }}</td>
              <td>
                {{ moneyify(project.currentMonthSummary?.all?.totalSpent) }}
              </td>
              <td rowspan="2">
                <risk-kpi-tag
                  :value="project.currentMonthSummary?.all?.riskKPI"
                />
              </td>
              <td class="has-text-right">
                {{
                  moneyify(project.currentMonthSummary?.all?.expectedSpending)
                }}
              </td>
              <td rowspan="2" class="has-text-right">
                <template v-if="project.internal"> - </template>
                <template v-else>
                  {{
                    moneyify(project.currentMonthSummary?.all?.expectedProfit)
                  }}
                </template>
              </td>

              <!-- to now -->
              <td rowspan="2" class="has-grey-left-border">
                <template v-if="project.internal"> - </template>
                <template v-else>
                  {{ moneyify(project.ytdSummary?.all?.income) }}
                </template>
              </td>
              <td>{{ moneyify(project.ytdSummary?.all?.budget) }}</td>
              <td>{{ moneyify(project.ytdSummary?.all?.totalSpent) }}</td>
              <td rowspan="2">
                <risk-kpi-tag :value="project.ytdSummary?.all?.riskKPI" />
              </td>
              <td class="has-text-right">
                {{ moneyify(project.ytdSummary?.all?.expectedSpending) }}
              </td>
              <td rowspan="2" class="has-text-right">
                <template v-if="project.internal"> - </template>
                <template v-else>
                  {{ moneyify(project.ytdSummary?.all?.expectedProfit) }}
                </template>
              </td>

              <!-- whole year -->
              <td rowspan="2" class="has-grey-left-border">
                <template v-if="project.internal"> - </template>
                <template v-else>
                  {{ moneyify(project.yearlySummary?.all?.income) }}
                </template>
              </td>
              <td>{{ moneyify(project.yearlySummary?.all?.budget) }}</td>
              <td>{{ moneyify(project.yearlySummary?.all?.totalSpent) }}</td>
              <td rowspan="2">
                <risk-kpi-tag :value="project.yearlySummary?.all?.riskKPI" />
              </td>
              <td class="has-text-right">
                {{ moneyify(project.yearlySummary?.all?.expectedSpending) }}
              </td>
              <td rowspan="2" class="has-text-right">
                <template v-if="project.internal"> - </template>
                <template v-else>
                  {{ moneyify(project.yearlySummary?.all?.expectedProfit) }}
                </template>
              </td>
            </tr>
            <tr
              class="cursor-pointer"
              @click="openProjectPage(project.identifier)"
            >
              <!-- actual month -->
              <td colspan="2">
                <div>
                  <div class="columns is-flex is-align-items-center">
                    <div
                      style="font-size: 0.8rem"
                      class="column is-one-quarter has-font-weight-500"
                    >
                      {{
                        percentify(
                          project.currentMonthSummary?.all?.costBudgetRatio
                        )
                      }}
                    </div>
                    <div class="column pl-0">
                      <b-progress
                        :value="
                          project.currentMonthSummary?.all?.costBudgetRatio
                        "
                        :max="1"
                        type="is-warning"
                        size="is-small"
                      />
                    </div>
                  </div>
                  <div class="columns is-flex is-align-items-center">
                    <div
                      style="font-size: 0.8rem"
                      class="column is-one-quarter has-font-weight-500"
                    >
                      {{
                        percentify(project.currentMonthSummary?.all?.timeRatio)
                      }}
                    </div>
                    <div class="column pl-0">
                      <b-progress
                        :value="project.currentMonthSummary?.all?.timeRatio"
                        :max="1"
                        type="is-info"
                        size="is-small"
                      />
                    </div>
                  </div>
                  <div class="is-flex has-gap-2"></div>
                </div>
              </td>
              <td class="has-text-right">
                <template v-if="project.internal"> - </template>
                <template v-else>
                  {{
                    moneyify(
                      project.currentMonthSummary?.all
                        ?.expectedSpendingDifference
                    )
                  }}
                </template>
              </td>

              <!-- to now -->
              <td colspan="2">
                <div>
                  <div class="columns is-flex is-align-items-center">
                    <div
                      style="font-size: 0.8rem"
                      class="column is-one-quarter has-font-weight-500"
                    >
                      {{ percentify(project.ytdSummary?.all?.costBudgetRatio) }}
                    </div>
                    <div class="column pl-0">
                      <b-progress
                        :value="project.ytdSummary?.all?.costBudgetRatio"
                        :max="1"
                        type="is-warning"
                        size="is-small"
                      />
                    </div>
                  </div>
                  <div class="columns is-flex is-align-items-center">
                    <div
                      style="font-size: 0.8rem"
                      class="column is-one-quarter has-font-weight-500"
                    >
                      {{ percentify(project.ytdSummary?.all?.timeRatio) }}
                    </div>
                    <div class="column pl-0">
                      <b-progress
                        :value="project.ytdSummary?.all?.timeRatio"
                        :max="1"
                        type="is-info"
                        size="is-small"
                      />
                    </div>
                  </div>
                  <div class="is-flex has-gap-2"></div>
                </div>
              </td>
              <td class="has-text-right">
                <template v-if="project.internal"> - </template>
                <template v-else>
                  {{
                    moneyify(
                      project.ytdSummary?.all?.expectedSpendingDifference
                    )
                  }}
                </template>
              </td>

              <!-- in year -->
              <td colspan="2">
                <div>
                  <div class="columns is-flex is-align-items-center">
                    <div
                      style="font-size: 0.8rem"
                      class="column is-one-quarter has-font-weight-500"
                    >
                      {{
                        percentify(project.yearlySummary?.all?.costBudgetRatio)
                      }}
                    </div>
                    <div class="column pl-0">
                      <b-progress
                        :value="project.yearlySummary?.all?.costBudgetRatio"
                        :max="1"
                        type="is-warning"
                        size="is-small"
                      />
                    </div>
                  </div>
                  <div class="columns is-flex is-align-items-center">
                    <div
                      style="font-size: 0.8rem"
                      class="column is-one-quarter has-font-weight-500"
                    >
                      {{ percentify(project.yearlySummary?.all?.timeRatio) }}
                    </div>
                    <div class="column pl-0">
                      <b-progress
                        :value="project.yearlySummary?.all?.timeRatio"
                        :max="1"
                        type="is-info"
                        size="is-small"
                      />
                    </div>
                  </div>
                  <div class="is-flex has-gap-2"></div>
                </div>
              </td>
              <td class="has-text-right">
                <template v-if="project.internal"> - </template>
                <template v-else>
                  {{
                    moneyify(
                      project.yearlySummary?.all?.expectedSpendingDifference
                    )
                  }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-pagination
        :total="pagination.value.totalCount"
        :modelValue="pagination.value.page + 1"
        :per-page="pagination.value.size"
        aria-next-label="Következő lap"
        aria-previous-label="Előző lap"
        aria-page-label="Lap"
        aria-current-label="Aktuális lap"
        @change="onPageChange"
        class="mt-4"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";
import { roundToTwoDecimals } from "@/utils/util";
import ProjectListMixin from "@/mixins/ProjectListMixin";
import { SHORT_MONTH_NAMES } from "@/utils/const";
import RiskKpiTag from "@/components/enterprise/project/module/RiskKpiTag.vue";
import SaveableObject from "@/objects/SaveableObject";
import LocalStoreMixin from "@/mixins/LocalStoreMixin";
import debounce from "lodash.debounce";
import UserInfo from "@/components/module/info/UserInfo.vue";
import ClientInfo from "@/components/module/info/ClientInfo.vue";

const LOCAL_KEY_PREFIX = "project_list_support_";

export default {
  name: "SupportProjectList.vue",
  components: { RiskKpiTag, LoadingSkeleton, UserInfo, ClientInfo },
  mixins: [ProjectListMixin, LocalStoreMixin],
  data() {
    return {
      now: new Date(),
      selectedYear: new Date().getFullYear(),
      loading: false,
      includedTypes: ["fixes", "lockups", "billables"],
      SHORT_MONTH_NAMES,
      pagination: new SaveableObject(LOCAL_KEY_PREFIX + "pagination", {
        totalCount: 0,
        page: 0,
        size: 10,
      }),
      sort: new SaveableObject(LOCAL_KEY_PREFIX + "sort", {
        field: "name",
        order: "asc",
      }),
    };
  },
  computed: {
    years() {
      let years = [];
      let year = new Date().getFullYear() + 1;
      while (year > 2019) {
        years.push(year);
        year--;
      }
      return years;
    },
    ...mapGetters({
      projects: "support_projects/projectData",
      employees: "employee/loggingEmployees",
      clients: "enterprise_clients/clients",
    }),
  },
  watch: {
    async selectedYear(newYear) {
      await this.fetchProjects(newYear);
    },
    async includedTypes(newTypes) {
      await this.fetchProjects(undefined, newTypes);
    },
    filters: {
      handler: debounce(function () {
        this.pagination.resetToDefault();
        this.fetchProjects();
      }, 500),
      deep: true,
    },
  },
  async mounted() {
    if (this.filtersLoaded) {
      await this.fetchProjects();
      // await this.$store.dispatch("employee/fetchColleagues");
      await this.$store.dispatch("enterprise_clients/fetchClients");
    }
  },
  methods: {
    roundToTwoDecimals,
    resetFilters() {
      this.pagination.resetToDefault();

      this.$emit("reset-filters");
    },

    async fetchProjects(year, newTypes, force) {
      this.loading = true;
      let requestParams = this.composeRequestParams();
      requestParams.params.set("year", year ?? this.selectedYear);
      requestParams.params.set(
        "includeFixes",
        (newTypes ?? this.includedTypes).includes("fixes")
      );
      requestParams.params.set(
        "includeLockups",
        (newTypes ?? this.includedTypes).includes("lockups")
      );
      requestParams.params.set(
        "includeBillables",
        (newTypes ?? this.includedTypes).includes("billables")
      );
      const projects = await this.$store.dispatch(
        "support_projects/fetchProjectsForYear",
        {
          requestParams,

          force,
        }
      );
      this.pagination.value = projects?.pagination
        ? projects.pagination
        : {
            totalCount: 0,
            page: 0,
            size: 20,
          };
      this.pagination.save();
      this.loading = false;
    },
    onPageChange: function (page) {
      this.pagination.value.page = page - 1;
      this.pagination.save();
      this.fetchProjects();
    },
    openProjectPage(identifier) {
      this.$router.push(`/projects/support/${identifier}`);
    },
    getEmployeeDetails(id) {
      const employee = this.employees.find((emp) => emp.id === id);
      if (employee) {
        return {
          userId: employee.id,
          name: employee.name,
          avatar: employee.avatar,
          email: employee.email,
          phone: employee.phone,
        };
      } else {
        return undefined;
      }
    },
    getClientDetailsByName(name) {
      const client = this.clients.find((client) => client.name === name);
      if (client) {
        return {
          identifier: client.identifier,
          name: client.name,
          avatar: client.avatar,
        };
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.support-project-list-table {
  width: 2400px;
  table-layout: fixed;
  border: none;
}

.support-project-list-table td,
.support-project-list-table th {
  vertical-align: middle;
  border: none;
}

.header-row td {
  font-size: 0.9rem;
  color: $custom-dark-blue;
  font-weight: 500;
}

.header-cell-bubble {
  background: $grey-light;
  color: $custom-dark-blue;
  text-align: center;
  border-radius: 8px;
  padding: 4px 0;
}

th.has-grey-left-border,
td.has-grey-left-border {
  border-left: 2px solid $grey-lighter;
}

tr.has-grey-top-border {
  border-top: 2px solid $grey-lighter;
}

.legend-stripe {
  height: 0.5rem;
  width: 1.5rem;
  border-radius: 2rem;
}

.legend-stripe-warning {
  background: #ce9700;
}

.legend-stripe-default {
  background: #167df0;
}
</style>
