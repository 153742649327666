<template>
  <div class="onboarding-step-page-main">
    <div class="onboarding-step-page-content pt-6">
      <h2>
        {{
          $tf(
            "onboarding.setupProjects.title|Projektjeid kezdeti konfiguárciója"
          )
        }}
      </h2>
      <p>
        {{
          $tf(
            "onboarding.setupProjects.dataFromJira|A legtöbb adatot a Jirából automatikusan kitöltöttük, csak pénzügyi részleteket szükséges kézzel megadnod."
          )
        }}
        <br />
        {{
          $tf(
            "onboarding.setupProjects.youCanConfigureLater|Lehetőséged lesz ezeket tovább konfigurálni, amennyiben pontosabb  adatokat szeretnél."
          )
        }}
        <br />
        {{
          $tf(
            "onboarding.setupProjects.detailsNeededForAnalisys|Ha a projektről részletesebb elemzéseket is rögtön látni szeretnél, minden mezőt ki kell töltened."
          )
        }}
        <br />
        {{
          $tf(
            "onboarding.setupProjects.okProjectsSignedWithCheck|Az ilyen, teljesen kitöltött projekteket egy pipával jelezzük. Legalább 1 projektet ajánlott kitölteni."
          )
        }}
        <br />
        {{
          $tf(
            "onboarding.setupProjects.validDataRecommended|A pénzügyi adatok nem lehetnek 0 értékűek, és 1, vagy ehhez hasonló placeholdereket sem ajánlott használni, akkor inkább hagyd üresen és töltsd ki később."
          )
        }}
      </p>
      <p>
        {{ $tf("onboarding.setupProjects.projectCount|Projektek száma: ") }}
        <b>{{ projectCount }}</b>
        {{
          $tf("onboarding.setupProjects.hiddenCount|(elrejtve: {count})", {
            count: hiddenProjectCount,
          })
        }}
      </p>
      <div class="mb-1 mt-0">
        <label class="mt-0" for="old-project-date-input">
          <p>
            {{
              $tf(
                "onboarding.setupProjects.hideOldProjects|Régi projektek elrejtése"
              )
            }}
            <b-tooltip type="is-info">
              <template v-slot:content>
                <div class="content tooltip">
                  <p class="has-text-white">
                    {{
                      $tf(
                        "onboarding.setupProjects.hideOldProjects.tooltip|Azon projektek elrejtése melyeknél az utolsó jegy módosítás dátuma korábbi a megadott dátumnál."
                      )
                    }}
                  </p>
                </div>
              </template>
              <b-icon icon="info-circle" />
            </b-tooltip>
          </p>
        </label>
        <input
          type="date"
          class="onboarding-datepicker_small"
          v-model="oldProjectDate"
          id="old-project-date-input"
        />
      </div>
      <p>
        {{
          $tf(
            "onboarding.setupProjects.totallyCorrectProjectCount|Teljesen kitöltött projektek száma: "
          )
        }}
        <b>{{ completedProjectCount }}</b>
      </p>
      <div v-if="projects.length > 0" style="color: #677d99">
        <b-switch v-model="showDetailedFields">{{
          $tf(
            "onboarding.setupProjects.showDetailedFields|Részletes adatok megadása"
          )
        }}</b-switch>
      </div>
      <div v-if="projects.length < 1">
        <p>
          {{
            $tf(
              "onboarding.setupProjects.empty|Nem találtunk egy projektet sem."
            )
          }}
        </p>
      </div>
      <div v-else class="project-setup-container">
        <table class="project-setup-table">
          <tr>
            <th style="min-width: 130px">
              {{ $tf("onboarding.setupProjects.table.tag|Előtag") }}
            </th>
            <th style="min-width: 32px"></th>
            <th style="min-width: 200px">
              {{ $tf("onboarding.setupProjects.table.name|Név") }}
            </th>
            <th style="min-width: 200px">
              {{ $tf("onboarding.setupProjects.table.lead|Projektmenedzser") }}
            </th>
            <template v-if="showDetailedFields">
              <th style="min-width: 130px">
                {{ $tf("onboarding.setupProjects.table.client|Ügyfél") }}
              </th>
              <th style="min-width: 130px">
                {{ $tf("onboarding.setupProjects.table.budget|Büdzsé") }}
              </th>
              <th style="min-width: 130px">
                {{ $tf("onboarding.setupProjects.table.commitment|Vállalás") }}
              </th>
              <th style="min-width: 130px">
                {{
                  $tf(
                    "onboarding.setupProjects.table.expectedIncome|Várható bevétel"
                  )
                }}
              </th>
              <th style="min-width: 160px">
                {{
                  $tf("onboarding.setupProjects.table.startDate|Kezdési dátum")
                }}
              </th>
              <th style="min-width: 160px">
                {{ $tf("onboarding.setupProjects.table.dueDate|Határidő") }}
              </th>
            </template>
          </tr>
          <template v-for="(project, index) in projects">
            <tr
              v-if="
                index + 1 > (page - 1) * itemsPerPage &&
                index + 1 <= page * itemsPerPage
              "
              :key="project.key"
            >
              <td>
                <div class="is-flex is-align-items-center">
                  <div>
                    {{ project.key }}
                  </div>
                  <div
                    style="font-size: 8px"
                    v-if="isProjectCompleted(project)"
                  >
                    <b-tooltip
                      :label="
                        $tf(
                          'onboarding.setupProjects.table.projectCompleted.tooltip|A projekt adatai teljesen ki vannak töltve!'
                        )
                      "
                      type="is-success"
                    >
                      <b-icon class="has-text-green" icon="check-circle" />
                    </b-tooltip>
                  </div>
                </div>
              </td>
              <td>
                <b-icon
                  icon="trash"
                  class="has-text-blue cursor-pointer"
                  size="is-small"
                  @click="openRemovalDialog(index)"
                />
              </td>
              <td><b-input size="is-small" v-model="project.name" /></td>
              <td>
                <b-select
                  size="is-small"
                  expanded
                  :modelValue="project.lead ? project.lead.accountId : null"
                  @update:modelValue="
                    (value) => setLeadForProject(value, project.key)
                  "
                >
                  <option
                    v-for="user in users"
                    :value="user.accountId"
                    :key="user.accountId"
                  >
                    {{ user.name }}
                  </option>
                </b-select>
              </td>
              <template v-if="showDetailedFields">
                <td>
                  <b-select
                    size="is-small"
                    expanded
                    v-model="project.client"
                    :disabled="clients.length < 1"
                  >
                    <option
                      v-for="client in clients"
                      :value="client"
                      :key="client"
                    >
                      {{ client }}
                    </option>
                  </b-select>
                </td>
                <td>
                  <div class="is-flex is-align-items-center has-gap-1">
                    <b-input v-model="project.budget" size="is-small" />
                    <span style="font-size: 12px">{{ currency.symbol }}</span>
                  </div>
                </td>
                <td>
                  <div class="is-flex is-align-items-center has-gap-1">
                    <b-input v-model="project.commitment" size="is-small" />
                    <span style="font-size: 12px">MWD</span>
                  </div>
                </td>
                <td>
                  <div class="is-flex is-align-items-center has-gap-1">
                    <b-input v-model="project.expectedIncome" size="is-small" />
                    <span style="font-size: 12px">{{ currency.symbol }}</span>
                  </div>
                </td>
                <td>
                  <input
                    type="date"
                    class="onboarding-datepicker"
                    v-model="project.startDate"
                  />
                </td>
                <td>
                  <input
                    type="date"
                    class="onboarding-datepicker"
                    v-model="project.dueDate"
                  />
                </td>
              </template>
            </tr>
          </template>
        </table>
      </div>
      <div
        v-if="projects.length > 0"
        class="is-flex is-justify-content-space-between mt-3"
      >
        <b-button
          class="onboarding-button onboarding-button-transparent onboarding-button-small"
          icon-left="arrow-up"
          @click="scrollToTop"
        >
          {{ $tf("onboarding.setupProjects.jumpToTop|Ugrás a tetejére") }}
        </b-button>
        <div class="is-flex has-gap-2 is-align-items-center">
          <b-button
            class="onboarding-button onboarding-button-transparent onboarding-button-small onboarding-pagination-button"
            @click="page--"
            :disabled="page === 1"
          >
            <b-icon icon="chevron-left" size="is-small" />
          </b-button>
          <div class="pagination-number">{{ page }} / {{ maxPage }}</div>
          <b-button
            class="onboarding-button onboarding-button-transparent onboarding-button-small onboarding-pagination-button"
            @click="page++"
            :disabled="page === maxPage"
          >
            <b-icon icon="chevron-right" size="is-small" />
          </b-button>
        </div>
      </div>
    </div>
    <div class="onboarding-step-page-footer">
      <div class="is-flex ml-auto has-gap-2">
        <b-button
          class="onboarding-button onboarding-button-transparent onboarding-button-small"
          @click="stepBack"
        >
          {{ $tf("onboarding.setupProjects.back|Vissza") }}
        </b-button>
        <b-button
          class="onboarding-button onboarding-button-small"
          type="is-info"
          @click="stepNext"
        >
          {{ $tf("onboarding.setupProjects.continue|Tovább") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ONBOARDING_PAGES } from "@/utils/const";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import { formatDate } from "@/utils/util";

export default {
  name: "OnboardingSetupProjects",
  components: {},
  data: function () {
    return {
      ONBOARDING_PAGES,
      projects: [],
      itemsPerPage: 30,
      page: 1,
      showDetailedFields: true,
      oldProjectDate: new Date(),
    };
  },
  created() {
    this.projects = cloneDeep(this.$store.getters["onboarding/getProjects"]);
    this.oldProjectDate.setMonth(this.oldProjectDate.getMonth() - 6);
    this.oldProjectDate = formatDate(this.oldProjectDate);
  },
  computed: {
    ...mapGetters({
      users: "onboarding/getUsers",
      currency: "onboarding/getCurrency",
      clients: "onboarding/getClientIdentifiers",
    }),
    maxPage() {
      return Math.ceil(this.projects.length / this.itemsPerPage);
    },
    projectCount() {
      return this.projects.length;
    },
    allProjectCount() {
      return this.$store.getters["onboarding/getProjects"]?.length ?? 0;
    },
    hiddenProjectCount() {
      return this.allProjectCount - this.projectCount;
    },
    completedProjectCount() {
      return this.projects.filter((pr) => this.isProjectCompleted(pr)).length;
    },
  },
  watch: {
    projectCount() {
      if (this.page > this.maxPage) {
        this.page = this.maxPage;
      }
    },
    oldProjectDate() {
      this.projects = cloneDeep(
        this.$store.getters["onboarding/getProjects"].filter(
          (pr) =>
            !this.oldProjectDate ||
            !pr.lastIssueUpdateTime ||
            new Date(pr.lastIssueUpdateTime) > new Date(this.oldProjectDate)
        )
      );
    },
  },
  methods: {
    async save() {
      await this.$store.dispatch("onboarding/updateProjects", this.projects);
    },
    async stepBack() {
      await this.save();
      this.$emit("nextPage", ONBOARDING_PAGES.SETUP_CLIENTS);
    },
    async stepNext() {
      await this.save();
      this.$emit("nextPage", ONBOARDING_PAGES.FINISH);
    },
    removeProject(index) {
      this.projects.splice(index, 1);
    },
    setLeadForProject(leadId, projectKey) {
      let prIndex = this.projects.findIndex((pr) => pr.key === projectKey);
      this.projects[prIndex].lead = this.users.find(
        (u) => u.accountId === leadId
      );
    },
    scrollToTop() {
      document.querySelector(".onboarding-step-page-content").scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    openRemovalDialog(index) {
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "onboarding.setupProjects.removalModal.title|Projekt törlése"
        ),
        message: this.$tf(
          "onboarding.setupProjects.removalModal.message|A projekt törlése nem vonható vissza!"
        ),
        confirmText: this.$tf(
          "onboarding.setupProjects.removalModal.confirm|Törlés"
        ),
        cancelText: this.$tf(
          "onboarding.setupProjects.removalModal.cancel|Mégse"
        ),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.removeProject(index),
      });
    },
    isProjectCompleted(project) {
      return (
        project.name &&
        project.dueDate &&
        project.startDate &&
        project.lead &&
        project.client &&
        project.budget &&
        project.commitment &&
        project.expectedIncome
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
.project-setup-container {
  margin-top: 12px;
  padding: 18px 12px;
  font-size: 14px;
  line-height: 150%;
  max-width: calc(100vw - 580px);
  min-width: 792px;
  overflow-x: auto;
}

.project-setup-table {
  width: auto;
  overflow-x: scroll;
}

.project-setup-table > tr > td,
.project-setup-table > tr > th {
  border: none;
  vertical-align: middle;
}

.project-setup-table > tr > th {
  border-bottom: 1px solid $grey-light;
  color: $grey-light;
  font-weight: normal;
  font-size: 14px;
}

.project-setup-table > tr > td {
  font-weight: 500;
  color: $custom-dark-blue;
  font-size: 16px;
}

.onboarding-datepicker {
  height: 30px;
  font-size: 0.8rem;
  font-family: Roboto, serif;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  color: #363636;
  padding: 0 6px;
  width: 100%;

  &_small {
    height: 30px;
    font-size: 0.8rem;
    font-family: Roboto, serif;
    box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
    color: #363636;
    padding: 0 6px;
    width: auto;
  }
}

.onboarding-pagination-button,
.pagination-number {
  width: 24px;
  height: 24px;
}

.pagination-number {
  padding-left: 8px;
  padding-right: 8px;
  height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

th.required::after {
  content: " *";
  font-weight: 700;
  color: $red;
}
</style>
