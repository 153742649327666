<template>
  <b-tag
    :style="{
      'background-color': indicator?.color,
    }"
  >
    {{ indicator?.name }}
  </b-tag>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "IndicatorTag",
  props: {
    indicatorId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    indicator() {
      return (
        this.indicators?.find(
          (indicator) => indicator.id === this.indicatorId
        ) ?? { name: this.$tf("indicators.unknown|Ismeretlen") }
      );
    },
    ...mapGetters({
      indicators: "user_indicator/indicators",
    }),
  },
};
</script>

<style scoped lang="scss"></style>
