<template>
  <div id="app">
    <div
      v-if="(configLoaded && validTenant) || onboardingPage"
      class="layout-wrapper"
      :class="{
        'login-layout-wrapper': isLoginLayout,
        'login-dark-background': getCurrentTheme() === UI_THEMES.DARK,
        'onboarding-layout-wrapper': onboardingPage,
      }"
    >
      <side-bar v-if="showSidebar" />
      <div
        class="content"
        :style="{ 'overflow-x': onboardingPage ? 'hidden' : 'auto' }"
      >
        <router-view v-show="pageLoaded" />
        <page-loading v-if="!pageLoaded" />
      </div>
      <loading-indicator>&nbsp;</loading-indicator>
    </div>
    <div
      v-else-if="!configLoaded && tenantExamined && !validTenant"
      class="layout-wrapper"
    >
      <PortalError />
    </div>
    <div v-else-if="configLoaded && tenantExamined && !validTenant">
      <div v-if="tenantId">Invalid Tenant: {{ tenantId }}</div>
      <div v-else>
        <PortalOther />
      </div>
    </div>
    <div v-else-if="!tenantId">
      <PortalOther />
    </div>
  </div>
</template>
<script>
import network from "./utils/network";
import PageLoading from "./components/module/loading/PageLoading";
import { EVENTS } from "./utils/eventBus";
import PortalOther from "@/views/Other.vue";
import {
  changeTheme,
  getCurrentTheme,
  localFetch,
  tenantIdFromPath,
} from "@/utils/util";
import { UI_THEMES } from "@/utils/const";
import { loadLanguageAsync } from "@/plugins/i18n";
import PortalError from "@/views/Error.vue";
import { defineAsyncComponent } from "vue";

export default {
  name: "PortalHome",
  methods: { getCurrentTheme },
  components: {
    PortalError,
    PortalOther,
    PageLoading,
    SideBar: defineAsyncComponent(() =>
      import("@/components/sidebar/SideBar.vue")
    ),
    LoadingIndicator: defineAsyncComponent(() =>
      import("@/components/module/loading/LoadingIndicator")
    ),
  },
  computed: {
    tenantIdFromPath,
    UI_THEMES() {
      return UI_THEMES;
    },
    isLoginLayout() {
      return this.$route.meta ? this.$route.meta.loginLayout : false;
    },
    showSidebar() {
      return !this.$route.meta || !this.$route.meta.hideSidebar;
    },
    configLoaded() {
      return (
        this.$store.getters["uiConfigStore/isLoaded"] ||
        ["SignIn", "Other", "NotFound"].includes(this.$route.name)
      );
    },
  },
  async created() {
    this.tenantId = this.tenantIdFromPath;

    network.configure(this.$store, this.$router, this.$buefy);

    // eventBus.$on(EVENTS.PAGE_LOADING, () => (this.pageLoaded = false));
    // eventBus.$on(EVENTS.PAGE_LOADED, () => (this.pageLoaded = true));

    await this.$store.dispatch("env/fetch");

    let storedLocale = localFetch("stored_locale");
    if (storedLocale) {
      await loadLanguageAsync(storedLocale);
    } else if (this.$store.getters["session/isReady"]) {
      await this.$store.dispatch("census_user/fetchProfile");
      const fetchedLocale = this.$store.getters["census_user/profile"]?.lang;
      if (fetchedLocale) {
        await loadLanguageAsync(fetchedLocale);
      }
    } else {
      // default lang
      await loadLanguageAsync("hu");
    }

    if (this.$route.name === "Onboarding") {
      this.onboardingPage = true;
      return;
    }

    if (this.tenantId) {
      try {
        await this.$store.dispatch("uiConfigStore/fetch", this.tenantId);

        this.validTenant = true;

        document
          .querySelector('link[rel="icon"]')
          .setAttribute("href", process.env.VUE_APP_FAVICON);
      } catch (e) {
        this.validTenant = false;
      } finally {
        this.tenantExamined = true;
      }
    } else {
      this.tenantExamined = true;
    }

    if (this.$store.getters["session/isReady"]) {
      await this.$store.dispatch("user_indicator/fetch");
      await this.$store.dispatch("employee/fetchEmployees");
    }

    this.$store.commit(
      "uiConfigStore/setCurrentTheme",
      localFetch("current_ui_theme")
    );
    changeTheme(localFetch("current_ui_theme"));
  },
  data() {
    return {
      EVENTS,
      pageLoaded: true,
      tenantId: null,
      tenantExamined: false,
      validTenant: false,
      onboardingPage: false,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/media-queries.scss";

body {
  overflow: auto;
}

// "Tooltips is not showing correctly when use css zoom"
// https://github.com/apexcharts/apexcharts.js/issues/2884
// Also fixes other, more minor issues based on apexcharts thinking its canvas is the wrong size
// (Such as missing xaxis labels on certain charts in certain sizes,
// and column hover "highlights" being off by a few pixels on bar charts)
.vue-apexcharts {
  @include xl() {
    zoom: calc(1 / 0.9);
  }
  @include lg-max() {
    // 0.01 for spice (do not delete)
    zoom: calc(1 / 0.85 + 0.01);
  }
}
</style>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
@import "~@/assets/scss/media-queries.scss";

@include xl() {
  #app {
    zoom: 0.9;
  }
}

@include lg-max() {
  #app {
    zoom: 0.85;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  min-width: 1280px;
}

.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-basis: 0;

  .content {
    flex-grow: 1;
  }
}

.login-layout-wrapper {
  background-image: url("~@/assets/images/login_background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.login-dark-background {
    background-image: url("~@/assets/images/login_background_dark.jpg");
  }
}

.onboarding-layout-wrapper {
  background-color: $custom-dark-blue;

  .content {
    margin-bottom: 0;
  }
}
</style>
