<template>
  <div
    @click="onClick"
    @mouseover="toggleStar"
    @mouseout="restoreStar"
    class="star-container"
    :class="{ 'cursor-pointer': clickable, wider: !!text }"
  >
    <b-icon :icon="f ? 'star' : 'star-half-stroke'" :class="{ starred: f }" />
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "StarIcon",
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
  },
  data() {
    return {
      f: this.$props.filled,
    };
  },
  methods: {
    toggleStar() {
      if (this.clickable) this.f = !this.filled;
    },
    restoreStar() {
      if (this.clickable) this.f = this.filled;
    },
    onClick() {
      if (this.clickable) this.$emit("change");
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/colors.scss";

.star-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  span.icon {
    svg {
      width: 2rem !important;
    }
  }
  font-weight: 500;
  font-size: 1rem;
  line-height: 130%;

  color: $custom-dark-blue;
}
.starred {
  color: $yellow;
}
.wider {
  width: fit-content !important;
}
</style>
