<template>
  <b-collapse
    class="card container configuration-warnings"
    animation="slide"
    aria-id="config-messages-collapse"
    v-model="warningsOpen"
  >
    <template #trigger="">
      <div class="card-header" role="button" :aria-expanded="warningsOpen">
        <p class="card-header-title m-0">
          {{
            $tf(
              "project.configuration.hasErrors|Konfigurációs hibák / Karbantartási hiányosságok vannak a projektben!"
            )
          }}
        </p>
        <span class="configuration-warning-tag-container">
          <div class="configuration-warning-tag error-tag">
            <b-icon icon="exclamation-triangle"></b-icon>
            {{
              $tf("project.configuration.criticalErrors.count|{number} db", {
                number: numberOfCritical,
              })
            }}
          </div>

          <div class="configuration-warning-tag warning-tag">
            <b-icon icon="exclamation-triangle"></b-icon>
            {{
              $tf("project.configuration.amberErrors.count|{number} db", {
                number: numberOfAmber,
              })
            }}
          </div>

          <div class="configuration-warning-tag info-tag">
            <b-icon icon="wrench"></b-icon>
            {{
              $tf("project.configuration.todoErrors.count|{number} db", {
                number: numberOfTodo,
              })
            }}
          </div>
        </span>
        <a class="card-header-icon">
          <b-icon :icon="warningsOpen ? 'chevron-up' : 'chevron-down'" />
        </a>
      </div>
    </template>
    <div class="card-content">
      <article class="panel is-danger message-error" v-if="numberOfCritical">
        <p class="panel-heading">
          {{
            $tf(
              "project.configuration.criticalErrors.header|Kritikus konfigurációs hibák"
            )
          }}
          <span class="configuration-warning-tag error-tag">
            <b-icon icon="exclamation-triangle"></b-icon>
            {{
              $tf("project.configuration.criticalErrors.count|{number} db", {
                number: numberOfCritical,
              })
            }}
          </span>
        </p>
        <b-message v-if="coreDataMissing" has-icon icon="dumpster-fire">
          {{
            $tf(
              "project.configuration.coreDataMissing|A rendszer alapvető működéséhez szükséges adatok nem kitöltöttek! Kérjük töltsd ki a {missingString} mezőt!",
              { missingString: coreDataMissing }
            )
          }}
        </b-message>
        <b-message v-if="noTigs" has-icon icon="dumpster-fire">
          {{
            $tf(
              "project.configuration.noTigs|A Projekten nem találhatóak még mérföldkövek. Jegyek kizárólag Mérföldkövekhez köthetőek, kérjük vegyél fel legalább egyet!"
            )
          }}
        </b-message>
        <b-message
          v-if="tigTargetingMissing && !isSelfTracking"
          type="is-danger"
          has-icon
          icon="dumpster-fire"
        >
          {{
            $tf(
              "project.configuration.tigTargetingMissing|A Projektben található olyan Mérföldkő, melyhez nincs jegyek revonatkozó célzás beállítva. Az ilyen Mérföldkövekkel a Rendszer nem tud érdemi számításokat végezni!"
            )
          }}
        </b-message>
        <template v-if="!loading">
          <b-message
            v-if="potentialUnknownsPresent"
            has-icon
            icon="dumpster-fire"
          >
            {{
              $tf(
                "project.configuration.potentialUnknownsPresent|Léteznek olyan ismeretlen logolások a rendszerben, melyek prefixe megegyezik ennek a projektnek a prefixével! Ellenőrizd hogy az alábbi jegyeket a Mérföldkövek célzásának (vagy a jegyek attribútumainak) megfelelő módosításával ehhez a projekthez kellene-e kötni!"
              )
            }}
            <br />
            <strong
              v-for="u in unknown(projectData.prefix)"
              v-bind:key="u.issueKey"
              >{{ u.issueKey }},
            </strong>
          </b-message>
          <b-message v-if="sharedIssuesPresent" has-icon icon="dumpster-fire">
            {{
              $tf(
                "project.configuration.sharedIssuesPresent|Léteznek olyan jegyek, melyeket több mérföldkő / projekt is figyelembe vesz! Ellenőrizd, hogy az alábbi jegyeket célzó Mérföldkövek beállítása helyes-e!"
              )
            }}
            <br />
            <strong
              v-for="u in shared(projectData.prefix)"
              v-bind:key="u.issueKey"
            >
              {{ u.issueKey }}
              <b-tooltip :label="u.tigName"
                ><b-icon
                  icon="exclamation-triangle"
                  size="is-small"
                  class="untracked-triangle"
              /></b-tooltip>
            </strong>
          </b-message>
          <b-message v-if="illegalLogsPresent" has-icon icon="dumpster-fire">
            {{
              $tf(
                "project.configuration.illegalLogsPresent|Léteznek olyan logok, melyek dátuma a projekt indulása előtt vagy a lezárása után van! ({number} db)",
                { number: projectDetails.illegalWorklogs }
              )
            }}
          </b-message>
        </template>
      </article>
      <article class="message-warning panel is-warning" v-if="numberOfAmber">
        <p class="panel-heading">
          {{ $tf("project.configuration.amberErrors.header|Figyelmeztetések") }}
          <span class="configuration-warning-tag warning-tag">
            <b-icon icon="exclamation-triangle"></b-icon>
            {{
              $tf("project.configuration.amberErrors.count|{number} db", {
                number: numberOfAmber,
              })
            }}
          </span>
        </p>
        <b-message v-if="tigTicketsMissing" has-icon icon="engine-warning">
          {{
            $tf(
              "project.configuration.tigTicketsMissing|A Projektben található olyan Mérföldkő, melyhez a célzása mentén nem tartozik egyetlen jegy sem. Biztos hogy helyesen állítottad be a célzást?"
            )
          }}
        </b-message>
        <b-message v-if="readinessEstimation" has-icon icon="engine-warning">
          {{
            $tf(
              "project.configuration.readinessEstimation|A Projekt Készültségét kalkuláló automata módszerek már 100% felettire számolnák a Készültséget! Ez jelentős túlköltésre vagy rossz beállításokra utal!"
            )
          }}
        </b-message>
        <b-message v-if="billsMissing" has-icon icon="engine-warning">
          {{
            $tf(
              "project.configuration.billsMissing|Léteznek lezárt Mérföldkövek a Projekten, de nincs Bevétel (Számla) rögzítve a projektre! Kérjük pótold az elmaradást a bevételek megfelelő számításához."
            )
          }}
        </b-message>
        <b-message v-if="unestimatedCount" has-icon icon="engine-warning">
          {{
            $tf(
              "project.configuration.unestimatedCount|A Projekt jegyeinek {percent}%-án nincsen becslés! A becslés nélküli jegyek rontják a Forecast működését és növelik a Projekt kockázatát!",
              { percent: unestimatedCount }
            )
          }}
        </b-message>
        <b-message v-if="estimatedCommitment" has-icon icon="engine-warning">
          {{
            $tf(
              "project.configuration.estimatedCommitment|A Projekt jegyein lévő becslések összessége {percent}%-al eltér a Vállalástól! A Forecast és a Készültséget számoló funkciók ekkora eltérésnél nem tudnak megbízhatóan működni!",
              { percent: estimatedCommitment }
            )
          }}
        </b-message>
      </article>
      <article class="message-info panel is-info" v-if="numberOfTodo">
        <p class="panel-heading">
          {{
            $tf("project.configuration.todoErrors.header|Karbantartási teendők")
          }}
          <span class="configuration-warning-tag info-tag">
            <b-icon icon="wrench"></b-icon>
            {{
              $tf("project.configuration.todoErrors.count|{number} db", {
                number: numberOfTodo,
              })
            }}
          </span>
        </p>
        <b-message v-if="badBudget" has-icon icon="lightbulb-cfl-on">
          {{
            $tf(
              "project.configuration.badBudget|A Projektre beállított Büdzsé ({budget}) ~{percent}%-al eltér a Vállalásból az Általános Óradíjjal számított értéktől, ami {commitmentBudget}) lenne!",
              {
                budget: moneyify(this.projectDetails.budget),
                percent: badBudget,
                commitmentBudget: moneyify(
                  this.projectDetails.commitmentBudget
                ),
              }
            )
          }}
        </b-message>
        <b-message v-if="budgetTigSumMismatch" has-icon icon="lightbulb-cfl-on">
          {{
            $tf(
              "project.configuration.budgetTigSumMismatch|A Projekt Mérföldköveiben beállított Büdzsék összege {diff}, ami több mint 10%-al eltér a Projektre beállított Büdzsétől!",
              { diff: moneyify(budgetTigSumDiff) }
            )
          }}
        </b-message>
        <b-message
          v-if="commitmentTigSumMismatch"
          has-icon
          icon="lightbulb-cfl-on"
        >
          {{
            $tf(
              "project.configuration.commitmentTigSumMismatch|A Projekt Mérföldköveiben beállított Vállalások összege {diff}, ami több mint 10%-al eltér a Projektre beállított Vállalástól!",
              { diff: workdayify(commitmentTigSumDiff) }
            )
          }}
        </b-message>
        <b-message v-if="incomeTigSumMismatch" has-icon icon="lightbulb-cfl-on">
          {{
            $tf(
              "project.configuration.commitmentTigSumMismatch|A Projekt Mérföldköveiben beállított Számlázható Összegek összege {diff}, ami több mint 10%-al eltér a Projektre beállított előirányzott Bevételtől!",
              { diff: workdayify(incomeTigSumDiff) }
            )
          }}
        </b-message>
        <b-message v-if="pastDue" has-icon icon="lightbulb-cfl-on">
          {{
            $tf(
              "project.configuration.pastDue|A Projekt határideje már ~{number} napja lejárt, de még nincs lezárva!",
              { number: pastDue }
            )
          }}
        </b-message>
        <b-message v-if="pastDueTig" has-icon icon="lightbulb-cfl-on">
          {{
            $tf(
              "project.configuration.pastDueTig|A Projektben létezik lezáratlan, több mint két hete lejárt határidejű Mérföldkő!"
            )
          }}
        </b-message>
        <b-message v-if="problematicIssues" has-icon icon="lightbulb-cfl-on">
          {{
            $tf(
              "project.configuration.problematicIssues.1|A projekt egyes jegyein található logolás összeg nem egyezik a rájuk vonatkozó tényleges időlogok összegével!"
            )
          }}<br />
          {{
            $tf(
              "project.configuration.problematicIssues.2|Az érintett jegyek: "
            )
          }}
          <strong v-for="i in projectDetails.problematic" v-bind:key="i">
            {{ i }}
          </strong>
        </b-message>
      </article>
    </div>
  </b-collapse>
</template>

<script>
import { moneyify, workdayify } from "@/utils/util";
import { mapGetters } from "vuex";
import { ISSUE_TRACKING_SYSTEM } from "@/utils/const";

export default {
  name: "ConfigurationWarnings",
  components: {},
  mixins: [],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      moneyify,
      workdayify,
      loading: true,
      warningsOpen: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    isSelfTracking() {
      return this.issueTrackingSystem === ISSUE_TRACKING_SYSTEM.SELF.enum;
    },
    ...mapGetters({
      shared: "risk_management/getSharedIssuesFiltered",
      unknown: "risk_management/getUnknownIssuesFiltered",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
    numberOfCritical() {
      let number = 0;
      if (this.coreDataMissing) ++number;
      if (this.noTigs) ++number;
      if (this.tigTargetingMissing && !this.isSelfTracking) ++number;
      if (this.potentialUnknownsPresent) ++number;
      if (this.sharedIssuesPresent) ++number;
      if (this.illegalLogsPresent) ++number;
      return number;
    },
    numberOfAmber() {
      let number = 0;
      if (this.tigTicketsMissing) ++number;
      if (this.readinessEstimation) ++number;
      if (this.unestimatedCount) ++number;
      if (this.estimatedCommitment) ++number;
      return number;
    },
    numberOfTodo() {
      let number = 0;
      if (this.badBudget) ++number;
      if (this.budgetTigSumMismatch) ++number;
      if (this.commitmentTigSumMismatch) ++number;
      if (this.incomeTigSumMismatch) ++number;
      if (this.pastDue) ++number;
      if (this.pastDueTig) ++number;
      if (this.problematicIssues) ++number;
      return number;
    },
    coreDataMissing() {
      const missing = [];
      if (!this.projectData.dueDate)
        missing.push(
          this.$tf("project.configuration.coreData.dueDate|határidő")
        );
      if (!this.projectDetails.budget)
        missing.push(this.$tf("project.configuration.coreData.budget|büdzsé"));
      if (!this.projectData.commitment)
        missing.push(
          this.$tf("project.configuration.coreData.commitment|vállalás")
        );
      if (!this.projectData.internal && !this.projectDetails.expectedIncome)
        missing.push(this.$tf("project.configuration.coreData.income|bevétel"));
      return missing.join(", ");
    },
    noTigs() {
      return (
        !this.projectDetails.tigDetails ||
        !this.projectDetails.tigDetails.length
      );
    },
    potentialUnknownsPresent() {
      return !!this.unknown(this.projectData.prefix).length;
    },
    sharedIssuesPresent() {
      return !!this.shared(this.projectData.prefix).length;
    },
    illegalLogsPresent() {
      return !!this.projectDetails.illegalWorklogs;
    },
    problematicIssues() {
      return (
        this.projectDetails &&
        this.projectDetails.problematic &&
        !!this.projectDetails.problematic.length
      );
    },
    badBudget() {
      if (
        Math.abs(
          this.projectDetails.budget - this.projectDetails.commitmentBudget
        ) >
        this.projectDetails.budget * 0.25
      ) {
        return (
          Math.round(
            (Math.abs(
              this.projectDetails.budget - this.projectDetails.commitmentBudget
            ) /
              this.projectDetails.budget) *
              10000
          ) / 100
        );
      } else return false;
    },
    budgetTigSumMismatch() {
      if (this.noTigs || this.coreDataMissing) return false;
      let tigSum = this.projectDetails.tigDetails.reduce(
        (acc, cur) => acc + cur.budget,
        0
      );
      return (
        Math.abs(this.projectDetails.budget - tigSum) >
        this.projectDetails.budget * 0.1
      );
    },
    budgetTigSumDiff() {
      let tigSum = this.projectDetails.tigDetails.reduce(
        (acc, cur) => acc + cur.budget,
        0
      );
      return Math.abs(this.projectDetails.budget - tigSum);
    },
    commitmentTigSumMismatch() {
      if (this.noTigs || this.coreDataMissing) return false;
      let tigSum = this.projectDetails.tigDetails.reduce(
        (acc, cur) => acc + cur.commitment,
        0
      );
      return (
        Math.abs(this.projectDetails.commitment - tigSum) >
        this.projectDetails.commitment * 0.1
      );
    },
    commitmentTigSumDiff() {
      let tigSum = this.projectDetails.tigDetails.reduce(
        (acc, cur) => acc + cur.commitment,
        0
      );
      return Math.abs(this.projectDetails.commitment - tigSum);
    },
    incomeTigSumMismatch() {
      if (!this.projectData.internal && (this.noTigs || this.coreDataMissing))
        return false;
      let tigSum = this.projectDetails.tigDetails.reduce(
        (acc, cur) => acc + cur.billableAmount,
        0
      );
      return (
        Math.abs(this.projectDetails.expectedIncome - tigSum) >
        this.projectDetails.expectedIncome * 0.1
      );
    },
    incomeTigSumDiff() {
      if (this.projectData.internal) return undefined;
      let tigSum = this.projectDetails.tigDetails.reduce(
        (acc, cur) => acc + cur.billableAmount,
        0
      );
      return Math.abs(this.projectDetails.expectedIncome - tigSum);
    },
    tigTargetingMissing() {
      if (this.noTigs || this.coreDataMissing) return false;
      return this.projectData.tigDatas.some(
        (tig) =>
          tig.type !== "UNTRACKED" &&
          (!tig.externalIssueTargeting || !tig.externalIssueTargeting.link)
      );
    },
    tigTicketsMissing() {
      if (this.noTigs || this.coreDataMissing) return false;
      return this.projectDetails.tigDetails.some(
        (tig) =>
          tig.status !== "PLANNED" &&
          tig.externalIssueTargeting?.link &&
          !tig.issueCount
      );
    },
    billsMissing() {
      if (!this.projectData.internal && (this.noTigs || this.coreDataMissing))
        return false;
      let billed = this.projectDetails.tigDetails.some(
        (tig) => tig.status === "BILLED" || tig.status === "COMPLETED"
      );
      return billed && !this.projectDetails.invoicedIncome;
    },
    unestimatedCount() {
      if (this.noTigs || this.coreDataMissing) return false;
      let unestimatedRate =
        Math.round(
          (Math.abs(
            this.projectDetails.unestimatedIssueCount -
              this.projectDetails.issueCount
          ) /
            this.projectDetails.issueCount) *
            10000
        ) / 100;
      return unestimatedRate > 20 ? unestimatedRate : false;
    },
    readinessEstimation() {
      if (this.noTigs || this.coreDataMissing) return false;
      return (
        this.projectDetails.projectReadinessMap.BASIC > 0.989 ||
        this.projectDetails.projectReadinessMap.ESTIMATED > 0.989 ||
        this.projectDetails.projectReadinessMap.EDUCATED > 0.989
      );
    },
    estimatedCommitment() {
      if (this.noTigs || this.coreDataMissing) return false;
      let diffRate =
        Math.round(
          (Math.abs(
            this.projectDetails.sumEstimateMd - this.projectDetails.commitment
          ) /
            this.projectDetails.commitment) *
            10000
        ) / 100;
      return diffRate > 20 ? diffRate : false;
    },
    pastDue() {
      if (
        this.coreDataMissing ||
        this.projectData.status === "CANCELLED" ||
        this.projectData.status === "DONE" ||
        this.projectData.status === "CLOSED"
      )
        return false;
      let diff = new Date() - new Date(this.projectData.dueDate);
      if (diff > 1000 * 60 * 60 * 24 * 14)
        return Math.round(diff / 1000 / 60 / 60 / 24);
      else return false;
    },
    pastDueTig() {
      if (this.noTigs || this.coreDataMissing) return false;
      return this.projectData.tigDatas.some(
        (tig) =>
          (tig.status === "PENDING" || tig.status === "PLANNED") &&
          new Date() - new Date(tig.dueDate) > 1000 * 60 * 60 * 24 * 14
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.$store.dispatch("risk_management/clearCache");
      await this.$store.dispatch(
        "risk_management/fetchSharedIssues",
        this.projectData.id
      );
      await this.$store.dispatch(
        "risk_management/fetchUnknownIssues",
        this.projectData.prefix
      );
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.configuration-warnings {
  padding: 0 !important;

  .card-header {
    align-items: center;
    justify-content: center;

    &-title {
      padding: 24px !important;
      color: $custom-dark-blue !important;
    }
  }

  .configuration-warning-tag {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    padding-right: 6px;
    padding-left: 6px;

    &.error-tag {
      color: $custom-text-danger;
    }

    &.warning-tag {
      color: $configuration-warning;
    }

    &.info-tag {
      color: $forecast-secondary;
    }

    &-container {
      display: inline-block;
      padding-right: 140px;
      transform: translateY(2px);
    }
  }

  .panel-heading {
    background: $white !important;
  }

  .message {
    background: $white !important;
    margin-bottom: 10px !important;
  }

  .panel-heading {
    color: $custom-dark-blue !important;
    margin-bottom: 0 !important;
    padding-left: 0 !important;
  }

  .message-body {
    border: 1px solid #eceff5 !important;
    color: $custom-dark-blue;
    border-radius: 10px;
    font-weight: 500 !important;
    font-size: 14px !important;

    .media-left {
      display: none;
    }
  }

  .message-error {
    & .message-body {
      border-left: 5px solid $custom-text-danger !important;
    }
  }

  .message-warning {
    & .message-body {
      border-left: 5px solid $configuration-warning !important;
    }
  }

  .message-info {
    & .message-body {
      border-left: 5px solid $forecast-secondary !important;
    }
  }

  .panel {
    box-shadow: none !important;
  }
}

.full-page-message {
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
