<template>
  <div class="container">
    <h3 class="heading">
      {{ $tf("execDashboard.financial.title|Havi pénzügyek") }}
    </h3>
    <div class="columns">
      <!--      <todo-banner-->
      <!--        class="column is-three-quarters is-flex is-justify-content-space-between"-->
      <!--      >-->
      <!--        <div class="column is-one-quarter">-->
      <!--          <apex-chart-->
      <!--            type="bar"-->
      <!--            :options="getIncomeBarChartOptions"-->
      <!--            :series="incomeSeries"-->
      <!--            height="349px"-->
      <!--          />-->
      <!--        </div>-->
      <!--        <div class="column is-one-quarter">-->
      <!--          <apex-chart-->
      <!--            type="bar"-->
      <!--            :options="getExpensesBarChartOptions"-->
      <!--            :series="expenseSeries"-->
      <!--            height="330px"-->
      <!--          />-->
      <!--        </div>-->
      <!--        <div class="column is-one-quarter">-->
      <!--          <apex-chart-->
      <!--            type="bar"-->
      <!--            :options="getProfitBarChartOptions"-->
      <!--            :series="profitSeries"-->
      <!--            height="330px"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </todo-banner>-->
      <div class="column is-full">
        <apex-chart
          type="bar"
          :options="getHeadcountBarChartOptions"
          :series="numberOfEmployeeSeries"
          height="349px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_INDICATOR_TYPE_ENUM } from "@/utils/const";
import TodoBanner from "@/components/TodoBanner.vue";
import DivWithLoading from "@/components/loading/DivWithLoading.vue";
import LoadingComponent from "@/components/loading/LoadingComponent.vue";
import { randomColors } from "@/utils/util";

export default {
  name: "ExecutiveDashboardFinancial",
  props: {
    financial: {
      type: Object,
      required: true,
    },
  },
  // TO BE REMOVED
  data() {
    return {
      data: {
        income: {
          invoiced: [12, 3, 4, 6, 11, 7, 0, 0, 0, 0, 0, 0],
          uninvoiced: [3, 1, 0, 1, 2, 5, 3, 2, 0, 0, 0, 0],
        },
        expenses: [8, 7, 8, 9, 5, 8, 9, 5, 7, 8, 0, 0],
        profits: [3, 5, -1, 0, 3, 6, 3, 2, 5, 1, 0, 0],
      },
    };
  },
  computed: {
    getIncomeBarChartOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: "bottom",
          labels: {
            colors: "var(--text)",
          },
        },
        labels: this.months,
        title: {
          // FIXME: currency and prefixum
          text: this.$tf("execDashboard.financial.incomeChart|Bevétel - m Ft"),
          align: "center",
          style: {
            fontFamily: "Roboto, sans-serif",
            color: "var(--text)",
          },
        },
        colors: ["var(--green)", "var(--red)"],
        plotOptions: {
          bar: {
            //distributed: true,
            borderRadius: 4,
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true,
            style: {
              fontSize: "10px",
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
      };
    },
    getExpensesBarChartOptions() {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        labels: this.months,
        title: {
          // FIXME: currency and prefixum
          text: this.$tf("execDashboard.financial.spentChart|Költés - m Ft"),
          align: "center",
          style: {
            fontFamily: "Roboto, sans-serif",
            color: "var(--text)",
          },
        },
        colors: ["var(--purple)"],
        plotOptions: {
          bar: {
            borderRadius: 4,
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true,
            style: {
              fontSize: "10px",
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
      };
    },
    getProfitBarChartOptions() {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        labels: this.months,
        title: {
          // FIXME: currency and prefixum
          text: this.$tf("execDashboard.financial.profitChart|Profit - m Ft"),
          align: "center",
          style: {
            fontFamily: "Roboto, sans-serif",
            color: "var(--text)",
          },
        },
        colors: ["var(--green)"],
        plotOptions: {
          bar: {
            borderRadius: 4,
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true,
            style: {
              fontSize: "10px",
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
      };
    },
    getHeadcountBarChartOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: "bottom",
          labels: {
            colors: "var(--text)",
          },
        },
        labels: this.months,
        title: {
          text: this.$tf(
            "execDashboard.financial.headcountChart.title|Létszám"
          ),
          align: "center",
          style: {
            fontFamily: "Roboto, sans-serif",
            color: "var(--text)",
          },
        },
        colors: this.randomColors(this.titles.length),
        plotOptions: {
          bar: {
            //distributed: true,
            borderRadius: 4,
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true,
            style: {
              fontSize: "10px",
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
      };
    },
    months() {
      return Object.keys(this.financial.numberOfEmployees);
    },
    incomeSeries() {
      const series = [];

      const seriesObjectInvoiced = {
        data: [],
        name: this.$tf("execDashboard.financial.billed|Számlázott"),
      };

      if (typeof this.financial.income === Object) {
        for (const [key, value] of Object.entries(this.financial.income)) {
          seriesObject.data.push(value.invoiced);
        }
      }

      series.push(seriesObjectInvoiced);

      const seriesObjectNotInvoiced = {
        data: [],
        name: this.$tf("execDashboard.financial.unbilled|Nem számlázott"),
      };

      if (typeof this.financial.income === Object) {
        for (const [key, value] of Object.entries(this.financial.income)) {
          seriesObject.data.push(value.notInvoiced);
        }
      }

      series.push(seriesObjectNotInvoiced);

      return series;
    },
    expenseSeries() {
      const series = [];

      const seriesObject = {
        data: [],
        name: this.$tf("execDashboard.financial.expenses|Költség"),
      };

      if (typeof this.financial.expense === Object) {
        for (const [key, value] of Object.entries(this.financial.expense)) {
          seriesObject.data.push(value);
        }
      }

      series.push(seriesObject);

      return series;
    },
    profitSeries() {
      const series = [];

      const seriesObject = {
        data: [],
        name: this.$tf("execDashboard.financial.profit|Profit"),
      };

      if (typeof this.financial.profit === Object) {
        for (const [key, value] of Object.entries(this.financial.profit)) {
          seriesObject.data.push(value);
        }
      }

      series.push(seriesObject);

      return series;
    },
    numberOfEmployeeSeries() {
      const series = [];

      this.titles.forEach((title) => {
        const seriesObject = { data: [], name: title };

        for (const [key, value] of Object.entries(
          this.financial?.numberOfEmployees
        )) {
          const role = value.find((t) => t.type === title);
          seriesObject.data.push(role ? role.number : 0);
        }

        series.push(seriesObject);
      });

      return series;
    },
    titles() {
      return this.indicators
        .filter((i) => i.type === USER_INDICATOR_TYPE_ENUM.DEPARTMENT)
        .map((i) => i.name);
    },
    ...mapGetters({
      indicators: "user_indicator/indicators",
    }),
  },
  methods: {
    randomColors,
  },
};
</script>

<style scoped></style>
