<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $tf("logging.title|Munkaidő rögzítése") }}</h1>
      <b-tabs type="is-toggle" v-model="activeTab">
        <b-tab-item
          :value="LOGGING_TABS.CALENDAR"
          :label="$tf('logging.tab.calendar|Naptár nézet')"
          icon="calendar"
          icon-pack="fad"
        >
          <logging-calendar />
        </b-tab-item>
        <b-tab-item
          :value="LOGGING_TABS.IMPORT"
          :label="$tf('logging.tab.import|Excel Import')"
          icon="file-excel"
          icon-pack="fad"
        >
          <logging-import />
        </b-tab-item>
        <b-tab-item
          :value="LOGGING_TABS.COUNTER"
          :label="$tf('logging.tab.counter|Counter')"
          icon="stopwatch"
          icon-pack="fad"
        >
          <logging-counter />
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { LOGGING_TABS } from "@/utils/const";
import LoggingCalendar from "@/components/enterprise/logging/LoggingCalendar.vue";
import LoggingCounter from "@/components/enterprise/logging/LoggingCounter.vue";
import LoggingImport from "@/components/enterprise/logging/LoggingImport.vue";

export default {
  name: "LoggingView",
  components: { LoggingImport, LoggingCounter, LoggingCalendar },
  data() {
    return {
      LOGGING_TABS,
      activeTab: LOGGING_TABS.CALENDAR,
    };
  },
};
</script>

<style scoped lang="scss"></style>
