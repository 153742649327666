import { newLogger } from "@/utils/util";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("ChangeThis");

const urls = {
  counter: "/enterprise/counter",
  close: (date) => `enterprise/counter/close?date=${date}`,
};

const initState = () => {
  return {
    counters: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchCounters: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.counter,
        null,
        true,
        dispatch,
        "Számlálók letöltése...",
        null,
        true
      );
      commit("saveCounters", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  startCounter: async function ({ commit, dispatch }, { issueId, override }) {
    try {
      await this.remoteRequest(
        "post",
        `${urls.counter}/${issueId}?override=${override}`,
        null,
        true,
        dispatch,
        "Számláló indítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  stopCounter: async function ({ commit, dispatch }, id) {
    try {
      await this.remoteRequest(
        "put",
        `${urls.counter}`,
        null,
        true,
        dispatch,
        "Számláló leállítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  closeDay: async function ({ commit, dispatch }, { date, worklogs }) {
    try {
      await this.remoteRequest(
        "post",
        `${urls.close(date)}`,
        worklogs,
        true,
        dispatch,
        "Nap zárása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveCounters: (state, data) => {
    state.counters = data;
  },
};

const getters = {
  getCounters: (state) => {
    return state.counters;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
