<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $tf("quotaList.title|Szabadságkvóták") }}</h1>
      <h2 class="subtitle">
        {{ $tf("quotaList.title|Éves szabadság kvóták megadása, módosítása") }}
      </h2>
      <hr />

      <div class="columns">
        <div class="column is-2 is-offset-5 card is-centered has-text-centered">
          <b-field
            :label="$tf('quotaList.year|Év')"
            label-position="on-border"
            expanded
          >
            <b-select
              v-model="selectedYear"
              @update:modelValue="setSelectedYear(selectedYear)"
              :placeholder="$tf('quotaList.year.placeholder|Cél év')"
              icon="calendar-alt"
              expanded
            >
              <option v-for="year in years" v-bind:key="year" :value="year">
                {{ year }}
              </option>
            </b-select>
          </b-field>
          <b-field>
            <b-button type="is-info" @click="jumpEditor">
              {{ $tf("quotaList.openQuotaEditor|Kvótaszerkesztő") }}
            </b-button>
          </b-field>
        </div>
      </div>

      <b-field grouped group-multiline>
        <div v-if="filter['user']" class="control">
          <b-taglist v-if="filter['user']" attached class="filter-tags">
            <b-tag type="is-dark">
              {{ $tf("quotaList.filter.name|Név") }}
            </b-tag>
            <b-tag
              type="is-danger"
              closable
              aria-close-label="Remove filter"
              @close="removeFilter('user')"
            >
              <span v-for="user in filter['user']" :key="user.id"
                >{{ user.name
                }}<span
                  v-if="
                    filter['user'].indexOf(user) !== filter['user'].length - 1
                  "
                  >,
                </span></span
              >
            </b-tag>
          </b-taglist>
        </div>
      </b-field>

      <b-table
        :data="quotas ? quotas.items : []"
        ref="table"
        :loading="isLoading"
        striped
        hoverable
        mobile-cards
        paginated
        backend-pagination
        :total="pagination.totalCount"
        :per-page="pagination.size"
        @page-change="onPageChange"
        backend-sorting
        @sort="onSort"
        default-sort-direction="asc"
        backend-filtering
        @filters-change="onFilterChange"
      >
        <b-table-column
          field="year"
          :label="$tf('quotaList.table.year|Év')"
          width="80"
        >
          <template v-slot="props">
            {{ props.row.year }}
          </template>
        </b-table-column>

        <b-table-column
          field="user"
          :label="$tf('quotaList.table.user|Név')"
          width="160"
          searchable
          sortable
        >
          <template #searchable="props">
            <multiselect-autocomplete
              v-model="props.filters.user"
              :data="employees"
              field="name"
              :placeholder="
                $tf('quotaList.table.user.placeholder|pl. Elektrom Ágnes')
              "
            ></multiselect-autocomplete>
          </template>
          <template v-slot="props">
            {{ props.row.user }}
          </template>
        </b-table-column>

        <b-table-column
          field="id"
          :label="$tf('quotaList.table.id|Azonosító')"
          v-slot="props"
        >
          <i style="opacity: 0.7">{{ props.row.userId }}</i>
        </b-table-column>

        <b-table-column
          field="quota"
          :label="$tf('quotaList.table.quota|Kvóta')"
          width="80"
          sortable
          v-slot="props"
        >
          {{ props.row.quota }}
        </b-table-column>

        <b-table-column
          field="taken-quota"
          :label="$tf('quotaList.table.takenQuota|Felhasznált')"
          width="80"
          v-slot="props"
        >
          {{ getTakenQuotaNumber(props.row.userId)?.taken }}
          <span
            v-if="getTakenQuotaNumber(props.row.userId)?.reserved > 0"
            style="font-weight: lighter"
          >
            (+{{ getTakenQuotaNumber(props.row.userId)?.reserved }})
          </span>
        </b-table-column>

        <b-table-column
          field="actions"
          :label="$tf('quotaList.table.actions|Műveletek')"
          centered
          width="80"
          v-slot="props"
        >
          <b-tooltip
            :label="$tf('quotaList.table.actions.delete|Törlés')"
            type="is-light"
          >
            <clickable-icon
              type="is-info"
              @click="deleteQuota(props.row.id, props.row.user)"
              icon="trash-alt"
            />
          </b-tooltip>
        </b-table-column>

        <template #bottom-left>
          <div class="is-flex has-gap-1 is-align-items-center">
            <div>{{ $tf("quotaList.table.pageSize|Oldal mérete") }}</div>
            <div id="size-selector" class="ml-2 mr-2">
              <b-select
                v-model="pagination.size"
                size="is-small"
                @update:modelValue="getQuotas"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </b-select>
            </div>
            <div>
              {{ $tf("quotaList.table.sum|Összesen: ") }}
              <strong>{{ pagination.totalCount }}</strong>
            </div>
          </div>
        </template>

        <template #empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p><b-icon icon="frown" size="is-large"></b-icon></p>
              <p>{{ $tf("quotaList.table.empty|Még nincs megadva kvóta.") }}</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import MultiselectAutocomplete from "@/components/module/input/MultiselectAutocomplete";
import debounce from "lodash.debounce";

export default {
  name: "QuotaList",
  components: {
    ClickableIcon,
    MultiselectAutocomplete,
  },

  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("quotas/getRemainings", {
      year: this.selectedYear,
    });
    // await this.$store.dispatch("employee/fetch");
    await this.getQuotas();
    this.isLoading = false;
  },
  watch: {
    async selectedYear() {
      this.isLoading = true;
      await this.$store.dispatch("quotas/getRemainings", {
        year: this.selectedYear,
      });
      await this.getQuotas();
      this.isLoading = false;
    },
  },

  data: function () {
    return {
      showDatePicker: false,
      selectedYear: new Date().getFullYear(),
      selectedMonth: null,
      isLoading: false,
      pagination: {
        totalCount: 0,
        page: 0,
        size: 100,
      },
      sort: {
        field: "year",
        order: "desc",
      },
      filter: {},
    };
  },

  computed: {
    ...mapGetters({
      quotas: "quotas/quotas",
      remainings: "quotas/remainings",
      employees: "employee/employees",
    }),
    years() {
      let years = [];
      let curYear = new Date().getFullYear() + 1;
      while (curYear >= 2020) {
        years.push(curYear);
        curYear--;
      }
      return years;
    },
  },

  methods: {
    async getQuotas(force) {
      this.isLoading = true;
      const year = this.selectedYear;
      const { page, size } = this.pagination;
      const sort =
        this.sort.field !== null
          ? `${this.sort.field},${this.sort.order}`
          : null;
      let params = new URLSearchParams();
      params.append("page", page);
      params.append("size", size);
      params.append("sort", sort);
      params.append("year", year);

      for (const [key, value] of Object.entries(this.filter)) {
        if (value && key !== "user") {
          params.append(key, value);
        } else if (value && key === "user") {
          params.append(
            "userId",
            this.filter.user.map((user) => user.id)
          );
        }
      }
      let requestParams = { params: params };

      await this.$store.dispatch("quotas/getQuotas", {
        params: requestParams,
        force,
      });
      if (this.quotas) {
        this.pagination = this.quotas.pagination;
      }
      this.isLoading = false;
    },
    deleteQuota(quotaId, user) {
      this.$buefy.dialog.confirm({
        title: this.$tf("quotaList.deleteModal.title|Kvóta törlése"),
        message: this.$tf(
          "quotaList.deleteModal.message|Biztos vagy benne, hogy törölni szeretnéd {user} kvótáját?",
          { user: user }
        ),
        confirmText: this.$tf("quotaList.deleteModal.confirm|Kvóta törlése"),
        cancelText: this.$tf("quotaList.deleteModal.cancel|Mégse"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          await this.$store.dispatch("quotas/deleteQuota", quotaId).then(() => {
            this.getQuotas(true);
            this.$buefy.toast.open(
              this.$tf("quotaList.deleteModal.success|Kvóta törölve!")
            );
          });
        },
      });
    },
    getTakenQuotaNumber(user) {
      let quota = this.remainings.filter((q) => {
        return q.user === user;
      });
      if (quota[0]) return quota[0];
      return null;
    },
    jumpEditor() {
      this.$router.push({
        path: "/absence/admin/quota/" + this.selectedYear,
      });
    },
    setSelectedYear(selectedYear) {
      this.selectedYear = selectedYear;
    },
    onPageChange: function (page) {
      this.pagination.page = page - 1;
      this.getQuotas();
    },
    onSort: function (field, order) {
      this.sort.field = field;
      this.sort.order = order;
      this.getQuotas();
    },
    onFilterChange: debounce(
      function (filter) {
        const { year, user } = filter;
        this.filter = {
          year: year ? year : null,
          user,
        };
        this.getQuotas();
      },
      500,
      { leading: true }
    ),
    removeFilter: function (filteredField) {
      this.$refs.table.filters[filteredField] = null;
      this.filter[filteredField] = null;
      this.getQuotas();
    },

    filterQuotasByName(user) {
      let quotas = this.quotas.items.filter((option) => {
        return (
          option.user.toString().toLowerCase().indexOf(user.toLowerCase()) >= 0
        );
      });
      for (let i in quotas) {
        quotas[i] = quotas[i].userId;
      }
      return quotas;
    },
  },
};
</script>
