<template>
  <b-tooltip
    :label="tooltip"
    :position="tooltipPosition"
    v-if="tooltip"
    :type="type"
    append-to-body
  >
    <button class="clickable-icon-button" :disabled="disabled">
      <b-icon
        :icon="icon"
        :size="size"
        :type="type"
        :pack="pack"
        custom-class="clickable-icon"
        :disabled="disabled"
      />
    </button>
  </b-tooltip>
  <button class="clickable-icon-button" :disabled="disabled" v-else>
    <b-icon
      :icon="icon"
      :size="size"
      :type="type"
      :pack="pack"
      custom-class="clickable-icon"
      :disabled="disabled"
    />
  </button>
</template>

<script>
export default {
  name: "ClickableIcon",
  components: {},
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "is-small",
    },
    type: {
      type: String,
      default: "is-primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
    },
    tooltipPosition: {
      type: String,
      default: "is-top",
    },
    pack: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.clickable-icon-button {
  background: transparent;
  border: none;
  padding: 0;
  &:not(:disabled) {
    cursor: pointer;
    :hover {
      .icon,
      .clickable-icon {
        color: $grey-dark;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    .icon,
    .clickable-icon {
      color: $grey;
    }
  }
}
</style>
