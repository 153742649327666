<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $tf("clients.title|Ügyfelek") }}</h1>
      <h2 class="subtitle">
        {{ $tf("clients.subtitle|Ügyfelek beállítása") }}
      </h2>
    </div>
    <div class="container">
      <div class="is-flex">
        <b-button
          type="is-info"
          class="ml-auto mb-5"
          @click="openEditPopup()"
          icon-left="plus-circle"
          >{{ $tf("clients.add|Új ügyfél") }}
        </b-button>
      </div>

      <b-table
        :data="clients"
        ref="clientsTable"
        :per-page="15"
        paginated
        detailed
        detail-key="id"
        :show-detail-icon="false"
        aria-next-label="Következő lap"
        aria-previous-label="Előző lap"
        aria-page-label="Lap"
        aria-current-label="Aktuális lap"
      >
        <b-table-column
          field="avatar"
          :label="$tf('clients.table.logo|Logó')"
          v-slot="props"
        >
          <f-avatar
            :data="props.row"
            :username="props.row.identifier"
            size="96x96"
            :font-size="2"
          />
        </b-table-column>

        <b-table-column
          field="identifier"
          :label="$tf('clients.table.identifier|Azonosító')"
          sortable
          searchable
          :custom-sort="
            (a, b, isAsc) =>
              isAsc
                ? Intl.Collator('hu').compare(a.identifier, b.identifier)
                : Intl.Collator('hu').compare(b.identifier, a.identifier)
          "
          v-slot="props"
        >
          {{ props.row.identifier }}
        </b-table-column>

        <b-table-column
          field="name"
          :label="$tf('clients.table.name|Név')"
          sortable
          searchable
          :custom-sort="
            (a, b, isAsc) =>
              isAsc
                ? Intl.Collator('hu').compare(a.name, b.name)
                : Intl.Collator('hu').compare(b.name, a.name)
          "
          v-slot="props"
        >
          {{ props.row.name }}
        </b-table-column>

        <b-table-column
          field="actions"
          :label="$tf('clients.table.actions|Műveletek')"
          v-slot="props"
        >
          <clickable-icon
            icon="pencil"
            type="is-info"
            class="mr-2"
            @click="openEditPopup(props.row)"
          ></clickable-icon>
          <clickable-icon
            icon="trash"
            type="is-danger"
            @click="deleteClient(props.row.id)"
          ></clickable-icon>
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate, openDeleteConfirm } from "@/utils/util";
import ClickableIcon from "../../../components/module/icon/ClickableIcon";
import ClientForm from "./ClientForm";
import FAvatar from "@/components/module/icon/FAvatar.vue";

export default {
  name: "Enterprise-Clients",
  data() {
    return {
      name: "",
      formatDate: formatDate,
      newClient: {
        identifier: "",
        name: "",
        description: "",
        avatar: "",
      },
    };
  },
  components: { FAvatar, ClickableIcon },
  computed: {
    ...mapGetters({
      clients: "enterprise_clients/clients",
    }),
  },
  methods: {
    async deleteClient(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("enterprise_clients/deleteClient", id);
        await this.$store.dispatch("enterprise_clients/fetchClients", true);
      });
    },
    openEditPopup(client) {
      this.$buefy.modal.open({
        parent: this,
        component: ClientForm,
        props: { client: client },
        hasModalCard: true,
        trapFocus: true,
      });
    },
  },
  mounted() {
    this.$store.dispatch("enterprise_clients/fetchClients");
  },
};
</script>
