<template>
  <section class="section">
    <div class="container">
      <h1 class="title">
        {{ $tf("handleAbsenceRequests.title|Távollét kérelmek") }}
      </h1>
      <h2 class="subtitle">
        {{
          $tf(
            "handleAbsenceRequests.subtitle|Beküldött szabadságkérelmek kezelése"
          )
        }}
      </h2>
    </div>

    <div class="container" v-if="absenceRequests">
      <div class="overflow-auto">
        <b-table
          :data="absenceRequests.items"
          ref="table"
          striped
          hoverable
          mobile-cards
          v-model:checked-rows="checkedRows"
          :checkable="canApproveRequests"
          :checkbox-position="'left'"
          paginated
          backend-pagination
          :total="pagination.totalCount"
          :per-page="pagination.size"
          @page-change="onPageChange"
          backend-sorting
          @sort="onSort"
          default-sort-direction="asc"
          :loading="loading"
        >
          <b-table-column
            field="created"
            :label="$tf('handleAbsenceRequests.table.date|Kérelem dátuma')"
            width="150"
            sortable
            v-slot="props"
          >
            {{ formatDate(props.row.created) }}
          </b-table-column>

          <b-table-column
            field="user"
            :label="$tf('handleAbsenceRequests.table.name|Név')"
            sortable
            v-slot="props"
            :custom-sort="
              (a, b, isAsc) =>
                isAsc
                  ? Intl.Collator('hu').compare(a.user, b.user)
                  : Intl.Collator('hu').compare(b.user, a.user)
            "
          >
            {{ props.row.user }}
          </b-table-column>

          <b-table-column
            field="type"
            :label="$tf('handleAbsenceRequests.table.type|Típus')"
            sortable
            v-slot="props"
          >
            <b-tag :style="style(props.row.type.color)">
              {{ props.row.type.name }}
            </b-tag>
          </b-table-column>

          <b-table-column
            field="status"
            :label="$tf('handleAbsenceRequests.table.status|Státusz')"
            sortable
            v-slot="props"
          >
            {{ props.row.status ? props.row.status.display : "-" }}
          </b-table-column>

          <b-table-column
            field="description"
            :label="$tf('handleAbsenceRequests.table.description|Leírás')"
            centered
            v-slot="props"
          >
            {{ props.row.description }}
          </b-table-column>

          <b-table-column
            field="date"
            :label="$tf('handleAbsenceRequests.table.requestDate|Dátum')"
            centered
            width="450"
            v-slot="props"
          >
            <div
              class="is-flex is-flex-wrap-wrap has-gap-1 is-justify-content-center is-align-items-center"
            >
              <b-tooltip
                :label="
                  $tf(
                    'handleAbsenceRequests.table.carryOver.tooltip|Előző évről áthozott'
                  )
                "
                type="is-light"
                class="mr-2"
                v-if="props.row.carryOver"
              >
                <div class="is-flex is-align-items-center">
                  <b-icon size="is-small" icon="clock-rotate-left" />
                </div>
              </b-tooltip>
              <b-tag
                v-for="(actDate, index) in props.row.absenceRequestDates"
                :key="index"
                :type="
                  getDateType(props.row.status ? props.row.status.enum : null)
                "
              >
                {{ formatDate(actDate) }}
              </b-tag>
            </div>
          </b-table-column>

          <b-table-column
            field="actions"
            :label="$tf('handleAbsenceRequests.table.actions|Műveletek')"
            centered
            v-slot="props"
            v-if="canApproveRequests || canRevokeRequests"
          >
            <b-tooltip
              :label="
                $tf(
                  'handleAbsenceRequests.table.actions.open.tooltip|Kérelem megnyitása'
                )
              "
              type="is-light"
            >
              <clickable-icon
                @click="openAbsenceRequestForm(props.row.id)"
                icon="edit"
                type="is-info"
              />
            </b-tooltip>
          </b-table-column>

          <template #empty>
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p><b-icon icon="smile-beam" size="is-large"></b-icon></p>
                <p>
                  {{
                    $tf(
                      "handleAbsenceRequests.table.empty|Jelenleg nincs elbírálásra váró szabadságkérelem."
                    )
                  }}
                </p>
              </div>
            </section>
          </template>

          <template #bottom-left>
            <div class="is-flex has-gap-1 is-align-items-center">
              <div>
                {{ $tf("handleAbsenceRequests.table.pageSize|Oldal mérete") }}
              </div>
              <div id="size-selector" class="ml-2 mr-2">
                <b-select
                  v-model="pagination.size"
                  size="is-small"
                  @update:modelValue="getAbsenceRequests"
                >
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                  <option :value="100">100</option>
                </b-select>
              </div>
              <div>
                {{ $tf("handleAbsenceRequests.table.sum|Összesen:") }}
                <strong>{{ pagination.totalCount }}</strong>
              </div>
            </div>
          </template>
        </b-table>
      </div>

      <br />

      <b-field>
        <b-button
          :disabled="!checkedRows.length > 0"
          type="is-primary"
          @click="approve"
          v-if="canApproveRequests"
        >
          {{
            $tf(
              "handleAbsenceRequests.acceptSelected|Kijelölt kérelmek elfogadása"
            )
          }}
        </b-button>

        <b-button
          type="is-primary"
          class="ml-auto"
          @click="openPastBrowserModal"
        >
          {{
            $tf("handleAbsenceRequests.browsePase|Korábbi kérelmek böngészése")
          }}
        </b-button>
      </b-field>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import {
  createUrlSearchParams,
  getDisplayName,
  formatDate,
  getContrastedColor,
} from "@/utils/util";
import PastRequestBrowserModal from "../../../components/absence/admin/PastRequestBrowserModal";
import { PRIVILEGES } from "@/utils/const";

export default {
  components: { ClickableIcon },
  name: "HandleAbsenceRequestList",
  async mounted() {
    await this.getAbsenceRequests(this.$route.query.force);
    await this.$store.dispatch("employee/fetchMyEmployees");
  },
  data() {
    return {
      formatDate,
      checkedRows: [],
      pagination: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sort: {
        field: "created",
        order: "desc",
      },
      loading: false,
    };
  },
  computed: {
    canApproveRequests() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ABSENCE.REQUEST.APPROVE_ASSIGNED,
        PRIVILEGES.ABSENCE.REQUEST.APPROVE_ANY,
        PRIVILEGES.ABSENCE.REQUEST.ADMIN
      );
    },
    canRevokeRequests: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ABSENCE.REQUEST.REVOKE,
        PRIVILEGES.ABSENCE.REQUEST.REVOKE_ANY,
        PRIVILEGES.ABSENCE.REQUEST.ADMIN
      );
    },
    ...mapGetters({
      absenceRequests: "absence_request/pmAbsenceRequests",
      employees: "employee/employees",
    }),
  },
  methods: {
    async getAbsenceRequests(force) {
      this.loading = true;
      let searchParams = createUrlSearchParams(this.pagination, this.sort);
      searchParams.append("status", "REQUEST");
      searchParams.append("status", "CLARIFY");

      await this.$store.dispatch("absence_request/getPmAbsenceRequests", {
        params: { params: searchParams },
        force,
      });
      this.pagination = this.absenceRequests.pagination;
      this.loading = false;
    },
    approve() {
      this.$buefy.dialog.prompt({
        type: "is-success",
        hasIcon: true,
        title: "Kérelmek elfogadása",
        message: "Biztosan elfogadod a kijelölt kérelmeket kérelmet?",
        cancelText: "Mégsem",
        confirmText: "Elfogadás",
        inputAttrs: {
          placeholder: "Bírálat, rövid üzenet (opcionális)",
          maxlength: 255,
          size: 250,
          autocomplete: "off",
          required: false,
        },
        onConfirm: (value) => this.approveSelected(value),
      });
    },

    approveSelected(comment) {
      let approvals = [];
      for (let request of this.checkedRows) {
        let approval = {
          comment: comment,
          approval: "APPROVE",
          user: request.userId,
          absenceRequestId: request.id,
        };
        approvals.push(approval);
      }

      this.$store
        .dispatch("absence_request/approveMultiple", approvals)
        .then(() => {
          this.getAbsenceRequests(true);
          this.checkedRows = [];
        });
    },
    openAbsenceRequestForm(absenceId) {
      this.$router.push("/approval/absence/handle-requests/" + absenceId);
    },
    getDisplayName(user) {
      return getDisplayName(this.employees, user);
    },
    getDateType(status) {
      switch (status) {
        case "REQUEST":
          return "is-info";
        case "LINE_APPROVED":
          return "is-info";
        case "APPROVED":
          return "is-info";
        case "ACTIVE":
          return "is-success";
        case "TAKEN":
          return "is-light";
        case "DENIED":
          return "is-danger";
        case "REVOKED":
          return "is-light";
      }
    },
    onPageChange: function (page) {
      this.pagination.page = page - 1;
      this.getAbsenceRequests();
    },
    onSort: function (field, order) {
      this.sort.field = field;
      this.sort.order = order;
      this.getAbsenceRequests();
    },

    style(color) {
      return (
        "color: " +
        getContrastedColor(color) +
        ";" +
        "background-color:" +
        color
      );
    },

    openPastBrowserModal() {
      this.$buefy.modal.open({
        parent: this,
        component: PastRequestBrowserModal,
        props: { employees: this.employees },
        hasModalCard: true,
        trapFocus: true,
        events: {
          save: () => this.reloadPage(),
        },
      });
    },
  },
};
</script>

<style scoped></style>
