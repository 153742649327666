<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $tf("querying.title|Célzott lekérdezés") }}</h1>
      <div class="overviewInfo">
        {{
          $tf(
            "querying.subtitle|Összesített lekérdezés a rendszerben szereplő munkanapló-bejegyzésekből. Az adatok nagy száma miatt valamilyen szűrés beállítása kötelező."
          )
        }}
      </div>
      <hr />
      <div class="container">
        {{ $tf("querying.filter.employees|Dolgozók kijelölése") }}

        <b-field grouped>
          <b-autocomplete
            v-model="employeeFilter"
            :data="employeesSortedAndFiltered"
            :placeholder="
              $tf('querying.filter.employees.placeholder|pl. Elektrom Ágnes')
            "
            class="overview-filter"
            clearable
            field="name"
            icon="search"
            rounded
            @select="(option) => checkEmployee(option)"
          >
            <template #empty
              >{{ $tf("querying.filter.employees.empty|Nincs találat") }}
            </template>
          </b-autocomplete>

          <b-switch v-model="onlyActiveEmployees">
            {{
              $tf("querying.filter.employees.onlyActive|Csak aktív dolgozók")
            }}
          </b-switch>
        </b-field>

        <div class="is-flex has-gap-2">
          <b-tag
            v-for="checkedEmployee in checkedEmployees"
            :key="checkedEmployee"
            :aria-close-label="
              $tf('querying.filter.employees.ariaCloseLabel|Eltávolítás')
            "
            closable
            size="is-medium"
            @close="uncheckEmployee(checkedEmployee)"
            >{{ getEmployeeNameById(checkedEmployee) }}
          </b-tag>
        </div>
      </div>
      <div class="container is-flex is-flex-direction-column has-gap-3">
        <transition name="slide">
          <div>
            {{ $tf("querying.filter.projects|Projektek kijelölése") }}

            <b-field grouped>
              <b-autocomplete
                v-model="projectFilter"
                :data="projectsSortedAndFiltered"
                :placeholder="
                  $tf(
                    'querying.filter.projects.placeholder|pl. Alfa Fejlesztés'
                  )
                "
                class="overview-filter"
                clearable
                field="name"
                icon="search"
                rounded
                @select="(option) => checkProject(option)"
              >
                <template #empty
                  >{{ $tf("querying.filter.projects.empty|Nincs találat") }}
                </template>
              </b-autocomplete>

              <b-switch v-model="showClosedProjects">
                {{
                  $tf(
                    "querying.filter.projects.showClosed|Lezárt projektek mutatása"
                  )
                }}
              </b-switch>
            </b-field>

            <div class="is-flex has-gap-2">
              <b-tag
                v-for="checkedProject in checkedProjects"
                :key="checkedProject"
                :aria-close-label="
                  $tf('querying.filter.projects.ariaCloseLabel|Eltávolítás')
                "
                closable
                size="is-medium"
                @close="uncheckProject(checkedProject)"
                >{{ checkedProject }}:
                {{ getProjectNameByKey(checkedProject) }}
              </b-tag>
            </div>
          </div>
        </transition>
      </div>
      <div class="container is-flex is-justify-content-space-between">
        <div>
          <b-field grouped>
            <b-field
              :label="$tf('querying.filter.fromDate|Mettől')"
              label-position="on-border"
            >
              <f-datepicker
                v-model="fromDate"
                :placeholder="
                  $tf('querying.filter.fromDate.placeholder|tól...')
                "
                icon="calendar-alt"
              >
              </f-datepicker>
            </b-field>
            <b-field
              :label="$tf('querying.filter.toDate|Meddig')"
              label-position="on-border"
            >
              <f-datepicker
                v-model="toDate"
                :placeholder="$tf('querying.filter.toDate.placeholder|...ig')"
                icon="calendar-alt"
              >
              </f-datepicker>
            </b-field>
          </b-field>
        </div>
        <div>
          <b-field grouped>
            <b-field>
              <b-button
                icon-left="table"
                size="is-default"
                type="is-info"
                @click="fetchOverview"
              >
                {{ $tf("querying.submit.show|Lekérdezés és megjelenítés") }}
              </b-button>

              <b-button
                :loading="isExportInProgress"
                class="ml-2"
                icon-left="file-excel"
                size="is-default"
                type="is-info"
                @click="exportOverview"
              >
                {{ $tf("querying.submit.export|Lekérdezés és exportálás") }}
              </b-button>
            </b-field>
          </b-field>
        </div>
      </div>
      <section
        v-if="overview && overview.records && overview.records.length"
        id="results"
      >
        <div class="container">
          <h3 class="heading">
            {{ $tf("querying.result.subtitle|Lekért adatok összesítése") }}
          </h3>
          <p>
            {{ $tf("querying.result.first|Legkorábbi bejegyzés dátuma:") }}
            <strong>{{ formatDate(new Date(overview.firstDate)) }}</strong>
          </p>
          <p>
            {{ $tf("querying.result.last|Legutóbbi bejegyzés dátuma:") }}
            <strong>{{ formatDate(new Date(overview.lastDate)) }}</strong>
          </p>
          <p>
            {{ $tf("querying.result.records|Bejegyzések száma: ") }}
            <strong>
              {{
                $tf("querying.result.records.count|{count} db", {
                  count: overview.totalRecords,
                })
              }}
            </strong>
          </p>
          <p>
            {{
              $tf(
                "querying.result.totalTime|A bejegyzések teljes időmennyisége:"
              )
            }}
            <strong>
              {{ secondToShortWorkDayFormat(overview.totalLogged) }}
            </strong>
          </p>
          <p>
            {{ $tf("querying.result.cost|A bejegyzések teljes költsége: ") }}
            <strong> {{ moneyify(overview.totalCost) }}</strong>
          </p>
        </div>
        <div class="container">
          <!--            <h1-->
          <!--              class="title small-title is-clickable"-->
          <!--              @click="openColumnSelector"-->
          <!--            >-->
          <!--              <b-icon icon="eye" size="is-small" />-->
          <!--              {{-->
          <!--                $tf(-->
          <!--                  "querying.result.table.columnVisibility|Oszlopok láthatósága"-->
          <!--                )-->
          <!--              }}-->
          <!--            </h1>-->

          <b-modal
            v-model="isModalOpen"
            :can-cancel="false"
            aria-modal
            aria-role="dialog"
            class="column-selector-modal"
            has-modal-card
            @close="closeColumnSelector"
          >
            <div class="modal-card" style="width: 36rem">
              <div class="container">
                <section class="modal-card-body">
                  <h1 class="title small-title mt-0">
                    <b-icon icon="eye" size="is-small" />
                    {{
                      $tf(
                        "querying.result.table.columnVisibility|Oszlopok láthatósága"
                      )
                    }}
                  </h1>
                  <div class="checkBox-container">
                    <b-checkbox v-model="saveableObjects.showProjectKey.value">
                      {{
                        $tf("querying.result.table.projectKey|Projekt kulcs")
                      }}
                    </b-checkbox>

                    <b-checkbox v-model="saveableObjects.showProjectName.value">
                      {{ $tf("querying.result.table.projectName|Projekt név") }}
                    </b-checkbox>

                    <b-checkbox v-model="saveableObjects.showTigKey.value">
                      {{ $tf("querying.result.table.tigKey|Mérföldkő kulcs") }}
                    </b-checkbox>

                    <b-checkbox v-model="saveableObjects.showTigName.value">
                      {{ $tf("querying.result.table.tigName|Mérföldkő név") }}
                    </b-checkbox>

                    <b-checkbox v-model="saveableObjects.showUserName.value">
                      {{ $tf("querying.result.table.username|Név") }}
                    </b-checkbox>

                    <b-checkbox v-model="saveableObjects.showIssueKey.value">
                      {{ $tf("querying.result.table.issueKey|Jegy kulcs") }}
                    </b-checkbox>

                    <b-checkbox v-model="saveableObjects.showIssueName.value">
                      {{ $tf("querying.result.table.issueName|Jegy név") }}
                    </b-checkbox>

                    <b-checkbox v-model="saveableObjects.showDate.value">
                      {{ $tf("querying.result.table.date|Dátum") }}
                    </b-checkbox>

                    <b-checkbox v-model="saveableObjects.showIssueTime.value">
                      {{ $tf("querying.result.table.time|Idő") }}
                    </b-checkbox>

                    <b-checkbox v-model="saveableObjects.showIssueCost.value">
                      {{ $tf("querying.result.table.cost|Költség") }}
                    </b-checkbox>

                    <b-checkbox
                      v-model="saveableObjects.showIssueComment.value"
                    >
                      {{ $tf("querying.result.table.comment|Megjegyzés") }}
                    </b-checkbox>
                  </div>

                  <div
                    class="close-btn cursor-pointer"
                    @click="closeColumnSelector"
                  >
                    <b-icon icon="times" icon-pack="fas" type="is-grey-icon" />
                  </div>
                </section>
              </div>
            </div>
          </b-modal>

          <b-table
            ref="overviewTable"
            :data="overview.records"
            :per-page="100"
            :show-detail-icon="true"
            aria-current-label="Aktuális lap"
            aria-next-label="Következő lap"
            aria-page-label="Lap"
            aria-previous-label="Előző lap"
            hoverable
            paginated
          >
            <b-table-column
              v-slot="props"
              :label="$tf('querying.result.table.project|Projekt')"
              field="projectName"
              searchable
            >
              <div>{{ props.row.projectIdentifier }}</div>
              <div>{{ props.row.projectName }}</div>
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="$tf('querying.result.table.tig|TIG')"
              field="projectName"
              searchable
            >
              <div>{{ props.row.tigIdentifier }}</div>
              <div>{{ props.row.tigName }}</div>
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="$tf('querying.result.table.issue|Jegy')"
              field="issueName"
              searchable
            >
              <div>
                <a
                  :href="
                    'https://loginet.atlassian.net/browse/' + props.row.issueKey
                  "
                  :title="props.row.issueName"
                  target="_blank"
                  >{{ props.row.issueKey }}</a
                >
              </div>
              <div>{{ props.row.issueName }}</div>
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="$tf('querying.result.table.employee|Dolgozó')"
              field="workerName"
              searchable
            >
              <UserInfo
                :user="{
                  userId: props.row.userId,
                  name: props.row.workerName,
                  avatar: props.row.workerAvatar,
                  email: props.row.workerEmail,
                  phone: props.row.workerPhone,
                }"
                displayMode="minimal"
                tooltipPosition="bottom"
              />
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="$tf('querying.result.table.day|Dátum')"
              field="workStarted"
              sortable
            >
              {{ formatDate(new Date(props.row.workStarted)) }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="$tf('querying.result.table.spent|Költség')"
              field="loggedSeconds"
              sortable
            >
              <div>
                {{ secondToShortWorkDayFormat(props.row.loggedSeconds) }}
              </div>
              <div>{{ moneyify(props.row.spentCost) }}</div>
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="$tf('querying.result.table.comment|Komment')"
              field="workComment"
              searchable
            >
              {{ props.row.workComment }}
            </b-table-column>
          </b-table>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate, moneyify, secondToShortWorkDayFormat } from "@/utils/util";
import { PROJECT_STATUS_ENUM } from "@/utils/const";
import FileSaver from "file-saver";
import LocalStoreMixin from "@/mixins/LocalStoreMixin";
import SaveableObject from "@/objects/SaveableObject";
import SaveableObjectContainer from "@/objects/SaveableObjectContainer";
import UserInfo from "@/components/module/info/UserInfo.vue";
import FDatepicker from "@/components/module/input/FDatepicker.vue";

const LOCAL_KEY_PREFIX = "overview_columns_";

export default {
  name: "Enterprise-Overview",
  components: { FDatepicker, UserInfo },
  mixins: [LocalStoreMixin],
  data() {
    return {
      PROJECT_STATUS_ENUM,
      formatDate,
      moneyify,
      secondToShortWorkDayFormat,
      checkedProjects: [],
      checkedEmployees: [],
      fromDate: new Date(new Date().setDate(1)),
      toDate: new Date(),
      employeeFilter: "",
      projectFilter: "",
      showClosedProjects: false,
      onlyActiveEmployees: true,
      saveableObjects: new SaveableObjectContainer({
        showProjectKey: new SaveableObject(
          LOCAL_KEY_PREFIX + "showProjectKey",
          false
        ),
        showProjectName: new SaveableObject(
          LOCAL_KEY_PREFIX + "showProjectName",
          true
        ),
        showTigKey: new SaveableObject(LOCAL_KEY_PREFIX + "showTigKey", false),
        showTigName: new SaveableObject(
          LOCAL_KEY_PREFIX + "showTigName",
          false
        ),
        showUserName: new SaveableObject(
          LOCAL_KEY_PREFIX + "showUserName",
          true
        ),
        showIssueKey: new SaveableObject(
          LOCAL_KEY_PREFIX + "showIssueKey",
          true
        ),
        showIssueName: new SaveableObject(
          LOCAL_KEY_PREFIX + "showIssueName",
          true
        ),
        showDate: new SaveableObject(LOCAL_KEY_PREFIX + "showDate", true),
        showIssueTime: new SaveableObject(
          LOCAL_KEY_PREFIX + "showIssueTime",
          true
        ),
        showIssueCost: new SaveableObject(
          LOCAL_KEY_PREFIX + "showIssueCost",
          true
        ),
        showIssueComment: new SaveableObject(
          LOCAL_KEY_PREFIX + "showIssueComment",
          false
        ),
      }),
      isExportInProgress: false,
      isModalOpen: false,
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      employees: "employee/timesheetEmployees",
      projects: "enterprise_core/limitedProjects",
      overview: "enterprise_overview/overview",
      currencySymbol: "uiConfigStore/getCurrencySymbol",
    }),
    employeesSortedAndFiltered() {
      let selectableEmployees = this.employeesSorted;
      if (this.onlyActiveEmployees) {
        selectableEmployees = selectableEmployees.filter(
          (e) => !e.endDate || new Date(e.endDate) > new Date()
        );
      }
      if (this.employeeFilter) {
        selectableEmployees = selectableEmployees.filter((emp) =>
          emp.name.toLowerCase().includes(this.employeeFilter.toLowerCase())
        );
      }
      return selectableEmployees;
    },
    projectsSortedAndFiltered() {
      let selectableProjects = this.projectsSorted;
      if (!this.showClosedProjects) {
        selectableProjects = selectableProjects.filter(
          (p) => p.status !== PROJECT_STATUS_ENUM.CLOSED
        );
      }
      if (this.projectFilter) {
        selectableProjects = selectableProjects.filter(
          (p) =>
            p.name.toLowerCase().includes(this.projectFilter.toLowerCase()) ||
            p.identifier
              .toLowerCase()
              .includes(this.projectFilter.toLowerCase())
        );
      }
      return selectableProjects;
    },
    employeesSorted() {
      return [...this.employees].sort((a, b) => a.name.localeCompare(b.name));
    },
    projectsSorted() {
      return [...this.projects].sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    createRequest() {
      return {
        from: this.fromDate,
        to: this.toDate,
        employees: this.checkedEmployees,
        projects: this.checkedProjects,
      };
    },
    fetchOverview() {
      let request = this.createRequest();
      this.$store.dispatch("enterprise_overview/fetchOverview", request);
    },
    async exportOverview() {
      this.isExportInProgress = true;
      try {
        let request = this.createRequest();

        let data = await this.$store.dispatch(
          "enterprise_overview/exportOverview",
          request
        );

        FileSaver.saveAs(data, "overview.xlsx");
      } finally {
        this.isExportInProgress = false;
      }
    },
    openColumnSelector() {
      this.isModalOpen = true;
    },
    closeColumnSelector() {
      this.isModalOpen = false;
    },
    checkEmployee(employee) {
      if (employee && !this.checkedEmployees.find((e) => employee.id === e)) {
        this.checkedEmployees.push(employee.id);
      }
    },
    uncheckEmployee(employeeId) {
      this.checkedEmployees.splice(
        this.checkedEmployees.indexOf(employeeId),
        1
      );
    },
    getEmployeeNameById(employeeId) {
      return this.employees.find((e) => e.id === employeeId).name;
    },
    checkProject(project) {
      if (
        project &&
        !this.checkedProjects.find((p) => project.identifier === p)
      ) {
        this.checkedProjects.push(project.identifier);
      }
    },
    uncheckProject(projectKey) {
      this.checkedProjects.splice(this.checkedProjects.indexOf(projectKey), 1);
    },
    getProjectNameByKey(projectKey) {
      return this.projects.find((p) => p.identifier === projectKey).name;
    },
  },
  async mounted() {
    this.fetchAllSaveObjects(this.saveableObjects);
    await this.$store.dispatch("enterprise_core/fetchLimitedProjects");
    await this.$store.dispatch("employee/fetchEmployeesForTimesheet");
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.employee-container {
  max-height: 200px;
  overflow-y: scroll;
}

.employee-container ul {
  column-count: 4;
}

.overview-filter {
  width: 80%;
}

.checkBox-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

.overviewInfo {
  font-weight: normal;
  font-size: 1rem;
  line-height: 130%;
  color: $custom-grayer-blue;
}

.overviewInfo > ul {
  margin: 4px 16px !important;
}
</style>
